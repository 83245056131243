.page-cart-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
  margin: 0;
}

.container-cart {
  width: 100%;
  max-width: 746px;
  .cart-card-product-skeleton {
    border-bottom: 1px solid #e6e6eb;
  }

  .button-cart-clear {
    height: 40px !important;
    border-radius: 22px !important;
    border: 1px solid #acacac !important;
    background: #fff !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    // margin-top: 16px;
    div {
      p {
        color: #acacac !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        margin-left: 8px !important;
      }
    }
    &:hover {
      background: rgba(245, 242, 242, 0.6901960784) !important;
      border: 1px solid rgba(245, 242, 242, 0.6901960784) !important;
      // height: 42px !important;
      // border: none !important;
    }
  }
}

.cart-clear {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
  margin-top: 32px;
  .title-counter-product-cart {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #333333;
    display: block;
    width: auto;
  }
}

.cart-clear-not-available {
  border-bottom: 0px;
}

.cart-claer-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.card-product-img {
  // background-color: #8b8b8b;
  width: 100px;
  height: 100px;
  position: relative;
  min-width: 100px;
  cursor: pointer;
  img {
    object-fit: contain;
  }
}

.product-tag {
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
  background: #ffffff;
  border: 1px solid #5032c8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 4px 5px;
}

.tag-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #5032c8;
  margin: 0;
  padding: 0;
}

.cart-card-product {
  display: flex;
  padding: 16px 0px;
  border-bottom: 1px solid #e6e6eb;
  .product-not-available {
    opacity: 0.5;
    justify-content: center;
    .product-name {
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
  .product-price-not-available {
    width: auto;
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: #131313;
    }
  }
  .product-not-available-image {
    opacity: 0.5;
  }
}

.product-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-width: 275px;
  cursor: pointer;
  .product-detail-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 91%;
    .product-code {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8b8b8b;
    }
    .product-name {
      font-weight: 500;
    }
  }
  &:hover {
    .product-name {
      color: rgba(80, 50, 200, 1);
    }
  }
}

.card-product-content {
  margin-left: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.product-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.product-unit-price {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8b8b8b;
  padding: 0;
  margin: 0;
}

.button-cart-counter {
  display: flex;
  width: 120px;
  height: 32px;
  border: 1px solid #e6e6eb;
  border-radius: 12px;
}

.button-product-cart {
  background-color: #ffffff;
  border: 0;
  padding: 0;
  width: 38px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    &:hover {
      background-color: #ffffff;
    }
  }
  &:hover {
    background: rgba(245, 242, 242, 0.6901960784);
  }
}

.disabled-icon-button-cart {
  filter: brightness(0) saturate(100%) invert(94%) sepia(8%) saturate(82%) hue-rotate(201deg) brightness(96%)
    contrast(96%);
}

.counter-product-cart {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 44px;
  height: 28px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.product-button {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.grade-product {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.grade-sale-product {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0;
  padding: 0;
  text-decoration: line-through;
}

.cart-product-grade {
  display: flex;
  width: 120px;
  margin-left: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-delete-like {
  display: flex;
  margin-left: 16px;
  width: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-product {
  margin-bottom: 16px;
  cursor: pointer;
}

.delete-product:hover {
  background: rgba(245, 242, 242, 0.6901960784);
  border-radius: 16px;
}

.row-basket-page {
  justify-content: space-between;
}

//---------------------------Order----------------------------

.col-ordering {
  position: relative;
}

.box-ordering {
  position: sticky;
  top: 10px !important;
}

.box-order {
  gap: 24px;
  max-width: 416px;
  width: 100%;
  max-height: 680px;
  height: auto;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  padding: 16px 0px 24px 0px;
  .create-order-button {
    padding: 0px 24px;
    .error-create-order {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: #ed1414;
      margin-top: 8px;
      display: block;
    }
    .btn-primary {
      &:disabled {
        pointer-events: none !important;
        background-color: #f2f2f2 !important;
        width: 100% !important;
        box-shadow: none !important;
        opacity: 1;
        p {
          color: #c3c3c7 !important;
        }
      }
    }
  }
}

.title-card-order {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0 0 16px 0;
  padding: 0px 24px;
}

.__summ-order {
  margin-top: 16px;
}

.title-info-order-card {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
}

.text-info-order-card {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
}

.all-summ-cart {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.info-order-card {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  .box-info-order {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

.box-info-order-column {
  display: flex;
  flex-direction: column;
}

.box-promo-code-input {
  position: relative;
  margin: 8px 0px 16px 0px;
}

.order-input-promo-code {
  width: 368px;
  height: 46px;
  border: 1px solid #e6e6eb;
  border-radius: 12px;
  padding: 0 16px;
}

.active-promo-code {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: rgba(195, 195, 199, 1);
  margin: 0;
  position: absolute;
  top: 12px;
  right: 16px;
}

.__active-promocode {
  color: #5032c8;
  cursor: pointer;
}

.order-input-promo-code:focus-visible {
  outline: 1px solid #5032c8;
}

.text-error-promo-code {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  color: #ed1414;
}

.text-save-promo-code {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-top: 4px;
  color: #198754;
}

.box-info-card-order {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e6e6eb;
  padding: 0px 24px;
}

.default-margin {
  margin-bottom: 0;
}

.edit-delivery-params {
  margin: 4px 0px 16px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #5032c8;
  cursor: pointer;
}

.edit-delivery-params:hover {
  text-decoration: underline;
}

.box-order-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.order-checkbox-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0 0 0 5px;
}

.button-checkout {
  max-width: 368px !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  height: 44px !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 22px !important;
  width: 368px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
  margin-top: 16px;
  &:hover {
    background: #613df2 !important;
  }
}

.button-text-checkout {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  color: #ffffff;
}

.box-order-policy {
  text-align: center;
  margin-top: 16px;
}

.text-order-policy {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.text-link-order-policy {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5032c8;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
}

.text-link-order-policy:hover {
  text-decoration: underline;
  color: #613df2;
}

//------------------------------Order-Details-----------------------------

.container-order-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 24px 0px !important;
  max-width: 746px !important;
  // border: 1px solid #e6e6eb;
  border-radius: 16px;
  margin-top: 40px;
  .contact-details-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 24px;
  }
  .information-recipient {
    width: 100%;
    .information-recipient-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #333333;
      margin-bottom: 16px;
    }
    .information-recipient-inputs {
      display: flex;
      gap: 20px;
      .container-phone-input {
        margin: 0 !important;
        .input-recipient {
          background-color: #ffffff;
        }
      }

      .container-input {
        margin: 0;
        .input-recipient {
          background-color: #ffffff;
          padding-top: 3px;
        }
      }
    }
  }
  .additionally {
    width: 100%;
    margin-top: 24px;
    .additionally-title {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: #333333;
      margin: 0;
    }
    .additionally-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #8b8b8b;
    }
    .info-analogues-close {
      // margin: 37px 0px 37px 0px;
    }
    .info-analogues {
      display: flex;
      align-items: center;

      .analogues-checkbox {
        display: flex;
        .icon-info {
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .analogues-detail-info {
        visibility: hidden;
        opacity: 0;
        margin-left: 16px;
        padding: 12px 16px;
        background: #fafafc;
        border: 1px solid #5032c8;
        border-radius: 16px;
        max-width: 375px;
        transition: visibility 0s, opacity 0.3s linear;
        .analogues-detail-info-text {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #131313;
        }
      }
      .analogues-detail-info__open {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .order-comment {
    .title-comment-order {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
      margin: 0px 0px 16px 0px;
      padding: 0;
    }
    .comment-order {
      width: 698px;
      height: 117px;
      border: 1px solid #e6e6eb;
      border-radius: 16px;
      padding: 12px 16px 12px 16px;
      resize: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &::placeholder {
        color: #c3c3c7;
      }
      &:focus-visible {
        outline: 1px solid #5032c8 !important;
      }
    }
  }
  .box-order-details {
    max-width: 600px;
    margin-bottom: 40px;
    .title-payment-method {
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: #333333;
      margin: 24px 0px 8px 0px;
      padding: 0;
    }
    .box-order-radio {
      display: flex;
      margin-bottom: 16px;
      cursor: pointer;
      .form-check-input {
        width: 22px !important;
        height: 22px !important;
        border-radius: 22px !important;
        background-color: #fafafc !important;
        &:checked {
          border-color: #5032c8 !important;
          background-color: #fafafc !important;
          background-image: url("../../../image/check-radio.svg") !important;
          background-size: 14px 14px;
        }
        &:focus {
          box-shadow: none !important;
        }
      }
      .order-radio-text {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        margin: 0 0 0 5px;
      }
    }
  }
}

.text-payment-method {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8b8b8b;
  margin: 0px 0px 20px 0px;
}

.__no-border-checkbox {
  border: none;
  margin: 0px 0px 16px 0px;
  padding: 0;
}

.banner-empty-cart {
  background: transparent !important;
  h5 {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 24px;
  }
  .banner-found-nothing-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 24px;
    max-width: 420px;
  }
  .button-banner-found-nothing {
    width: 190px !important;
    height: 44px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
    border-radius: 22px !important;
    .container-button-content {
      margin: 0 !important;
      .button-text-found-nothing {
        font-size: 16px !important;
        line-height: 20px !important;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 !important;
      }
    }
  }
}

//-----------------------------------successful-ordering-banner------------
.row-cart-success {
  display: flex;
  justify-content: center !important;
  .successful-ordering-banner {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #fafafc;
    border: 1px solid #e6e6eb;
    border-radius: 24px;
    max-width: 636px;
    width: 100%;
    .banner-title {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      width: 100%;
      text-align: center;
      color: #333333;
      margin: 0px 0px 24px 0px;
    }
    .banner-info-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
      .info-detail-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8b8b8b;
      }
      .info-detail-text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .row-basket-page {
    margin-top: 16px;
    .page-cart-title {
      margin-bottom: 32px;
    }
    .box-ordering {
      padding: 0px 16px;
      position: relative;
      .box-order {
        border: 0;
        padding: 0;
        max-height: 100%;
        width: 100%;
        max-width: 100%;
        .title-card-order {
          padding: 0;
        }
        .box-info-card-order {
          padding: 0;
          .title-info-order-card {
            font-size: 12px;
            line-height: 16px;
          }
        }
        .container-cart {
          .cart-clear {
            flex-direction: column;
            align-items: flex-start;
            .title-counter-product-cart {
              font-size: 20px;
              line-height: 28px;
            }
            .button-cart-clear {
              margin-top: 16px;
            }
          }
          //ттт
          // .container-cart {
          //   .contaiter-products-cart {
          //     .cart-card-product {
          //       .card-product-content {
          //         flex-direction: column;
          //         .product-content {
          //           width: 100%;
          //           max-width: 100%;
          //         }
          //       }
          //     }
          //   }
          // }
        }
        .__summ-order {
          margin-top: 32px;
        }
        .info-order-card {
          padding: 0;
          .box-promo-code-input {
            width: 100%;
            .order-input-promo-code {
              width: 100%;
            }
          }
        }
        .create-order-button {
          padding: 0;
          width: 100%;
          button {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
        .container-order-details {
          margin-top: 32px;
          padding: 0 !important;
          .contact-details-title {
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 24px;
          }
          .information-recipient {
            .information-recipient-inputs {
              flex-direction: column;
            }
          }
          .box-order-details {
            width: 100%;
            margin: 32px 0 0 0;
            .title-payment-method {
              margin-top: 0;
              font-size: 20px;
              line-height: 28px;
            }
            .text-payment-method {
              font-size: 13px;
              line-height: 20px;
            }
          }
          .additionally {
            margin-top: 16px;
            .additionally-title {
              margin-top: 8px;
              font-size: 20px;
              line-height: 28px;
            }
            .info-analogues {
              flex-direction: column-reverse;
              align-items: flex-start;
              width: 100%;
              .analogues-checkbox {
                margin-top: 12px;
              }
              .analogues-detail-info {
                height: 0;
                margin: 0;
                padding: 0px 16px;
                background: transparent;
                opacity: 1;
                transition: all 0.4s;
                width: 100%;
                border: 0;
                .analogues-detail-info-text {
                  height: 0;
                  display: none;
                }
              }
              .analogues-detail-info__open {
                width: 100%;
                margin: 16px 0 0 0;
                border: 1px solid #5032c8;
                padding: 12px 16px;
                background: #fafafc;
                height: auto;
                .analogues-detail-info-text {
                  height: auto;
                  display: flex;
                }
              }
            }
          }
          .order-comment {
            margin-top: 24px;
            width: 100%;
            .title-comment-order {
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 16px;
            }
            .comment-order {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .row-cart-success {
    margin: 20px 16px 0px 16px !important;
  }
}

@media (max-width: 430px) {
  .container-cart {
    .contaiter-products-cart {
      padding-bottom: 16px;
      border-bottom: 1px solid #e6e6eb;
      .cart-card-product {
        border: 0;
        .card-product-img {
          width: 90px;
          height: 90px;
          min-width: 90px;
        }
        .card-product-content {
          flex-direction: column;
          .product-content {
            padding: 0;
            width: 100%;
            max-width: 100%;
            .product-unit-price {
              font-size: 14px;
              line-height: 20px;
            }
          }
          .cart-product-grade {
            margin: 8px 0 0 0;
            align-items: flex-start;
          }
        }
      }
      .product-buttons-cart {
        display: flex;
        flex-direction: row-reverse;
        .product-button {
          width: 100%;
          margin-left: 16px;
          .button-cart-counter {
            width: 100%;
            justify-content: space-between;
            border-radius: 12px;
            .button-product-cart {
              background: transparent;
            }
          }
        }
        .box-delete-like {
          flex-direction: row;
          margin-left: 0;
          width: auto;
          padding: 0px 9px;
          .delete-product {
            margin: 0 9px 0px 0px;
          }
        }
      }
    }
  }
}
