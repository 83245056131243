.container-catalog-button {
  max-width: 131px;
}

.catalog-button {
  background: #5032c8;
  border-radius: 16px;
  cursor: pointer;
}

.button-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  min-width: 60px;
  position: relative;
  z-index: 12;
}

.box-img {
  width: 21px;
  height: 21px;
  margin: 13.5px 0px 13.5px 17.5px;

  &.close-box-img {
    height: 17px;
    // filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(225deg)
    //   brightness(104%) contrast(102%);
  }
  transition: 0.2s;
}

.box-text {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 17px 16px 17px 13.5px;
}

.box-catalog {
  display: flex;
  width: 700px;
  height: auto;
  min-height: 200px;
  position: absolute;
  z-index: 99;
  max-height: 80%;
  border-radius: 24px;
}

.container-close-catalog {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  opacity: 0;
  display: block;
}

.none-container-close-catalog {
  display: none;
}

@media screen and (max-width: 430px) {
  .box-catalog {
    margin-left: -16px;
    margin-top: 16px;
    width: 100%;

    .container-catalog {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }
}
