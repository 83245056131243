.promocode {
  width: 471px;
  border-radius: 24px;
  display: flex;
}

.promocode-line {
  height: 100%;
  background: #5032c8;
  border-radius: 10px 0px 0px 10px;
  min-width: 10px;
  min-height: 200px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.promocode-card {
  background: #ffffff;
  padding: 16px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #dedee0;
  border-radius: 0px 12px 12px 0px;
  width: 100%;
}

.box-text-promocode-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 317px;
  width: 100%;
}

.promocode-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #ed145b;
  padding: 0;
  margin: 0;
}

.promocode-date {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #a2a2a6;
  padding: 0;
  margin: 8px 0px;
}

.box-bot-card-promocode {
  display: flex;
  justify-content: space-between;
}

.box-content-promocode-card {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.text-radial-progress {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  line-height: 20px;
  color: #ed145b;
  max-width: 65px;
  text-align: center;
  padding: 0;
  margin: 10px 0px 0px 0px;
}

.promocode-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #272729;
  margin: 0px 0px 24px 0px;
  padding: 0;
}

.promocode-name {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.promocode-active {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  color: #5032c8;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.active-promocode {
  color: #198754;
}

.promo-code-activation {
  color: rgb(195, 195, 199);
  cursor: default;
  &:hover {
    color: rgb(195, 195, 199);
  }
}

.promocode-active:hover {
  text-decoration: underline;
}

.box-progress-card-promocode {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.line-radial {
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  margin-left: -6px;
  display: flex;
  align-items: center;
}

.promocodes {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.title-promocodes {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #333333;
  padding: 0;
  margin-bottom: 32px;
}

.box-circular-progressbar {
  width: 85px;
  height: 85px;
}
