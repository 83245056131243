.title-recipe {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
  margin: 0px 0px 32px 0px;
  padding: 0 !important;
}

.box-video {
  padding: 0 !important;
}

.col-ingredients {
  padding: 0px 0px 0px 32px !important;
}

.title-ingredients {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0px 0px 32px 0px;
}

.ingredient {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.ingredient-weight {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.line {
  flex-grow: 1;
  margin: 1rem 0 0 0;
}

.box-ingredients {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box-ingredient {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 1rem;
}

.button-add-to-cart {
  max-width: 224px !important;
  width: 190px;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 16px !important;
}

.button-text-add-to-cart {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
  margin: 0;
}

.box-button-add-to-cart {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.info-card {
  padding: 12px 16px;
  gap: 12px;
  width: 306px !important;
  height: 66px;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  display: flex;
}

.title-info-card {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
  margin: 0;
}

.text-info-card {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
  margin: 0;
}

.row-information-card {
  gap: 24px;
  margin-top: 24px !important;
}

.row-cooking {
  margin-top: 72px !important;
}

.title-cooking {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
}

.col-cooking {
  padding: 0 !important;
}

.box-cooking-step {
  display: flex;
}

.img-step {
  width: 306px;
  height: 306px;
  border-radius: 16px;
}

.box-text-step {
  margin-left: 40px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.step {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
}

.text-step {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #333333;
  max-width: 510px;
}

.cooking-steps {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
}

.container-recipe {
  margin-top: 16px;
}

.row-breadcrumb-recipe > nav {
  padding: 0;
}

.title-slider {
  padding: 0 !important;
  margin: 0px 0px 16px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.row-slider {
  margin-top: 72px !important;
}

@media (max-width: 768px) {
  .row-recipe-info {
    flex-direction: column;
  }
  .col-ingredients {
    padding: 0 !important;
  }
  .info-card {
    width: 100% !important;
  }
  .col-ingredients {
    margin-top: 40px !important;
  }
  .title-ingredients {
    margin-bottom: 24px !important;
  }
  .box-ingredients {
    gap: 0px;
  }
  .box-ingredient {
    margin-bottom: 16px !important;
  }
  .row-cooking {
    margin-top: 40px !important;
  }
  .title-cooking {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #333333;
  }
  .box-cooking-step {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0px;
    margin-bottom: 32px;
  }
  .box-cooking-step:last-child {
    margin-bottom: 0px;
  }
  .img-step {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }
  .step {
    margin-bottom: 8px;
  }
  .box-text-step {
    width: 100%;
    margin-left: 0;
    gap: 0;
    margin-bottom: 12px;
  }
  .step {
    font-weight: 700;
  }
  .cooking-steps {
    gap: 0;
  }
  .row-information-card {
    gap: 0;
  }
  .info-card {
    margin-bottom: 16px;
  }
  .info-card:last-child {
    margin-bottom: 0px;
  }
}

@media (max-width: 430px) {
  .container-carousel-cards .slick-slider {
    button {
      display: none !important;
    }
    .slick-list {
      height: 100% !important;
      // max-height: 400px !important;
    }
    .slick-track {
      height: auto !important;
    }
  }
  .title-recipe {
    padding: 0 16px !important;
  }
  .row-information-card {
    padding: 0 16px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .col-ingredients {
    padding: 0 16px !important;
  }
  .row-cooking {
    padding: 0 16px !important;
  }
  .row-slider {
    .col-carousel-cards {
      padding: 0 !important;
    }
  }
  .title-slider {
    padding: 0 16px !important;
  }
}
