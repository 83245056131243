.button-app {
  width: 100%;
  max-width: 164px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 5px;
  margin-left: 8px;
  border: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
}

.button-app:hover {
  background: #f7f7fa;
}

.button-app::nth-child(3) {
  margin: 0px !important;
}

.button-app-content {
  display: flex;
  min-width: 120px;
}

.content-button-text {
  margin: 0px 0px 0px 13px;
}

.donload {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #272729;
  margin: 0;
  padding: 0;
}

.store {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272729;
  margin: 0;
  padding: 0;
}

.box-img-app {
  width: 28px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}