.__modal-message {
  margin-bottom: 24px;
  max-width: 100%;
  height: 120px;
  resize: none;
  padding: 12px 24px 0px 16px;
}

.text-support-modal {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 24px;
}

.__support {
  margin-top: 24px;
  align-items: center;
}

.text-area-error-support {
  margin-top: -12px;
}

.text-error-support {
  margin: 1px 0px -4px 0px;
}

.feedback-form-title {
  font-weight: 400;
  font-size: 20px;
}

.feedback__close__btn {
  background-color: #5032c8 !important;

  .content-button-text {
    color: #ffffff !important;
  }
}

@media screen and (max-width: 1280px) and (min-width: 821px) {
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 640px !important;
    max-width: unset;
  }
}

@media screen and (max-width: 950px) and (min-width: 431px) {
  .container-formik-input {
    .container-input-file {
      width: 100%;
      max-width: unset;
    }
  }
}


@media screen and (max-width: 430px) {
  .modal-body {
    align-items: flex-start;
    padding: 0 16px 16px 16px !important;
  }

  .modal-dialog {
    &.confirm-modal {
      padding: 0 16px 0 16px !important;
    }
  }

  .confirm-modal {
    margin: 30vh 0 0 0 !important;

    .modal-content {
      border-radius: 24px !important;
      height: auto !important;
    }

    &.modal-content {
      max-width: 90vw;;
    }
  }


  .text-area-error-support {
    margin-top: 0;
  }

  .__support {
    .box-name-phone-city {
      height: unset;

      .container-input, .container-phone-input {
        margin-top: 16px;
      }

      .__modal-message {
      margin-bottom: unset;
      }
    }

    .box-file-input {
      display: flex;
      justify-content: center;
    }

    .text-agreement {
      width: auto;
      text-align: center;
    }

    .text-support-modal {
      margin-bottom: 16px;
    }

    .container-input-file {
      max-width: 100%;
      height: fit-content;

      .input-file-info {
        margin-bottom: unset;
      }
    }
  }
}
