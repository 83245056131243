.product-mark-reviews-modal {
  max-width: 600px !important;
}

.container-product-mark-reviews {
  padding: 24px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.product-evaluation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.product-evaluation-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.product-evaluation-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.product-evaluation-icon {
  width: 40px;
  cursor: pointer;
}

.container-product-comment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
}

.product-comment-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.input-photo-products {
  color: transparent;
  width: 96px;
  height: 96px;
  position: absolute;
  cursor: pointer;
}
.input-photo-products::-webkit-file-upload-button {
  visibility: hidden;
}
.input-photo-products::before {
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.input-photo-products:hover::before {
  border-color: black;
}
.input-photo-products:active {
  outline: 0;
}
.input-photo-products:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.card-input-photo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 28px;
  gap: 10px;
  width: 96px;
  height: 96px;
  border: 1px solid #e6e6eb;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.image-plus-photo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.input-photo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.box-download-photo {
  position: relative;
}

.download-photo {
  width: 96px;
  height: 96px;
  border: 1px solid #e6e6eb;
  border-radius: 10px;
  object-fit: contain;
}

.button-delete-download-photo {
  position: absolute;
  z-index: 10;
  width: 24px;
  height: 24px;
  right: 4px;
  top: 4px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button-delete-download-photo:hover {
  background: #e6e6eb;
}

.delete-download-photo {
  width: 8px;
  height: 8px;
}

.container-photo-product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.box-button-send-review {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

}

.button-send-review {
  max-width: 166px !important;
  height: 44px !important;
}

@media screen and (max-width: 430px) {
  .button-send-review {
    max-width: unset !important;
  }
}

@media screen and (max-width: 320px) {
  .download-photo,
  .input-photo-products,
  .card-input-photo {
    width: 86px;
    height: 86px;
  }
}

.button-send-review > div > p {
  font-size: 14px !important;
}

.__modal-comment {
  max-width: 100%;
  height: 120px;
  resize: none;
  padding: 12px 24px 0px 16px;
}

.text-area-container {
  margin-bottom: 0;
}

.container-product-cannot-rated {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
}

.text-product-cannot-rated {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.product-cannot-rated-modal {
  max-width: 416px !important;

  .modal-content {
    top: 25%;
  }
}

.button-close-modal-rated {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-button-text {
    margin-left: 0 !important;
  }
}

.product-cannot-rated-alignment {
  width: 100%;
  align-items: flex-start;
}

.text-processing-agreement {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  max-width: 416px;
  margin: 24px 0px 0px 0px;
}
