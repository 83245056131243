.box-favorites-products {
  display: flex;
  flex-wrap: wrap;
}

.box-pagination {
  width: 100%;
  margin-top: 16px;
}

.box-prodects-favorites {
  display: flex;
  flex-wrap: wrap;
}

.title-favorites {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #333333;
  padding: 0;
  margin-bottom: 32px;
}

.skeleton-product-cards {
  gap: 16px;
}

@media (max-width: 430px) {
  .box-prodects-favorites {
    .container-cards {
      width: 100% !important;
      .swiper {
        width: 100% !important;
        .container-cart-button {
          max-width: 100% !important;
        }
      }
    }
  }
}
