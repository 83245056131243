.smart-about-text {
  max-width: 746px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
}

.img-smart-info {
  width: 48px;
  height: 48px;
}

.text-smart-info {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 16px 0px 0px 0px;
}

.cards-info-company {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.col-smart {
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.title-smart-about {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.col-smart-about-text {
  display: flex;
  gap: 32px;
  flex-direction: column;
}

.row-page-company-text {
  margin-top: 96px !important;
}