.article-card {
  // width: 30%;
  width: 100%;
  max-width: 426px;
  padding: 6px;
  height: 342px;
  margin-top: 26px;
  cursor: pointer;
}

.article-card-disabled {
  // width: 30%;
  width: 100%;
  max-width: 426px;
  padding: 6px;
  height: 342px;
  margin-top: 26px;
}

.article-card:nth-child(1) {
  padding-left: 0;
}

.article-card:nth-child(3) {
  padding-right: 0;
}

.article-card:hover .article-card-title {
  text-decoration: underline;
}

.article-card-img {
  width: 100%;
  max-width: 411px;
  max-height: 278px;
  margin-bottom: 10px;
  border-radius: 24px;
  object-fit: cover;
}

.article-card-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #272729;
  margin: 0px 0px 8px 0px;
  padding: 0;
  cursor: pointer;
}

.article-card-subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7d7d80;
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

@media (max-width: 430px) {
  .slick-slider {
    .article-card {
      height: auto !important;
    }
  }

  .article-card-disabled {
    height: auto;
  }
}
