.container-password-inputs {
  width: 100%;
}

.button-box-edit-password {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .button-save-edit-password {
    border: 0 !important;
  }
}

.button-edit-password-modal {
  max-width: 224px !important;
  width: 100%;
  height: 48px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin: 24px 0px 0px 0px !important;
  height: 44px !important;
}

.button-save-edit-password {
  height: 44px !important;
  margin-top: 8px;
}

.box-forgot-password {
  display: flex;
  margin-top: -4px;
  margin-bottom: 16px;
  margin-left: 16px;
}

.text-forgot-password {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #272729;
  padding: 0px;
  margin: 0px 8px 0px 0px;
}

.text-open-forgot-password {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5032c8;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.text-open-forgot-password:hover {
  text-decoration: underline;
}

@media (max-width: 430px) {
  .button-edit-password-modal {
    max-width: 100% !important;
    margin-top: 16px !important;
  }
}
