.container-contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1300px;
}

.contacts-head {
  width: 100%;
  display: flex;
  align-items: center;
}

.head-box-text {
  max-width: 880px;
  width: 100%;
}

.subtitle-contacts {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
}

.element-box {
  display: flex;
  border-bottom: 1px solid #f2f2f5;
  margin-bottom: 24px;
}

.element-box:last-child {
  border-bottom: 0;
}

.box-name {
  width: 100%;
  max-width: 416px;
}

.name-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.box-address {
  width: 46%;
  margin-left: 24px;
}

.address {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
}

.box-contacts {
  display: flex;
  margin-bottom: 4px;
}

.box-contacts:last-child {
  margin-bottom: 24px;
}

.title-time {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7d7d80;
  padding: 0;
  margin: 0;
}

.time {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.title-phone {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7d7d80;
  padding: 0;
  margin: 0;
}

.phone {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.title-email {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7d7d80;
  padding: 0;
  margin: 0;
}

.email {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
}
