.banner-found-nothing {
  width: 100%;
  background: #f2f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  padding: 34px;
}

.banner-found-nothing-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0px 0px 12px 0px;
}

.banner-found-nothing-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  max-width: 410px;
  text-align: center;
  margin: 0px 0px 40px 0px;
}

.button-banner-found-nothing {
  max-width: 199px !important;
  background: #5032c8 !important;
  width: 100% !important;
  height: 44px !important;
}

.button-banner-found-nothing:hover {
  background: #4b30b4 !important;
}

.button-text-found-nothing {
  font-size: 16px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  margin-left: 7px !important;
}
