.mobile-app-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 5px 0px 24px 0px;
}

.box-mobile-app-links {
  width: 588px;
  height: 172px;
  background: #fafafc;
  border-radius: 16px;
  display: flex;
  padding: 16px;
  gap: 16px;
  margin-bottom: 24px;
}

.mobile-app-links-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.mobile-app-buttons-text {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #333333;
  margin: 0px 0px 8px 0px;
}

.mobile-app-links {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.box-mobile-app-buttons {
  display: flex;
  gap: 8px;
}

.black-button-app {
  background: #000000;
  height: 34px;
  border-radius: 1px solid #a6a6a6 !important;
  border-radius: 8px;
}

.mobile-app-title-delivery {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.mobile-app-text-delivery {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0px 0px 12px 0px;
}

.map-block-mobile {
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .__modal-mobile-app {
    .modal-header {
      align-items: flex-start;
      padding-left: 16px !important;
      padding-right: 16px !important;
      .modal-title {
        margin-top: 40px;
        .exit-form-title {
          text-align: start;
          font-weight: 500;
        }
      }
    }
    .mobile-app-text {
      text-align: center;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 430px) {
  .map-block-mobile {
    margin-left: -16px;
  }
  .container-mobile-app {
    width: 100%;

    .mobile-app-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .box-mobile-app-links {
      width: 100%;
      height: unset;

      .box-mobile-app-buttons {
        width: 80%;
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
  }
}
