.__carousel-article-cards {
  // margin: 0 16px 0px 36px;
  padding: 0px 20px;
}

@media (max-width: 768px) {
  // .__carousel-article-cards > .slick-slide {
  //   // min-width: 250px;
  // }
  // .__carousel-article-cards > .article-card-img {
  //   height: 170px;
  //   width: 100%;
  // }
  // .__carousel-article-cards > .slick-slider > .slick-list > .slick-track {
  //   margin-left: -25px;
  //   div {
  //     min-width: 250px;
  //   }
  // }
  // .__carousel-article-cards > .slick-slider > .slick-list {
  //   width: 114%;
  // }
  // .slick-slide {
  //   opacity: 1;
  //   transition: opacity 0.5s;
  // }
  // .slick-current,
  // .slick-current-clone-animate {
  //   opacity: 1;
  // }
  // .article-card-title {
  //   font-weight: 500;
  //   font-size: 18px;
  //   line-height: 28px;
  //   margin-bottom: 4px;
  //   color: #333333;
  // }
  // .article-card-subtitle {
  //   font-weight: 300;
  //   font-size: 16px;
  //   line-height: 24px;
  // }
  // .slider-article-cards {
  //   max-height: 400px !important;
  //   margin-bottom: 24px;
  //   .slick-slider {
  //     height: 280px !important;
  //   }
  // }
  .slick-slider {
    .article-card {
      // max-height: 350px;
      width: 270px !important;
      img {
        max-height: 172px !important;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .__carousel-article-cards {
    padding: 0px 10px !important;
  }
}

.slider-article-cards {
  .container-button-content {
    flex-direction: row-reverse;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(56deg) brightness(107%) contrast(101%);
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }
    .button-text-show-all {
      width: auto !important;
    }
  }
}
