.container-catalog {
  width: 57%;
  background: #ffffff;
  border-radius: 16px;
  height: 200px;
  max-width: 400px;
  max-height: 80%;
  height: auto;
  box-shadow: -5px 4px 12px rgba(0, 0, 0, 0.12);
}

.elem-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  // padding: 0 16px;
  margin-bottom: 12px;
  // margin: 20px 20px 20px 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  .elem-list-link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }
}

.elem-list-icons {
  width: 40px;
  height: 40px;
}

.elem-list:hover > .elem-list-icons {
  border-radius: 5px;
  filter: brightness(0) saturate(100%) invert(22%) sepia(56%) saturate(4319%)
    hue-rotate(247deg) brightness(79%) contrast(98%);
}

.elem-list:hover > .elem-list-text {
  color: #5032c8;
}

.__color {
  color: #5032c8 !important;
}

.__background {
  filter: brightness(0) saturate(100%) invert(22%) sepia(56%) saturate(4319%)
    hue-rotate(247deg) brightness(79%) contrast(98%);
}

.__opacity {
  filter: none !important;
}

.elem-list-text {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0;
  width: 83%;
  margin-left: 16px;
  text-decoration: none;
}

.elem-list-img {
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(57%) sepia(7%) saturate(0%)
    hue-rotate(266deg) brightness(96%) contrast(85%);
}

.catalog-list {
  max-height: 100%;
  padding: 16px;
  height: auto;
  overflow: auto;
}

.loading-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 25% 35%;
}

.hidden-catalog {
  display: none;
}