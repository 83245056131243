input[type="text"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
}

input[type="text"] {
  font-size: 16px;
}

.App {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.container-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-backdrop {
  transition: none !important;
}

.__modal-backgroud-none {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  transition: none !important;
  .modal-dialog {
    transition: none !important;
  }
}

.__delete-modal-open-backdrop {
  z-index: 1235 !important;
  border: 0 !important;
  outline: 0 !important;
}

.__delete-modal-open {
  z-index: 1235;
  opacity: 1 !important;
  background: transparent;
}

.link-form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-forgot-password {
  height: 44px !important;
  max-width: 100% !important;
}

.button-auth-modal {
  height: 44px !important;
}

.container-1440 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 62px 0px 62px;
  margin-top: 24px;
}

@media screen and (max-width: 1024px) {
  .container-delivery-smart-card {
    margin-top: 48px !important;
  }
}

@media screen and (max-width: 430px) {
  .container-1440 {
    padding: 0 16px;
  }

  .container-delivery-smart-card {
    margin-top: 40px !important;
    flex-direction: column;
    margin-bottom: 8px !important;
  }

  .container-delivery-card {
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
  }

  .shipping-and-payment-container {
    padding: 0 16px;
  }
}

.container-delivery-smart-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 96px;
  margin-bottom: 96px;
}

.container-shipping-and-payment {
  width: 100%;
  padding: 0px 72px;
}

.spinner-border {
  color: #5032c8;
}

.container-feedback {
  display: flex;
  width: 100%;
  margin-top: 96px;
}

@media screen and (max-width: 820px) {
  .container-feedback {
    flex-direction: column;
    margin-top: 40px;
  }

  .container-formik-input-vacancy {
    flex-direction: column;
    width: 100%;
  }

  .box-vacansy-personal-data {
    width: 100%;
  }

  .input-auth {
    max-width: unset;
  }
}

.col-menu-account {
  margin-top: 16px;
}

.col-content-account-info {
  width: 100%;
  max-width: 636px;
  margin-top: 16px;
}

.col-content-account {
  margin-top: 16px;
}

.row-basket-page {
  margin-top: 32px;
}

.row-news-page {
  margin-top: 16px !important;
}

.title-page-news {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.accordion-number-order {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #131313;
  margin: 0px 0px 8px 0px;
}

.container-page-news-list {
  margin-top: 16px;
}

.img-news {
  max-width: 856px;
  max-height: 510px;
  border-radius: 24px;
  padding: 0px;
  object-fit: cover;
}

@media screen and (max-width: 430px) {
  .container-page-stores {
    margin-top: unset;
    padding-left: 16px;
    padding-right: 16px;
  }

  .shipping-and-payment-box-map {
    margin: 40px 0 40px -16px !important;
  }
}

.shipping-and-payment-box-map {
  margin-top: 96px;
}

.row-page-promotions {
  margin-top: 96px;
}

.row-page-partners-banner {
  margin-top: 32px;
}

.row-page-partners {
  margin-top: 96px;
}

.row-page-career {
  margin-top: 96px;
}

.row-page-contacts {
  margin-top: 16px;
}

.row-page-company {
  margin-top: 96px;
}

.row-banner-page-company {
  margin-top: 32px;
}

.title-news-page {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #333333;
  margin: 0px 0px 32px 0px;
}

.row-page-new-img {
  margin-bottom: 40px;
  margin-top: 32px;
}

.btn-close:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.spinner-banner-smart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100%;
  margin-top: 20%;
}

.slick-vertical .slick-slide {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.slick-track {
  height: auto !important;
}

.__store-promotions-col {
  .slick-track {
    height: 395px !important;
  }
}

.button-news {
  max-width: 193px !important;
  width: 100%;
  height: 44px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  margin-top: 16px !important;
}

.button-news-text {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.link-news-button {
  width: 190px;
  height: 44px;
  border: 0;
  border-radius: 22px;
}

.button-news:hover {
  background: #613df2 !important;
}

.row-new-carousel-products {
  margin-top: 55px;
}

.modal-width-forgot {
  max-width: 416px !important;
}

.container-policy-personal-data {
  margin-top: 64px;
}

.container-individual-data {
  margin-top: 64px;
}

.smart-banner-app-box {
  position: relative;
  z-index: 12;
}

.smart-banner-app-box > div {
  min-height: 76px !important;
  height: auto !important;
  max-height: 68px !important;
  background: #fafafc !important;
  padding: 10px 16px 10px 10px !important;
  width: 100% !important;
  margin: 0 !important;
  gap: 0 !important;
  justify-content: flex-start !important;
  border: 0 !important;
}

.smart-banner-app-box > div > div {
  width: 100% !important;
  max-width: 213px !important;
  flex-grow: inherit;
}

.open-in-app-banner-open-button:hover {
  color: #ffffff !important;
  text-decoration: none !important;
  background: #613df2 !important;
}

.open-in-app-banner-open-button {
  position: absolute !important;
  z-index: 13 !important;
  right: 16px !important;
  top: 28% !important;
  background-color: #5032c8 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #ffffff !important;
  width: 83px !important;
  height: 32px !important;
  background: #5032c8 !important;
  border-radius: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
  border: 0 !important;
  padding: 7px 14px 4px 14px !important;
}

.open-in-app-banner-app-name {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #333333 !important;
  margin-bottom: 4px;
}

.open-in-app-banner-description {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #8b8b8b !important;
}

.smart-banner-app-box > div > div > span:first-child {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #333333 !important;
  margin-bottom: 4px;
}

.smart-banner-app-box > div > div > span:last-child {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #8b8b8b !important;
}

.scrollbar-container {
  height: auto !important;
}

.modal-body {
  padding: 0 24px 24px 24px !important;

  .slick-slider {
    width: 90%;
  }

  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product-photo-modal-preview {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 80px;
    }
  }
}

.breadcrumb-container {
  margin-top: 24px;
}

.button-sort-primary {
  background-color: #f7f7f7;
  padding: 12px 24px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 22px;
  display: flex !important;
  align-items: center !important;
}

.button-sort-primary:hover {
  text-decoration: none !important;
  background-color: #e0dbdb !important;
}

.container-header-bot {
  .cart-button {
    button {
      border: 1px solid #f2f2f5 !important;
    }
    .__active-button {
      border-color: #5032c8 !important;
    }
  }
}

#dropdown-basic-button:focus {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
}

.text-agreement {
  margin-top: 24px;
}

.dropdown-elements {
  color: #333333 !important;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  padding: 10px 16px !important;
}

.dropdown-elements:hover {
  background-color: #5032c8 !important;
  color: #f7f7f7 !important;
}

.dropdown-menu {
  width: auto !important;
  padding: 0 !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px !important;
  height: auto !important;
  overflow: hidden !important;
}

.sort-button-catalog {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #5032c8 !important;
  color: #f7f7f7 !important;
}

.button-sort-primary::after {
  content: url("../src/image/sort.svg") !important;
  border: 0 !important;
  height: 24px;
  margin-left: 8px !important;
}

.button-show-more-item {
  max-width: 161px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  margin-bottom: 16px !important;
}

.box-sort-dropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
#dropdown-basic-button {
  color: #5032c8;
  background-color: transparent;
  border: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
}

#dropdown-basic-button:hover {
  background-color: transparent !important;
  border: 0 !important;
  text-decoration: underline;
}

.dropdown-menu {
  overflow: scroll;
  height: 250px;
  width: 100%;
  min-width: 250px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.banner-smart-club {
  background: #5032c8;
  height: 311px;
  max-width: 1296px;
  width: 100%;
  border-radius: 24px;

  .banner-smart-club-button {
    margin-left: 40px;
    margin-top: 36px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 22px;
    border: none;
    padding: 12px 24px;
    color: #5032c8;
    height: 44px;
  }
}

.container-banner-smart-club {
  margin-top: 37px !important;
  position: relative;
  width: 100%;
}

.box-clear {
  height: 59px;
  width: 100%;
  font-family: "Gerbera", "Trebuchet MS";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
}

.title-banner-smart-club {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 44px;
  color: #ffffff;
  margin: 0px 0px 24px 0px;
  padding: 40px 0px 0px 40px;
}

.text-banner-smart-club {
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: #ffffff;
  margin: 0;
  padding: 0px 0px 0px 40px;
  max-width: 596px;
}

.img-banner-smart {
  position: absolute;
  max-width: 314px;
  width: 100%;
  top: 0;
  left: calc(100% - 38%);
}

.img-banner-partners {
  position: absolute;
  max-width: 384px;
  max-height: 382px;
  width: 100%;
  bottom: 0;
  right: 20px;
}

.box-button-banner {
  margin: 64px 0px 0px 40px;
}

.vacancy-img {
  max-width: 460px;
  max-height: 425px;
  right: 48px;
  height: 100%;
}

.container-accordion {
  border-radius: 8px;
  max-width: 1296px;
  width: 100%;
  margin: 0px 0px 0px 0px;
}

.header-accordion {
  margin-top: 24px;
  border: 1px solid #d9d9db;
  border-radius: 16px;
  height: auto;
}

.__open-accordion {
  border-radius: 16px 16px 0px 0px;
  border-bottom: 0;
}

h3 {
  padding: 1rem;
  margin: 0;
  line-height: 1;
}

.panel-accordion {
  padding: 0 1rem 1rem;
  height: auto;
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  border: 1px solid #d9d9db;
  border-radius: 0px 0px 16px 16px;
  border-top: 0;
  margin-top: -10px;
  padding-top: 10px;
  white-space: pre-line;
}

.panel-accordion--closed {
  padding: 0;
  height: 0;
  display: none;
}

.buttons-accordion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
}

.title-accordion {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #272729;
  margin: 0;
  display: flex;
  align-items: center;
  height: auto;
}

.button-accordion {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  display: flex;
  align-items: center;
  margin: 0;

  .arrow__up {
    transform: rotate(180deg);
  }
}

.button-accordion:hover {
  text-decoration: underline;
}

.row-page-club-smart {
  margin-top: 96px !important;
}

.delivery-methods-history {
  display: flex;
  gap: 12px;
  width: 100%;
}

.delivery-method-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 7px 16px;
  gap: 8px;
  width: auto;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.catalog-child-groups-list {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0px 14px 0px;
  .delivery-method-history {
    margin: 0px 12px 12px 0px;
  }
}

.delivery-methods-history {
  overflow-y: scroll;
  // width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.delivery-methods-history::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.delivery-method-history-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.delivery-method-history:hover {
  border: 1px solid #5032c8;
  .delivery-method-history-text {
    color: #5032c8;
  }
}

.__current-method {
  background: #5032c8;
  border: 1px solid #5032c8 !important;
  .delivery-method-history-text {
    color: #ffffff !important;
  }
}

.product-mark-review {
  margin-bottom: 1.75rem;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  padding: 16px;
}

.product-mark-review-header {
  display: flex;
  justify-content: space-between;
}

.product-mark-review-header-title {
  display: flex;
  padding-right: 16px;
}

.product-mark-review-header-title-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-right: 16px;
}

.product-mark-review-header-title-date {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
}

.product-mark-review-header-mark {
  display: flex;
}
.product-mark-review-header-mark img {
  padding-right: 4px;
}
.product-mark-review-header-mark span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: 4px;
  display: block;
  padding-top: 2px;
}

.product-mark-review-body {
  padding-top: 16px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.give-mark-review-container {
  display: flex;
  justify-content: center;
}

.give-mark-review-button {
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 22px;
  cursor: pointer;
  padding: 12px 24px;
  color: #5032c8;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.product-mark-review-photos {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.rate-stars-box {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 343px;
}

.product-mark-stars {
  display: flex;
  align-items: stretch;
}
.product-mark-stars img {
  width: 32px;
  height: 32px;
  padding-right: 4px;
}

.product-mark-stars span {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  margin-left: 12px;
  display: block;
  padding-top: 2px;
}

.container-formik-input {
  display: flex;
  width: 588px;
  align-items: center;
  margin-bottom: 16px;
}

.box-name-phone-city {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 248px;
}

.container-input-file {
  max-width: 282px;
  width: 100%;
  height: 248px;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  margin-top: 11px;
}

.box-file-input {
  width: 100%;
  margin-left: 24px;
}
.input__wrapper {
  width: 90%;
  position: relative;
  margin: 15px 0;
  text-align: center;
  border-radius: 24px;
  box-shadow: 0px 5px 10px 0px rgb(80 50 200 / 10%);
}

.input__file {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 99;
  left: 0;
  bottom: 0;
  height: 100%;
  width: auto;
  max-width: 252px;
}

.input__file::file-selector-button {
  width: 252px;
  height: 50px;
  cursor: pointer;
  border-radius: 24px;
}

.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #fff;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}

.input__file-button {
  width: 100%;
  max-width: 209px;
  height: 50px;
  background: #ffffff;
  color: #5032c8;
  font-size: 1.125rem;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-file-loaded {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px 12px 0px;
}

.input-file-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 0;
}

.input-file-info {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: #272729;
  margin: 0px 0px 12px 0px;
}

.img-file {
  width: 48px;
  height: 48px;
}

.box-text-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px 12px 0px;
}

.name-file {
  width: 180px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a2a2a6;
  margin: 0;
  overflow: hidden;
  text-align: center;
}

.text-loaded-file {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a2a2a6;
  margin-top: 12px;
}

.box-button-file {
  width: 100%;
  gap: 5px;
  height: 44px;
  display: flex;
  justify-content: center;
}

.__box-button-file-multi {
  justify-content: space-between;
}

.button-delete-file {
  max-width: 143px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 30px !important;
}

.__error {
  color: #ed1414;
}

.input-multy {
  width: 50%;
  margin: 0;
  background: #ffffff;
}

.__text-multy-file {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
}

.input-file-multy {
  width: 100%;
}

.__button-multy-file {
  width: 50% !important;
}

.__lable-multy-input {
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
  border-radius: 22px;
  height: 44px;
  cursor: pointer;
}

.input-multy:hover .__lable-multy-input {
  background: #f2f2f5;
  box-shadow: none;
}

.container-carousel-cards {
  margin-right: 78px;
  position: relative;
  width: 100%;
  max-width: 1300px;
}

.clients-slide {
  border-radius: 24px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-slider {
  max-width: 1320px;
}

.slick-arrow {
  height: 48px !important;
  width: 48px !important;
  z-index: 10 !important;
  top: 35% !important;
  top: calc(64% - 48px / 2 - 52px) !important;
  background-color: #ffffff !important;
  border-radius: 200px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 0 !important;
}

.slick-prev:before {
  height: auto;
  content: url("../src/image/left-arrow.svg") !important;
  margin-left: -3px;
}

.slick-next:before {
  height: auto;
  content: url("../src/image/right-arrow.svg") !important;
  margin-right: -3px;
}

.container-page-recipes {
  .title-page-news {
    padding: 0px 10px;
  }
}

.__modal-support-desktop {
  max-width: 636px !important;
  .container-phone-input {
    margin-top: 0;
  }
}

.head-box-connect {
  width: 50%;
  margin-left: 24px;
}

.head-box-connect {
  .box-connect-color {
    height: 300px;
    max-height: 300px;
  }
}

.box-connect-color {
  width: 100%;
  height: 100%;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7fa;
  border-radius: 24px;
  max-width: 416px;
  height: 256px;
}

.connect-title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: #272729;
  margin: 16px 0px 24px 0px;
  text-align: center;
}

.button-connect {
  max-width: 181px;
  width: 100%;
  margin-bottom: 24px;

  .__auth-button {
    background-color: #ffffff !important;

    .content-button-text {
      color: #5032c8 !important;
    }
  }
}

.text-phone {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0px 0px 8px 0px;
}

.number-phone {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
}

.container-offices {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.offices-content {
  max-width: 880px;
  width: 100%;
}

.office-people {
  width: 100%;
  max-width: 416px;
}

.box-office {
  display: flex;
  border-bottom: 1px solid #f2f2f5;
  padding-bottom: 24px;
}

.box-office:last-child {
  border: 0;
}

.post {
  margin-left: 24px;
  margin-top: 24px;
  width: stretch;
}

.offices-title {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
}

.people-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  margin-top: 24px;
}

.people-phone {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-bottom: 4px;
}

.people-email {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  text-decoration: none;
}

.people-email:hover {
  color: #613df2;
}

.post-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7d7d80;
  padding: 0;
  margin: 0;
}

.button-feedback-block {
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 24px !important;
  div {
    p {
      color: #5032c8 !important;
    }
  }
}

.button-feedback-block:hover {
  background: #f1f0f0 !important;
  box-shadow: none !important;
}

.row-account-page {
  .box-nav-account {
    .cart-counter-mobile {
      right: 80px !important;
      top: 2px !important;
    }
  }
}

/////////-------------------------------------///////////

@media screen and (max-width: 1400px) {
  .img-banner-smart {
    left: 50%;
  }
}

@media (max-width: 1200px) {
  .container-page-recipes {
    padding: 0;
    margin: 0px 28px;
    width: 100%;
    max-width: 95%;
  }
}

@media screen and (max-width: 1024px) {
  .head-box-connect {
    margin-top: 40px;

    .box-connect-color {
      max-width: unset;
      max-height: unset;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 431px) {
  .box-name {
    max-width: 50%;
  }

  .box-office {
    width: 100%;
    justify-content: space-between;
  }

  .offices-content {
    max-width: unset !important;
  }

  .head-box-connect {
    width: 65%;
  }
}

@media (max-width: 1000px) {
  .img-banner-smart {
    display: none;
  }
  .banner-smart-club {
    height: auto;
    padding: 0px 40px 40px 0px;
  }
}

@media (max-width: 950px) {
  .container-formik-input {
    flex-wrap: wrap;
    width: 100%;
  }
  .__container-form-modal {
    .input-auth {
      max-width: 100%;
    }
  }
  .__rent {
    padding: 0 !important;
  }

  .box-file-input {
    margin-left: 0;
  }
}

@media screen and (max-width: 820px) {
  .row-page-club-smart {
    margin-top: 32px !important;
  }
  .head-box-connect {
    &.fullwidth {
      width: 100%;
      margin-left: 0;
      margin-top: 24px;
    }
  }
  .subtitle-contacts {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
  }
  .element-box {
    flex-wrap: wrap;
  }
  .box-name {
    margin-bottom: 16px;
  }
  .name-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  .box-address {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .address {
    font-weight: 400;
  }
  .time {
    font-weight: 400;
  }
  .phone {
    font-weight: 400;
  }
  .box-contacts:last-child {
    margin-bottom: 0px;
  }
  .element-box {
    margin-bottom: 16px;
  }
  .element-box:last-child {
    margin-bottom: 40px;
  }
  .offices-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .people-name {
    margin-top: 16;
    margin-bottom: 4px;
  }
  .post-text {
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .container-input-file {
    max-width: 100%;
    height: 144px;
  }
  .input-file-text {
    margin-bottom: 0;
  }
  .container-input-file {
    padding-bottom: 0;
  }
  .text-agreement {
    width: auto;
  }
  .container-input-file {
    margin: 24px 0px 0px 0px;
  }
  .new-width-modal {
    margin: 0 !important;
  }
  .new-width-modal {
    border-radius: 0 !important;
  }
  .container-page-recipes {
    margin: 0;
    max-width: 100%;
    padding: 0px 12px;
  }
  .__support {
    .box-name-phone-city {
      margin-bottom: 24px;
    }
    .button-box-auth {
      width: 100%;
      button {
        width: 100% !important;
        max-width: 100% !important;
        height: 44px !important;
        background: #5032c8 !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
        border-radius: 22px !important;
        &:disabled {
          width: 124px !important;
          background: #f2f2f2 !important;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          margin-left: 0;
        }
      }
    }
  }
  .catalog-child-groups-list {
    flex-wrap: nowrap;
    margin-bottom: 0;
    overflow: auto;
    position: relative;
  }
}

@media (max-width: 576px) {
  .row-page-company-text {
    flex-direction: column-reverse;
    margin-top: 32px !important;
    gap: 34px;
  }
  .row-page-company {
    margin-top: 40px !important;
    .__carousel-article-cards {
      .slick-list {
        height: 290px !important;
      }
    }
  }
  .col-smart {
    gap: 24px;
  }

  .col-smart-about-text {
    gap: 24px;
  }

  .box-button-app {
    justify-content: center;
  }

  .col-banner-img-mobile {
    display: none;
  }

  .row-mobile-banner {
    padding: 0 !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
  }
  .container-page-company {
    padding: 0px 16px !important;
  }

  .mobile-banner-left {
    padding: 16px !important;
  }

  .col-mobile-banner-left {
    padding: 0 !important;
  }
  .title-smart-about {
    margin-top: 34px;
  }

  .box-button-app {
    gap: 10px;
  }
  .button-app {
    margin: 0 !important;
  }
}

.button-app {
  max-width: fit-content;
  padding: 8px 16px;

  .store {
    white-space: nowrap;
  }
}

@media screen and (max-width: 430px) {
  .container-page-recipes {
    .title-page-news {
      padding: 0px;
    }
  }
  .container-cart-success {
    padding: 0 !important;
  }
  .head-box-connect {
    margin: 0;
    width: 100%;
  }
  .__rent {
    .box-name-phone-city {
      height: 285px;
    }
  }
  .success-rent-form {
    top: calc(50% - 200px / 2);
    padding: 0px 16px;
    .success-rent-form {
      border-radius: 24px !important;
      .modal-header {
        margin-bottom: 12px;
        .modal-title {
          width: 100%;
          .auth-form-title {
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            text-align: center;
          }
        }
        button {
          display: none;
        }
      }
      .modal-body {
        .__support {
          align-items: center;
          .text-support-modal {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #333333;
          }
          .button-edit-password-account {
            width: 100%;
            height: 44px !important;
            background: #5032c8 !important;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
            border-radius: 22px !important;
            .button-text-edit-password-account {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #ffffff;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .modal-open {
    padding-right: unset !important;
  }
  .banner-smart-club-button {
    width: stretch;
    margin-left: 24px !important;
  }
  .box-clear {
    display: none;
  }
  .container-banner-smart-club {
    margin-top: 16px;
  }
  .club-smart-row {
    .container-banner-smart-club {
      margin-top: 16px !important;

      .title-banner-smart-club {
        font-size: 28px;
        line-height: 32px;
      }

      .text-banner-smart-club {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .row-page-club-smart {
    margin-top: 16px !important;

    .__container-mobile-banner {
      margin-top: 40px;
    }
  }

  .row-mobile-banner {
    margin-right: 0 !important;
    padding: 0 !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
  }

  .club-smart-mobile-banner {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  .__container-mobile-banner {
    padding: 0 !important;
  }

  .button-app {
    margin-bottom: 16px !important;
  }

  .col-smart {
    gap: 0;
  }
  .title-smart-about {
    margin-bottom: 24px;
  }
  .row-page-company-text {
    gap: 0;
  }
  .title-smart-about {
    margin-top: 0;
  }
  .col-smart {
    margin-bottom: 32px;
  }
  .col-smart-about-text {
    gap: 0;
  }
  .row-page-company {
    .article-title {
      padding: 0px !important;
    }
  }
  .product-mark-stars {
    img {
      width: 40px;
      height: 40px;
    }

    span {
      font-weight: 500 !important;
      font-size: 18px !important;
      line-height: 28px !important;
      height: auto !important;
      display: flex !important;
      align-items: center;
    }
  }

  .product-mark-review-header {
    flex-direction: column;
  }

  .product-mark-review-header-mark img {
    width: 20px;
    height: 20px;
  }

  .review-header-my-rate {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 16px;
  }

  .detele-rate-buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .button-delete-rate,
    .button-close-delete-rate {
      max-width: unset !important;
      width: 100%;
    }
  }
  .product-mark-review-header {
    flex-direction: column;
  }
  .product-mark-review-header-title {
    flex-direction: column;
  }
  .product-mark-review-body {
    margin-top: 16px;
  }
  .rate-stars-box {
    max-width: unset;
    justify-content: flex-start;
    padding: 0px;

    .product-mark-stars {
      align-items: center;
    }
  }
  .container-formik-input {
    flex-direction: column;
    width: 100%;
  }
  .modal-body {
    align-items: flex-start;
    padding: 0 16px 16px 16px !important;
  }

  .modal-dialog {
    &.confirm-modal {
      padding: 0 16px 0 16px !important;
    }
    .button-edit-password-account {
      width: 100%;
      height: 44px !important;
      background: #5032c8 !important;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
      border-radius: 22px !important;
      margin-top: 8px;
      .button-text-edit-password-account {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        margin-left: 0;
      }
    }
  }

  .confirm-modal {
    margin: 30vh 0 0 0 !important;

    .modal-content {
      border-radius: 24px !important;
      height: auto !important;
    }

    &.modal-content {
      max-width: 90vw;
    }
  }

  .text-area-error-support {
    margin-top: 0;
  }

  .__support {
    .box-name-phone-city {
      height: unset;

      .container-input,
      .container-phone-input {
        margin-top: 16px;
      }

      .__modal-message {
        margin-bottom: unset;
      }
    }

    .box-file-input {
      display: flex;
      justify-content: center;
    }

    .text-agreement {
      width: auto;
      text-align: center;
    }

    .text-support-modal {
      margin-bottom: 16px;
    }

    .container-input-file {
      max-width: 100%;
      height: fit-content;

      .input-file-info {
        margin-bottom: unset;
      }
    }
  }
  .container-input-file {
    margin-bottom: 32px;
  }

  .__vacancy-file {
    height: unset !important;
  }

  .box-file-input,
  .__vacansy {
    margin-left: unset !important;
  }
}
@media (max-width: 400px) {
  .__container-mobile-banner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mobile-banner-left {
    padding: 16px !important;
  }
  .mobile-banner-left-text {
    margin-bottom: 24px;
  }
  .box-button-app {
    margin: 0 !important;
  }
}

@media screen and (max-width: 820px) and (min-width: 431px) {
  .container-account-page {
    max-width: unset;
    margin-left: 0 !important;
    padding-left: 32px;
  }

  .col-content-account-info {
    padding: 0 !important;
    max-width: unset !important;
    width: 75%;
  }
}

@media (max-width: 768px) {
  .row-page-new-img {
    padding: 0 10px;
    margin-top: 32px;
  }
  .img-news {
    height: 382px;
  }
  .container-account-page {
    padding: 0px 16px !important;
    margin: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }
  .row-account-page {
    width: 100% !important;
  }

  .electronic-check-image {
    display: none;
  }
  .button-edit-password-account {
    max-width: 100% !important;
  }
}

@media (max-width: 430px) {
  .modal-fullscreen-mobile {
    margin: 0 !important;
    border-radius: 0 !important;
    border: 0 !important;
    height: 100% !important;
  }

  .spinner {
    display: none !important;
  }
  .containe-banner-partners-img {
    margin-top: 24px;
  }
  .col-content-account {
    width: 100% !important;
    padding: 0 !important;
  }
  .container-page-product {
    padding: 0px 16px !important;
  }
  .row-news-page {
    padding: 0px 16px !important;
  }
  .container-delivery-card {
    margin: 0 0 32px !important;
    width: 100% !important;
  }
  .row-page-promotions {
    margin-top: 40px;
    padding: 0px 7px 0px 11px;
  }
  .container-map {
    margin-top: 40px !important;
  }

  .modal-city-vacancy {
    .modal-body {
      height: 100%;
    }
    .container-city-selection {
      height: 100%;
      .container {
        height: 100%;
        .row-modal-city {
          height: 100%;
          position: relative;
          --bs-gutter-x: 0;
          .col-modal-city-content {
            position: absolute;
            top: 90px;
            left: 0;
            height: 90%;
            overflow: scroll;
            padding-bottom: 16px;
            min-height: auto !important;
            padding-bottom: 16px !important;
            .list-city {
              height: auto !important;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .modal-dialog.__delete-modal {
    margin: 0px 16px !important;
    margin-top: calc(100% - 30%) !important;
    width: auto !important;
    border-radius: 24px !important;
    height: 172px !important;
    .modal-content {
      margin: 0 !important;
      width: auto !important;
      height: 242px !important;
      border-radius: 24px !important;
      .delete-buttons {
        margin-top: 24px !important;
        flex-direction: column !important;
        button {
          width: 100% !important;
          max-width: 100% !important;
          &:last-child {
            margin-top: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .img-news {
    height: 358px;
  }
  .smart-banner-app-box > div > div {
    max-width: 189px !important;
  }
}

@media (max-width: 360px) {
  .img-news {
    height: 328px;
  }
  .smart-banner-app-box > div > div {
    max-width: 159px !important;
  }
}

@media (max-width: 350px) {
  .__store-promotions-col .slick-track {
    height: 198px !important;
  }
}

@media (max-width: 320px) {
  .img-news {
    height: 288px;
  }
  .smart-banner-app-box > div > div {
    max-width: 119px !important;
  }
}
