.container-page-partners {
  margin: 72px 72px 0px 72px;
}

.containe-banner-partners-img {
  max-height: 312px;
  height: 100%;
  width: 100%;
  height: 312px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.banner-partners-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
}

//-------------------------RentalOffet---------------------------

.container-rental-offer {
  max-width: 53%;
}

.img-banner-partners-page {
  width: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.rental-offer-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #272729;
  margin: 0px 0px 24px 0px;
}

.rental-offer-text-smale {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 0px 0px 40px 0px;
}

.rental-offer-text-big {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
}

.button-rent-open-modal {
  gap: 8px !important;
  width: 175px !important;
  height: 44px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
}

.button-text-rent-open-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: right;
  color: #ffffff;
  margin-left: 5px !important;
}

.button-rent-open-modal:hover {
  background: #613df2 !important;
  box-shadow: none !important;
}

//--------------------------------ContactsInfo--------------------------------

.container-contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 416px;
}

.contact-info-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #272729;
}

.box-contact-info {
  margin-bottom: 24px;
}

.contact-info-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #272729;
}

.contact-info-post {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
}

.contact-info-number {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272729;
  margin: 0;
}

.contact-info-email {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
  margin-top: 8px;
}

.container-peoples-info {
  .button-rent-open-modal {
    .container-button-content {
      margin: 0;
      justify-content: center !important;
      p {
        margin-left: 0 !important;
      }
    }
  }
}

//-------------------------pagePartners-----------------------

.box-page-partners {
  margin-top: 94px;
  display: flex;
  justify-content: space-between;
}

//-------------------------Requirements-----------------------

.container-requirements {
  margin-top: 94px;
}

.requirements-title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #272729;
  padding: 0;
}

.requirements-box {
  max-width: 326px;
  width: 100%;
  margin-left: 10px;
}

.text-requirements {
  color: #333333;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.img-requirements {
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
}

.box-cards-requirements {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

@media screen and (max-width: 1024px) {
  .box-page-partners {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 0;

    .container-contact-info {
      margin-bottom: 32px;
    }
  }

  .container-requirements {
    margin-top: 0;
  }
}

@media screen and (max-width: 820px) and (min-width: 431px) {
  .rental-offer-text-big {
    padding: 0 16px 0 0;
  }

  .row-page-partners {
    margin-top: 48px !important;
  }
}

@media (max-width: 770px) {
  .container-rental-offer {
    max-width: 100%;
  }
  .container-peoples-info {
    display: flex;
  }
  .box-contact-info:last-child {
  }
  .contact-info-title {
    margin-bottom: 0;
  }
  .container-contact-info {
    gap: 32px;
  }
  .box-contact-info {
    margin: 0 !important;
  }
  .rental-offer-text-big {
    margin: 0 !important;
  }
}

@media (max-width: 500px) {
  .container-contact-info {
    width: 100%;
    max-width: 100%;
  }
  .container-peoples-info {
    width: 100%;
  }
  .box-contact-info {
    width: 100%;
  }
  .button-rent-open-modal {
    width: 100% !important;
    text-align: center !important;
  }
  .button-rent-open-modal {
    .container-button-content {
      margin: 0 !important;
    }
  }
  .button-text-rent-open-modal {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .contact-info-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .contact-info-post {
    font-weight: 400;
    margin-bottom: 20px;
  }
  .contact-info-number {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  .contact-info-email {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  .box-page-partners {
    gap: 0;
  }
  .container-rental-offer {
    margin-top: 32px;
  }
  .requirements-box {
    margin-bottom: 8px;
  }
  .row-cards-requirements {
    width: 100%;
  }
}
