.filter-checkbox {
  display: flex;
  padding: 6px 0px;
  cursor: pointer;

  .input-group-text {
    margin-right: 17px !important;
    line-height: 20px !important;
    font-weight: 400 !important;

    input {
      width: 22px !important;
      height: 22px !important;
    }

    span {
      cursor: pointer;
    }
  }
}
.accordion-filter {
  border: 0 !important;
}

.col-catalog-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  .accordion {
    width: 100%;
  }
}

.__types-list-catalog {
  max-height: 100px;
  width: auto;
  overflow: scroll;
}

.accordion-filter-header {
  .accordion-filter-header-is-active {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: #5032c8;
    margin-left: 10px;
    margin-top: 1px;
  }
  button {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.6px;
    padding: 0;
    padding: 16px 0;
    background-color: transparent !important;
    color: #333333 !important;
    box-shadow: none !important;

    &::after {
      background-image: url("../../../image/arrow-bottom.svg") !important;
      // filter: brightness(0) saturate(100%) invert(13%) sepia(36%) saturate(22%)
      //   hue-rotate(347deg) brightness(101%) contrast(83%) !important;
      width: 20px !important;
      height: 24px !important;
    }
  }
}

.accordion-filter-header > button:hover {
  color: #5032c8 !important;
  &::after {
    filter: none;
  }
}

.accordion-filter-header > button:focus {
  border: 0;
  box-shadow: none;
}

.catalog-box {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.box-breadcrumb {
  margin-top: 20px;
}

.img-catalog-group {
  background: #f7f7f7;
  border-radius: 24px;
  object-fit: cover;
}

@media screen and (max-width: 430px) {
  .img-catalog-group {
    width: 100%;
  }
}

.card-catalog-group {
  margin-bottom: 24px;
}

.title-catalog-group {
  font-size: 22px;
  line-height: 1.4;
  letter-spacing: 0.6px;
  color: #333333;
  text-decoration: none;
  margin-top: 16px;
  display: block;
  width: 100%;
  font-weight: 500;
}

.title-catalog-group:hover {
  color: #5032c8;
}

.gpoup-title-img {
  cursor: pointer;
}

.gpoup-title-img:hover > .title-catalog-group {
  color: #5032c8;
}

.ul-child-group {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.list-child-group {
  list-style-type: none;
  margin: 0px;
  padding: 4px 0px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.4;
  color: #333333;
  text-decoration: none;
  font-weight: 400;
}

.list-child-group:hover {
  color: #5032c8;
}

.body-accordion {
  color: #333333;
  padding: 10px 0px !important;
}

.button-filter-show-more {
  border: 0 !important;
}

.button-filter-show-more:hover {
  border: 0 !important;
  background: transparent !important;
}

.button-text-filter-show-more:hover {
  color: #613df2 !important;
}

.__input-filter {
  margin-bottom: 10px;
}

.button-text-show-more-item {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  color: #272729;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #5032c8;
}

.box-button-show-more {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 16px 0px;
}

.__active-all-child-group {
  position: absolute;
  background: #ffffff;
  width: 425px;
  border-radius: 24px;
  padding: 14px;
  padding-left: 20px;
  margin: -14px;
  margin-left: -20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.box-close-all-child-group {
  min-height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
}

.none-block {
  display: none;
}

.more-all-child-group {
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: #5032c8;
}

// .more-all-child-group:hover {
//   text-decoration: underline;
// }

.accordion-filter .accordion-button::after {
  box-shadow: none !important;
  border: 0 !important;
}

.clear-filter-web-button {
  margin-top: 32px;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 22px;

  &:hover {
    background: #f2f2f5 !important;
  }

  .content-button-text {
    color: #5032c8 !important;
  }
}

@media (max-width: 1080px) {
  .col-catalog-filter {
    display: none;
  }
  .container-page-catalog {
    max-width: 100% !important;
    padding: 0px 32px !important;
  }
  .sort-button-catalog {
  }
  .sort-button-catalog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .filter-icon-button {
      background-color: #ffffff;
      width: 44px;
      height: 44px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.filtred {
        background: #5032c8;
        width: auto;
        display: flex;
        position: relative;
        flex-direction: row;
        gap: 24px;
        padding: 10px;

        .filters-length {
          position: absolute;
          right: 37px;
          top: 5px;
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          color: #5032c8;
          text-align: center;
          background: #ffffff;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          width: 18px;
          padding-top: 1px;
          // height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }

        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(0%) hue-rotate(56deg) brightness(107%) contrast(101%);
          &:last-child {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .catalog-grid-items {
    .container-cards {
      width: 306px !important;
    }
  }
}

@media screen and (max-width: 430px) {
  .container-page-catalog {
    padding: 0px 16px !important;
  }
  .more-all-child-group {
    color: #5032c8;
  }

  .catalog-item-list {
    padding: 0 16px !important;
    .container-cards {
      width: 100% !important;
    }
  }

  .accordion-filter .accordion-button::after {
    height: 100% !important;
    width: 1.25rem !important;
    right: 0;
  }

  .accordion {
    width: 100%;
  }

  .accordion-button::after {
    position: relative;
    top: unset !important;
  }

  .__input-filter {
    display: none;
  }

  .container-cards {
    width: 100% !important;

    .container-cards .mySwiperCards {
      width: 100%;
    }

    .swiper {
      width: 100% !important;
    }
  }
}
