.pagination {
  justify-content: center;
}

.page-link {
  border-radius: 200px;
  width: 48px;
  height: 48px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #272729 !important;
  text-decoration: none !important;
  border: 0 !important;
  margin: 0px 10px;
  padding: 0 !important;
}

.page-link:hover {
  border: 1px solid #5032c8 !important;
  background-color: transparent !important;
  border-radius: 200px !important;
  color: #5032c8 !important;
}

:focus-visible {
  outline: none !important;
}
.page-item.active .page-link {
  border: 1px solid #5032c8 !important;
  background-color: #5032c8 !important;
  border-radius: 200px !important;
  color: white !important;
}

@media screen and (max-width: 430px) {
  .pagination {
    justify-content: space-evenly;
  }

  .page-item, .page-link {
    height: 40px;
    width: 40px;
  }
}

.page-link:focus {
  color: #5032c8;
  background-color: transparent !important;
  outline: 0 !important;
  border-radius: 200px !important;
  box-shadow: 0 !important;
}

.page-link > span:first-child {
  font-size: 16px;
  font-weight: 100;
  color: #272729;
  line-height: 20px !important;
  display: flex;
  padding: 13px;
  justify-content: center;
  align-items: center;
}

.page-link > span:first-child {
  font-size: 16px;
  font-weight: 100;
  color: #272729;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.button-navigation-pagination > .page-link {
  display: inline-block !important;
}

.button-navigation-pagination > .page-link > span:first-child {
  font-size: 44px;
  font-weight: 100;
  color: #5032c8;
  line-height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.__prev-content-pagination > .page-link > span {
  background-image: url(../../../../image/right-arrow.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  font-size: 0px !important;
  transform: rotate(180deg);
  background-size: 24px 24px;
}

.__next-content-pagination > .page-link > span {
  background-image: url(../../../../image/right-arrow.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  font-size: 0px !important;
  background-size: 24px 24px;
}

.page-link:focus {
  box-shadow: none !important;
}
