.breadcrumb-item {
  display: flex;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #272729;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: url('../../image/breadcrumb-arrow.svg') !important;
}

.breadcrumb-item > a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8B8B8B;
  text-decoration: none;
}

.breadcrumb-item.active {
  font-weight: 500 !important;
  color: #C3C3C7 !important;
}

.breadcrumb-item > a:hover {
  text-decoration: underline;
  color: #272729;
}

.container-1440 > nav {
  width: 100%;
  margin-top: 24px;
}

.breadcrumb {
  max-width: 100%;
  overflow-x: auto;
  flex-wrap: nowrap !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}