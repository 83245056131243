.multi-switch-container {
  display: flex;
  border: 0;
  border-radius: 16px;
  height: 40px;
  border: 1px solid #f2f2f5 !important;
  margin: 0 !important;
  border-radius: 14px !important;
  padding: 1px;
}

.multi-switch-handle {
  background: #5032c8 !important;
  border-radius: 12px !important;
  height: 42px !important;
  margin-top: -1px;
}

.multi-switch-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  text-align: center !important;
}
