.container-stock-score {
  width: 100%;
  max-width: 1320px;
}

.title-h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.container-head-stock {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
}

.container-next {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text-next {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  margin: 0;
}

.text-next:hover {
  text-decoration: underline;
}

.imgArrow {
  width: 7px;
  height: 13px;
  margin-left: 9px;
}

.catalog-button-container {
  padding-right: 16px;

  .button-show-content {
    background-color: #5032c8 !important;

    p {
      color: #ffffff;
    }

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
        brightness(102%) contrast(102%);
    }
  }
}

@media screen and (max-width: 430px) {
  .container-stock-score {
    overflow: hidden;
  }
  .container-head-stock {
    padding: 0px 16px;
  }
  .catalog-button-container {
    padding: 0px 16px !important;
  }
}
