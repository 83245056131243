.title-order-status {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #272729;
  padding: 0;
  margin: 0px 0px 32px 0px;
}

.box-info-delivery {
  border-radius: 15px;
  border: 1px solid #e6e6eb;
  height: 60px;
  max-width: 460px;
  width: 100%;
  padding: 9px 0px 0px 15px;
  margin-bottom: 24px;
}

.info-delivery-lable {
  color: #616161;
  font-size: 11px;
  line-height: 1.55;
  font-weight: 300;
  margin: 0;
}

.info-delivery-text {
  color: #2e2e2e;
  font-size: 15px;
  line-height: 1.55;
  font-weight: 400;
  margin: 0;
}

.__infoOrder {
  margin-bottom: 24px;
}

.delivery-courier-info {
  display: flex;
}

.courier-info-text {
  width: 50%;
  margin-left: 34px;
}

.img-photo {
  width: 200px;
  height: 266px;
  background-color: #616161;
}

.photo-courier {
  width: 260px;
  height: 364px;
  object-fit: cover;
}

.col-account-order-status {
  .history-product-quantity {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .__order-accordion {
    margin-bottom: 24px;
  }
  .__body-order-info {
    .accordion-body-row {
      padding: 0px 24px;
      .history-info-price {
        justify-content: flex-end;
        padding-right: 24px;
        .history-product-price {
          max-width: 130px;
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
  .accordion-history {
    .accordion-history-header {
      .accordion-button {
        background: #e0d9ff !important;
        .box-header-accordion {
          .header-accordion-info {
            .accordion-header-status-order {
              display: flex;
              margin-bottom: 8px;
              .accordion-number-order {
                margin: 0;
              }
              .accordion-header-status-order-text {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;

                padding: 2px 8px;
                background: #ffffff;
                border-radius: 24px;
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                margin-left: 16px;
              }
              .__assembly {
                color: #e92c2c;
              }
              .__ready {
                color: #5032c8;
              }
              .__received {
                color: #acacac;
              }
            }
            .accordion-date {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #131313;
            }
          }
        }
      }
    }
    .accordion-collapse {
      .accordion-history-body {
        padding: 0;
        .box-order-status-info {
          padding: 24px;
          border-bottom: 1px solid #e6e6eb;
          .order-info-receiving-method {
            display: flex;
            .receiving-method-icon {
              filter: brightness(0) saturate(100%) invert(17%) sepia(45%) saturate(4004%) hue-rotate(242deg)
                brightness(115%) contrast(101%);
            }
            .receiving-method {
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              color: #333333;
              margin-left: 8px;
            }
          }
          .receiving-method-address {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #acacac;
            margin-top: 8px;
          }
          .status-order-details {
            width: 100%;
            display: flex;
            margin-top: 24px;
            .status-order-detail {
              padding-bottom: 8px;
              border-bottom: 3px solid #e6e6eb;
              width: 100%;
              display: flex;
              margin-left: 16px;
              transition: all 0.5s;
              &:first-child {
                margin-left: 0;
              }
            }
            .__active {
              border-bottom: 3px solid #5032c8;
            }
          }
        }
        .active-order-details {
          padding: 24px;
          .title-order-detailts {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #333333;
            margin: 0 0 24px 0;
            padding: 0;
          }
          .container-map {
            height: 228px;
            margin: 0;
            .map-block-title {
              display: none;
            }
            .map {
              height: 228px !important;
              ymaps[class*="controls-pane"] {
                ymaps[class*="controls__control"] {
                  inset: 45px 28px auto auto !important;
                  ymaps[class*="float-button"] {
                    width: 40px !important;
                    height: 40px !important;
                    border: 1px solid #e6e6eb !important;
                    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
                    border-radius: 48px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 !important;
                    ymaps[class*="float-button-icon"] {
                      background-image: url("../../../../image/location.svg") !important;
                      background-repeat: no-repeat !important;
                      background-size: 18px 18px !important;
                      background-position: 9px 12px !important;
                      width: 100%;
                      height: 100%;
                      border: 0 !important;
                    }
                  }
                  ymaps[class*="zoom"] {
                    height: 39px !important;
                    ymaps[class*="zoom__plus"] {
                      ymaps[class*="float-button-icon"] {
                        background-image: url("../../../../image/plus-color.svg") !important;
                        background-repeat: no-repeat !important;
                        background-size: 16px 16px !important;
                        background-position: center !important;
                        width: 100% !important;
                        height: 100% !important;
                        border: 0 !important;
                      }
                    }
                    ymaps[class*="zoom__minus"] {
                      ymaps[class*="float-button-icon"] {
                        background-image: url("../../../../image/minus-color.svg") !important;
                        background-repeat: no-repeat !important;
                        background-size: 16px 16px !important;
                        background-position: center !important;
                        width: 100%;
                        height: 100%;
                        border: 0 !important;
                      }
                    }
                  }
                }
                ymaps[class*="controls__control ymaps"] {
                  inset: 157px 16px auto auto !important;
                }
              }
            }
          }
          .active-order-detail {
            display: flex;
            .courier-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              max-width: 251px;
              margin-right: 16px;
              .image-courier {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 16px;
                background-color: #acacac;
              }
              .courier-info-detail {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                .courier-name {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 28px;
                  color: #333333;
                  margin-bottom: 4px;
                }
                .courier-car {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #8b8b8b;
                  margin-bottom: 4px;
                }
                .courier-phone {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #8b8b8b;
                }
              }
              .grade-courier {
                display: flex;

                .grade {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 10px 12px;
                  height: 44px;
                  width: auto;
                  border: 1px solid #e6e6eb;
                  border-radius: 22px;
                  margin-right: 16px;
                  .grade-image {
                    margin-right: 8px;
                  }
                  .grade-text {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333333;
                  }
                }
                .create-grade-button {
                  width: 100%;
                  max-width: 156px;
                  button {
                    height: 44px !important;
                    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
                    border-radius: 22px !important;
                    width: 100% !important;
                    max-width: 100% !important;
                    padding: 0px 20px !important;
                    div {
                      margin: 0;
                      img {
                        display: none;
                      }
                      p {
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #5032c8;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .products-by-status {
    width: 100%;
    .order-product-status {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      padding: 16px 0px 16px 24px;
      border-bottom: 1px solid #e6e6eb;
      border-top: 1px solid #e6e6eb;
      width: 100%;
      display: block;
    }
    .produce-history-info {
      .history-info-text {
        &:hover {
          .info-text-name {
            text-decoration: none;
            color: rgba(80, 50, 200, 1);
          }
        }
      }
      .active-order-price {
        width: 40%;
      }
      .product-detail-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .info-text {
          color: #8b8b8b;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin-top: 4px;
          margin-bottom: 0;
        }
        .__code {
          font-weight: 400;
          margin-top: 0;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.modal-courier-review {
  width: 600px;
  height: 510px;
  max-width: 100%;
  max-height: 100%;
  .courier-review-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .col-account-order-status {
    width: 100% !important;
    .accordion-history-header {
      .accordion-button {
        height: 224px !important;
        display: flex;
        align-items: flex-start;
        &::after {
          position: absolute;
          top: 16px;
          right: 16px;
        }
        .box-header-accordion {
          flex-direction: column-reverse;

          .header-accordion-info {
            width: 100%;
            padding-top: 16px;
          }
        }

        .box-image-products {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
    .accordion-history-body {
      // .container-map {
      //   .ymaps-2-1-79-map {
      //     height: 228px;
      //   }
      // }
      .container-map {
        margin-top: 0px !important;
        height: 350px !important;
        padding: 0px !important;
        .map {
          width: 100% !important;
          max-width: 100% !important;
          height: 350px !important;
          .ymaps-2-1-79-map {
            height: 350px !important;
            .ymaps-2-1-79-inner-panes {
              border-radius: 24px !important;
            }
          }
        }
      }
      .products-by-status {
        .history-info-price {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .photo-courier {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .delivery-courier-info {
    flex-direction: column;
  }
  .courier-info-text {
    margin: 24px 0px 0px 0px;
    width: 100%;
  }
  .box-info-delivery:last-child {
    margin-bottom: 0;
  }
  .__body-order-info {
    .box-repeat-order {
      button {
        max-width: 100% !important;
      }
      img {
        display: none;
      }
    }
  }
  .__header-order-info {
    .header-accordion-sum {
      margin-bottom: -25px;
    }
  }
  .col-account-order-status {
    .box-order-status-info {
      .status-order-details {
        flex-direction: column;
        margin-top: 16px !important;
        .status-order-detail {
          margin-left: 0px;
          margin: 4px 0px 0px 0px !important;
          border-bottom: none !important;
          border-left: 4px solid rgba(230, 230, 235, 1);
          padding: 0;
          height: 42px;
          display: flex !important;
          align-items: flex-end;
          border-radius: 4px;
          position: relative;
          width: 4px !important;
          .status-order-detail-text {
            display: block;
            position: absolute;
            margin-left: 16px;
            width: 150px;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            top: 8px;
          }
        }
        .__active {
          border-bottom: none !important;
          border-left: 4px solid #5032c8;
        }
      }
    }
    .accordion-button {
      &::after {
        top: 16px !important;
      }
      .box-header-accordion {
        justify-content: flex-end;
        position: relative;
        .header-accordion-info {
          width: 100%;
          height: 100%;
          // display: flex;
          // flex-direction: column-reverse;
          position: relative;
          .accordion-number-order {
            top: 52px;
            left: 16px;
            position: absolute;
          }
          .accordion-header-status-order-text {
            top: 16px;
            right: 16px;
            position: absolute;
          }
          .accordion-date {
            bottom: 16px;
            left: 16px;
            position: absolute;
          }
        }
        .header-accordion-sum {
          top: 116px;
          left: 16px;
          position: absolute;
        }
      }
    }
    .accordion-history-body {
      .box-order-status-info {
        padding: 16px !important;
      }
    }
    .active-order-details {
      padding: 16px 0px !important;
      .active-order-detail {
        flex-direction: column;
        .courier-info {
          margin-right: 0px !important;
          max-width: 100% !important;
          padding: 0px 16px 16px 16px !important;
          border-bottom: 1px solid rgba(230, 230, 235, 1);
          margin-bottom: 24px;
          .grade-courier {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
      .container-map {
        margin-top: 0px !important;
        height: 350px !important;
        padding: 0px 16px !important;
        .map {
          width: 100% !important;
          max-width: 100% !important;
          height: 350px !important;
          .ymaps-2-1-79-map {
            height: 350px !important;
            .ymaps-2-1-79-inner-panes {
              border-radius: 24px !important;
            }
          }
        }
      }
    }
    .products-by-status {
      .accordion-body-row {
        padding: 0px 16px;
        .product-card-order-mobile {
          border-bottom: none !important;
        }
        .container-product-history {
          border-bottom: 1px solid rgba(230, 230, 235, 1);
          .product-history-img-box {
            margin-right: 16px;
          }
          .produce-history-info {
            .history-info-text {
              padding: 0;
              .__code {
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 20px !important;
                margin-bottom: 4px !important;
              }
              .info-text-name {
                margin-bottom: 8px;
              }
              .info-text {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin: 0;
              }
            }
            .history-info-price {
              padding: 0;
              width: 100%;
              justify-content: space-between;
              .history-product-price {
                margin: 0;
              }
              .history-product-quantity {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: rgba(51, 51, 51, 1);
              }
            }
          }
        }
      }
    }
  }
}
