.cookie-banner {
  background-color: rgba(60, 60, 60, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  .cookie-banner-text {
    max-width: 90%;
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: white;
    text-align: center;
    .text-link-cookie-policy {
      font-size: 16px;
      line-height: 22px;
      color: white;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  button {
    background-color: rgba(80, 50, 200, 1) !important;
    max-width: 100px;
    width: 100% !important;
    height: 35px !important;
    border-radius: 18px !important;
    position: relative;
    right: inherit;
    top: inherit;
    margin: 0 0 0 24px;
    font-size: 16px;
    line-height: 22p;
    border: 0 !important;
    div {
      justify-content: center;
      margin: 0;
      p {
        color: rgba(255, 255, 255, 1) !important;
        margin: 0;
      }
    }
    &:hover {
      background: #613df2 !important;
      border: 0 !important;
    }
  }
}
