.container-footer {
  width: 100%;
  background: #f7f7fa;
  margin-top: 96px;
  padding-top: 24px;
}

._footer-row {
  padding-bottom: 24px;
  border-bottom: 1px solid #d9d9db;
}

.mobile-logo {
  width: 100%;
  height: 100%;
  max-width: 154px;
  max-height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.mobile-logo-img {
  width: 100%;
  height: 100%;
}

.mobile-title {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #272729;
  margin-bottom: 24px;
}

.container-app-block {
  display: flex;
}

.block-button {
  max-width: 164px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 430px) {
  .block-button {
    max-width: unset;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

    .button-app {
      margin-left: unset;
    }
  }

  .__footer-container {
    padding-right: 20px !important;
  }

  .visa,
  .mir {
    max-width: 64px !important;
  }

  .mastercard,
  .visa {
    margin-left: unset !important;
  }

  .containet-img-cards {
    justify-content: space-evenly !important;
  }

  .mobile-title {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .block-button {
    gap: 10px;
  }
  .container-footer {
    margin-top: 48px;
  }
}

.block-code {
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  margin-left: 16px;
}

.qr-code {
  width: 100%;
  height: 100%;
}

.footer-link {
  display: flex;
}

.left-link {
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.link-footer {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin-bottom: 16px;
  cursor: pointer;
  text-decoration: none;
}

.link-footer:hover {
  color: #5032c8;
}

.link-footer:last-child {
  margin-bottom: 0;
}

.right-link {
  max-width: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-contacts {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  justify-content: space-between;
}

.contacts-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #272729;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  text-align: end;
}

.contacts-number-phone {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #272729;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  text-align: end;
}

.contacts-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a2a2a6;
  margin: 0;
  padding: 0;
  text-align: end;
}

.container-images {
  display: flex;
}

.box-img-social {
  width: 56px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

.box-img-social:hover {
  background: #f7f7fa;
}

.img-social {
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
}

.logo-live {
  max-width: 203px;
  max-height: 66px;
  width: 100%;
  height: 100%;
}

.containet-img-cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mir {
  max-width: 118px;
  max-height: 32px;
  width: 100%;
  height: 100%;
}

.mastercard {
  max-width: 41px;
  max-height: 25px;
  width: 100%;
  height: 100%;
  margin-left: 45px;
}

.visa {
  max-width: 99px;
  max-height: 32px;
  width: 100%;
  height: 100%;
  margin-left: 40px;
}

.containet-support {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

._footer-img {
  align-items: center;
  margin-top: 12px !important;
  justify-content: space-between;
}

.support-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #272729;
  margin: 0;
  margin-bottom: 12px;
}

.support-button {
  max-width: 165px;
  height: 44px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
  border: 0;
}

@media screen and (max-width: 430px) {
  .support-button {
    max-width: unset;
  }
}

.support-button:hover {
  background: #f7f7fa;
}

.footer-text-politicians {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #272729;
  margin-top: 24px;
}

.col-support {
  display: flex;
  align-items: center;
  margin-left: 150px;
}

.containet-contacts-foot {
  margin-top: 16px;
}

@media (max-width: 1200px) {
  .row-footer-contacts-data {
    display: flex;
    margin-top: 48px !important;
  }
  .footer-link {
    margin-left: calc(100% - 70%);
    margin-top: 24px;
  }
  .left-link {
    min-width: 168px;
  }
  .right-link {
    margin-left: 30px;
    min-width: 180px;
  }
  .containet-contacts-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .box-img-social:nth-child(1) {
    margin-left: 0;
  }
  .footer-contacts {
    align-items: flex-start;
  }
}

@media (max-width: 995px) {
  .block-code {
    display: none;
  }

  ._footer-row {
    justify-content: flex-start;
  }
  .footer-link {
    margin-left: calc(100% - 90%);
    margin-top: 24px;
  }
  .cont {
    display: none;
  }
  .__footer-container {
    padding: 0;
  }
  .col-support {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .left-link {
    min-width: 128px;
  }
  .right-link {
    margin-left: 30px;
    min-width: 130px;
  }
  .support-title {
    text-align: center;
  }
  .containet-support {
    align-items: center;
  }
}

@media (max-width: 576px) {
  ._footer-row {
    justify-content: center;
    max-width: 100%;
  }

  .footer-link {
    margin-left: 0;
  }

  .col-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .col-support {
    width: 100% !important;
    margin-left: 0px;
    justify-content: center;
    margin-top: 36px !important;
  }

  .row-footer-contacts-data {
    justify-content: flex-start;
  }

  .containet-contacts-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-contacts {
    align-items: flex-start;
  }

  .containet-support {
    align-items: center;
    width: 100%;
  }

  .smart-mobile {
    width: 100%;
  }

  .container-app-block {
    justify-content: center;
  }

  .col-logo-live {
    display: flex;
    justify-content: center;
  }

  .col-cards {
    margin-top: 24px !important;
  }
}

.__footer-container {
  padding-right: 34px;
}

@media screen and (max-width: 430px) {
  .smart-mobile {
    margin-top: 24px;
  }
}