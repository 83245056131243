.__button-banner-delivery {
  color: rgb(225, 222, 222) !important;
  background: #d4d4d4 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
}

.spinner-margin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100%;
  margin-top: 20%;
}

.button-text-banner {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #5032c8;
}

@media screen and (max-width: 820px) {
  .banner-smart-club {
    padding: 0 24px 24px 0;
  }

  .box-button-banner {
    margin: 20px 0 0 24px;

    .button-edit-password-account {
      max-width: unset !important;
      width: stretch;
      margin-bottom: unset;
    }
  }

  .title-banner-smart-club {
    font-size: 28px;
    line-height: 32px;
    padding: 24px 0 0 24px;
    width: 100%;
  }

  .text-banner-smart-club {
    padding-left: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
