.modal-window-delivery {
  max-width: 636px !important;
  .error-auth-text {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.switch-container {
  margin-bottom: 26px;
  width: 100% !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 16px !important;
}

.title-delivery-address {
  margin: 0px 0px 12px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  padding: 0;
}

.delivery-new-address-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.switch-delivery {
  .switch-delivery-icon {
    filter: brightness(0) saturate(100%) invert(15%) sepia(6%) saturate(19%) hue-rotate(330deg) brightness(103%)
      contrast(88%);
    margin-right: 8px;
  }
}

.react-switch-selector-option-selected {
  .switch-delivery {
    .switch-delivery-icon {
      filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(94%) hue-rotate(53deg) brightness(115%)
        contrast(100%);
    }
    .switch-delivery-text {
      color: #ffffff;
    }
  }
}

.address-input-block {
  margin-top: 16px;
  .open-map {
    display: flex;
    cursor: pointer;
    &:hover {
      .open-map-text {
        text-decoration: underline;
      }
    }
    .open-map-icon {
      margin-right: 12px;
    }
    .open-map-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #5032c8;
      transition: all 0.3s;
    }
  }
}

.delivery-address-params {
  margin-top: 35px;
  .delivery-address-checkboxes {
    display: flex;
    .delivery-checkbox {
      display: flex;
      margin-right: 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #5032c8;
      }
    }
  }
  .address-params-detail {
    display: flex;
    margin-top: 24px;
    gap: 8px;
    .__required {
      .input-text {
        &::after {
          content: "*";
          color: #e92c2c;
        }
      }
    }
    .input-delivery-container {
      margin-bottom: 0;
    }
  }
}

.delivery-new-address-img {
  height: 13px;
  width: 13px;
}

.delivery-new-address {
  width: 218px;
  height: 32px !important;
  padding: 0px 12px 0px 5px !important;
  background: #f2f2f2 !important;
  border-radius: 22px !important;
  margin-bottom: 16px !important;
}

.delivery-new-address:hover {
  background: #d9d9d9 !important;
}

.box-card-delivery-address-save {
  position: relative;
}

.card-delivery-address-save {
  padding: 16px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  border: 1px solid #e6e6eb;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  margin-bottom: 8px;
  .address-warning-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #131313;
    padding: 8px 12px;
    background: rgba(253, 255, 167, 0.5);
    border-radius: 16px;
    margin: 8px 0px 0px 0px;
  }
}

.card-delivery-address-save:hover {
  border: 1px solid #5032c8;
  .delivery-address-save-title {
    color: #5032c8;
  }
}

.active-delivery-address {
  .card-delivery-address-save {
    border: 1px solid #5032c8;
    .delivery-address-save-title {
      color: #5032c8;
    }
  }
}

.box-delivery-address-save-text {
  width: 89%;
  gap: 4px;
  .__time-shop-pickup {
    max-width: 100%;
  }
}

.delivery-address-save-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.delivery-address-save-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0;
  max-width: 95%;
  .time-work-shop {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }
}

.block-image-trash-address {
  position: absolute;
  z-index: 10;
  // top: 18px;
  top: 50%;
  transform: translate(0, -62%);
  right: 16px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #f4f4f6;
  }
}

.block-image-edit-address {
  position: absolute;
  z-index: 10;
  // top: 18px;
  top: 50%;
  transform: translate(0, -62%);
  right: 48px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #f4f4f6;
  }
  .img-edit-address {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.__not-current-address {
  top: calc(44% - 26px);
}

.img-trash-address {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.delivery-address-list {
  max-height: 250px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.address-delivery-error {
  color: #e92c2c;
  font-style: normal;
  padding: 0;
  margin: 4px 0px 0px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.containter-delivery-date-current {
  align-items: flex-start !important;
}

.containter-delivery-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 24px;
  max-width: 588px;
  width: 100%;
  min-height: 68px;
  height: auto;
  background: #fafafc;
  border-radius: 16px;
  margin: 24px 0px;
  justify-content: space-between;
  .box-current-date-time-info {
    display: flex;
    .current-date-time-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .current-date {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
      }
      .current-timer-delivery {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #e92c2c;
      }
      .current-delivery-price {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8b8b8b;
      }
    }
    .box-edit-delitery-time-icon {
      margin-left: 24px;
      display: flex;
      align-items: center;
      .edit-delitery-time-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}

.box-delivery-date-text {
  display: flex;
  padding: 0px;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
}

.delivery-date-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.delivery-date-and-time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ed145b;
  margin: 0;
  cursor: pointer;
}

.delivery-date-and-time:hover {
  text-decoration: underline;
}

.warning-delivery-date {
  padding: 5px 12px 5px;
  max-width: 247px;
  width: 100%;
  height: auto;
  background: rgba(233, 44, 44, 0.1);
  border-radius: 8px;
}

.warning-delivery-date-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #e92c2c;
  margin: 0;
}

.button-save-delivery-info {
  max-width: 107px !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 22px !important;
  background: #5032c8 !important;
  width: 140px !important;
  .container-button-content {
    margin: 0 !important;
    .button-text-save-date-range {
      color: #ffffff;
      margin-left: 0 !important;
    }
  }
}

.__select-delivery-settings {
  max-width: 140px !important;
}

.button-save-delivery-info:hover {
  background: #613df2 !important;
}

.box-button-delivery {
  display: flex;
  justify-content: center;
  padding: 0px 24px 24px 0px;
  width: 100%;
}

.close-suggest {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.__setting-delivery-close {
  opacity: 0 !important;
}

.container-new-address-delivery {
  width: 100%;
  padding-top: 16px;
  ymaps[class*="search__suggest"] {
    height: auto;
    border-radius: 24px;
    padding: 8px;
    margin-top: 6px;
    ymaps[class*="suggest-item"] {
      font-family: "Gerbera", "Trebuchet MS" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #333333 !important;
      height: 40px;
      // padding: 8px 16px;
      display: flex;
      align-items: center;
      border-radius: 12px;
      width: 100%;
      ymaps[class*="search__suggest-item"] {
        display: block;
        height: auto;
      }

      &:hover {
        color: #ffffff !important;
        background: #5032c8 !important;

        ymaps[class*="search__suggest-item"] {
          color: #ffffff !important;
          background: transparent;
        }
        ymaps[class*="search__suggest-highlight"] {
          color: #ffffff !important;
        }
      }
      ymaps[class*="search__suggest-highlight"] {
        padding: 0 !important;
        color: #5032c8;
      }
    }
  }
  .yamaps {
    font-family: "Gerbera", "Trebuchet MS" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #ffffff !important;
    &:hover {
      color: #5032c8 !important;
    }
  }
  .container-map {
    margin: 0;
    h4 {
      display: none;
    }
    .map {
      height: 300px !important;
      ymaps[class*="controls-pane"] {
        ymaps[class*="controls__control"] {
          inset: 120px 28px auto auto !important;
          ymaps[class*="float-button"] {
            width: 40px !important;
            height: 40px !important;
            border: 1px solid #e6e6eb !important;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
            border-radius: 48px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 !important;
            ymaps[class*="float-button-icon"] {
              background-image: url("../../../image/location.svg") !important;
              background-repeat: no-repeat !important;
              background-size: 18px 18px !important;
              background-position: 9px 12px !important;
              width: 100%;
              height: 100%;
              border: 0 !important;
            }
          }
          ymaps[class*="zoom"] {
            height: 39px !important;
            ymaps[class*="zoom__plus"] {
              ymaps[class*="float-button-icon"] {
                background-image: url("../../../image/plus-color.svg") !important;
                background-repeat: no-repeat !important;
                background-size: 16px 16px !important;
                background-position: center !important;
                width: 100% !important;
                height: 100% !important;
                border: 0 !important;
              }
            }
            ymaps[class*="zoom__minus"] {
              ymaps[class*="float-button-icon"] {
                background-image: url("../../../image/minus-color.svg") !important;
                background-repeat: no-repeat !important;
                background-size: 16px 16px !important;
                background-position: center !important;
                width: 100%;
                height: 100%;
                border: 0 !important;
              }
            }
          }
        }
        ymaps[class*="controls__control ymaps"] {
          inset: 230px 16px auto auto !important;
        }
      }
    }
  }
}

.title-new-address-delivery {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 0;
  margin: 32px 0px 16px 0px;
}

.lable-new-delivery-address {
  padding-left: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0 0 4px 0px;
}

.input-new-address {
  position: relative;
  margin-bottom: 16px;
}

.input-new-delivery-address {
  padding: 12px 32px 12px 16px;
  gap: 12px;
  width: 100%;
  height: 48px;
  background: #fafafc;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  overflow: hidden;
  resize: none;
  transition: all 0.05s;
  &:focus-visible {
    outline-color: #5032c8;
    outline-offset: 0px;
    outline-style: solid;
    outline-width: 1px;
  }
}

.box-clear-input-address {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear-input-address {
  cursor: pointer;
  height: 12px;
  width: 12px;
}

.box-button-new-delivery-address {
  margin: 24px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.__new-delivery-data-button {
  width: 165px !important;
  p {
    margin-left: -1px !important;
    width: 100% !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #ffffff !important;
  }
}

.container-current-shipping-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
}

.img-address {
  margin-right: 4px;
}

.current-address-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  width: 430px;
  height: 48px;
}

.current-address-edit {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5032c8;
  cursor: pointer;
}

.current-address-edit:hover {
  text-decoration: underline;
}

.range-time-delivery {
  width: 88px;
  max-width: 88px;
  height: 28px;
  background: #f2f2f2;
  border-radius: 28px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  color: #333333;
  margin: 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.range-time-delivery:hover {
  background: #5032c8;
  color: #d9d9d9;
}

.active-date-time {
  background: #5032c8;
  color: #d9d9d9;
}

.box-delivery-time-range {
  width: 100%;
  max-width: 100%;
  .delivery-range-time {
    display: flex;
    flex-wrap: wrap;
    .range-time-delivery {
      margin: 0px 8px 8px 0px;
    }
  }
  .delivery-checkbox-time {
    display: flex;
    margin-top: 4px;
    padding-left: 4px;
    .box-random-delivery-time {
      .random-delivery-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-right: 9px;
      }
      .random-delivery-time {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5032c8;
      }
    }
  }
}

.navigation-slider-time {
  display: flex;
  padding-right: 5px;
}

.arrow-navigation-next {
  cursor: pointer;
  border-radius: 25%;
}

.arrow-navigation-next:hover {
  background-color: #eeeded;
}

.arrow-navigation-previous {
  cursor: pointer;
  transform: rotate(180deg);
  padding-bottom: 1px;
  border-radius: 25%;
}

.arrow-navigation-previous:hover {
  background-color: #eeeded;
}

.box-info-date-delivery {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.info-date-delivery {
  display: flex;
}

.text-delivery-date {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin: 0;
}

.text-delivery-day {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0 0 0 8px;
}

.title-list-delivery-time {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 24px;
  display: block;
}

.box-list-delivery-time {
  width: 100%;
  height: 100%;
  max-height: 300px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  overflow: scroll;
  margin-bottom: 24px;
  padding: 0 calc(20px - (100vw - 100%)) 0 0;
  overflow-x: hidden;
}

.current-shipping-address {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .modal-window-delivery {
    .container-delivery-form {
      position: relative;
      .block-image-trash-address {
        top: calc(100% - 32px);
      }
      .block-image-edit-address {
        right: 16px;
        top: calc(40% - 10px);
      }
      .card-delivery-address-save {
        .delivery-address-save-title {
          width: 91%;
        }
      }
      .box-card-delivery-address-save {
        .img-trash-address {
          top: 35px;
        }
      }
      .delivery-address-save-text {
        max-width: 93%;
      }
    }
    .current-shipping-address {
      width: 99%;
    }
  }
  .__delivery-time {
    overflow: hidden;
    .container-delivery-form {
      justify-content: flex-start !important;
      .method-delivery-body {
        height: 100%;
        .box-list-delivery-time {
          height: auto;
          // max-height: calc(52vh - 10px); // рабочая версия НО
          max-height: calc(100vh - (210px + 25vh));

          // max-height: 100vh;
          // padding-bottom: 66px;
          // position: relative;
          // z-index: 1;
          .box-random-delivery-time {
            display: flex;
            flex-direction: column;
          }
        }
        .box-button-delivery {
          width: 100%;
          padding: 0;
          position: absolute;
          bottom: 0px; // рабочая версия НО
          // left: 0px;
          // width: 100%;
          // max-width: 100%;
          // padding: 0px 16px;
          // z-index: 12312;
          button {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }
  }
  .method-delivery-footer {
    .containter-delivery-date {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .box-current-date-time-info {
        width: 100%;
        justify-content: space-between;
        .current-date-time-info {
          align-items: flex-start;
        }
      }
    }
    .box-button-delivery {
      padding: 0px;
      button {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
  .modal-header {
    padding: 20px 16px 0px 16px !important;
  }
  .container-new-address-delivery {
    .method-delivery-body {
      .delivery-address-params {
        margin-top: 34px;
        .delivery-address-checkboxes {
          flex-direction: column;
          .delivery-checkbox {
            &:last-child {
              margin-top: 16px;
            }
          }
        }
        .address-params-detail {
          flex-wrap: wrap;
          margin-top: 16px;
          .input-delivery-container {
            width: 48%;
            margin-top: 8px;
          }
        }
      }
    }
    .box-button-new-delivery-address {
      width: 100%;
      button {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 430px) {
  .modal-window-delivery {
    .container-delivery-form {
      height: 100%;
      justify-content: space-between;
    }
    .container-new-address-delivery {
      width: 100%;

      .box-clear-input-address {
        background: #8b8b8b;
        width: 17.5px;
        height: 17.5px;
        top: 16px;
        right: 15px;
        border-radius: 50px;
        .clear-input-address {
          width: 7.5px;
          height: 7.5px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(13%) saturate(324%) hue-rotate(70deg) brightness(102%)
            contrast(95%);
        }
      }
      ymaps[class*="search__suggest"] {
        height: 200px;
        overflow-y: scroll;
        border-radius: 0px;
        padding: 0px;
        margin-top: 8px;
        box-shadow: none !important;
        border: none !important;
        &::-webkit-scrollbar {
          width: 0px;
        }
        ymaps[class*="suggest-item"] {
          height: 82px;
          // padding: 8px 16px;
          display: flex;
          align-items: center;
          border-radius: 12px;
          width: 100%;
          border-bottom: 1px solid #f2f2f2;
          ymaps[class*="search__suggest-item"] {
            display: block;
            height: auto;
          }

          &:hover {
            color: #ffffff !important;
            background: transparent !important;

            ymaps[class*="search__suggest-item"] {
              color: #ffffff !important;
              background: transparent;
            }
            ymaps[class*="search__suggest-highlight"] {
              color: #ffffff !important;
            }
          }
          ymaps[class*="search__suggest-highlight"] {
            padding: 0 !important;
            color: #5032c8;
          }
        }
      }
      .yamaps {
        font-family: "Gerbera", "Trebuchet MS" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #ffffff !important;
        &:hover {
          color: #5032c8 !important;
        }
      }
    }
    .container-new-address-delivery {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .map {
      ymaps[class*="controls-pane"] {
        top: calc(40% - 200px) !important;
        ymaps[class*="controls__control"] {
          ymaps[class*="zoom__runner__transition"] {
            display: none !important;
          }
          ymaps[class*="zoom"] {
            height: 39px !important;
            ymaps[class*="zoom__plus"] {
              z-index: 15;
            }
            ymaps[class*="zoom__minus"] {
              z-index: 15;
            }
            ymaps[class*="zoom__scale"] {
              display: none !important;
            }
          }
        }
        ymaps[class*="controls__control ymaps"] {
          inset: 230px 16px auto auto !important;
        }
      }
    }
  }
  .new-address-map {
    .modal-header {
      height: 72px;
      align-items: flex-start;
      background: #ffffff;
      border-radius: 0px 0px 24px 24px;
      position: relative;
      z-index: 13;
    }
    .modal-body {
      padding: 0 !important;
      .container-new-address-delivery {
        padding: 0;
        position: relative;
        justify-content: flex-end;
        .container-map {
          margin-top: 0px !important;
          top: -17px !important;
          position: absolute !important;
          height: 100% !important;
          max-height: 100% !important;
          .map {
            height: 100% !important;
            max-height: 100% !important;
            .ymaps-2-1-79-map {
              height: 100% !important;
              max-height: 100% !important;
            }
          }
        }
        .method-delivery-body {
          background: #ffffff;
          border-radius: 24px 24px 0px 0px;
          position: relative;
          z-index: 13;
          padding: 0px 16px;
          .input-new-address {
            margin: 0;
            .input-new-delivery-address {
              overflow: hidden;
            }
          }
        }
        .box-button-new-delivery-address {
          background: #ffffff;
          position: relative;
          z-index: 13;
          margin: 0;
          padding: 24px 16px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .modal-window-delivery {
    .delivery-address-list {
      max-height: 205px;
    }
  }
}
