.product-delete-title {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #333333;
}

.modal-window-delete-product-cart {
  max-width: 416px !important;
  .modal-body {
    margin-top: 24px;

    .delete-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      .button-cancel-delete-product {
        width: 100% !important;
        max-width: 100% !important;
        height: 44px !important;
        background: #ffffff !important;
        border: 1px solid #e6e6eb !important;
        border-radius: 22px !important;
        margin-right: 16px !important;
        &:hover {
          background: #e6e6eb !important;
        }
        .button-text-cancel-delete-product {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #131313 !important;
        }
      }
      .button-delete-product {
        width: 100% !important;
        max-width: 100% !important;
        height: 44px !important;
        background: #5032c8 !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
        border-radius: 22px !important;
        &:hover {
          background: #613df2 !important;
        }
        .button-text-delete-product {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
        }
      }
      button {
        .container-button-content {
          margin: 0;
          align-items: center;
          justify-content: center;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .modal-window-delete-product-cart {
    .delete-buttons {
      .modal-body {
        flex-direction: column;
        justify-content: flex-end;
        .button-cancel-delete-product {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}
