.body-modal-age {
  margin-top: 16px;
  padding: 0px 24px;
}

.text-modal-age {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0px 0px 24px 0px;
}

.button-box-modal-age {
  display: flex;
  justify-content: space-between;
}

.box-button-false {
  width: 50%;
  max-width: 172px;
}

.box-button-true {
  width: 50%;
  max-width: 172px;
}

.__modal-age {
  box-shadow: none !important;
  border: 1px solid #e6e6eb !important;
}
