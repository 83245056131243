.row-reviews-page {
  padding: 0px 10px !important;
  margin-top: 32px !important;
}

.product-reviews-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 8px;
  gap: 24px;
  background: #fafafc;
  border-radius: 24px;
  width: 100%;
  justify-content: space-between;
}

.box-product-reviews-info {
  display: flex;
  gap: 24px;
  align-items: center;
}

.product-reviews-info-img {
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 16px;
  object-fit: contain;
}

.product-reviews-info-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.product-reviews-info-text-name {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.product-reviews-info-text-vendor {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8b8b8b;
}

.product-reviews-favorites {
  width: 44px;
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-product-reviews-buttons {
  display: flex;
  gap: 24px;
  height: 100%;
  align-items: center;
  width: 30%;
  justify-content: flex-end;
}

.button-product-reviews-info {
  max-width: 200px !important;
  width: 100%;
  height: 48px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
}

.button-product-reviews-info:hover {
  color: #fff;
}

.button-text-product-reviews-info {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #5032c8;
}

@media screen and (max-width: 430px) {
  .row-reviews-page {
    margin-top: 16px;
  }

  .product-reviews-info {
    flex-direction: column;
    align-items: flex-start;

    .box-product-reviews-info {
      flex-direction: column;
      align-items: flex-start;
    }

    .box-product-reviews-buttons {
      width: 100%;
      justify-content: space-between;

      .button-product-reviews-info {
        max-width: unset !important;
      }
    }
  }
}

//----------------------product-reviews---------------------------

.product-reviews {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 !important;
}

.product-reviews-title {
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
}

.product-reviews-title-count {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  margin: 0;
  padding: 0;
}

.button-product-add-review {
  max-width: 219px !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 22px !important;
  background: #5032c8 !important;
  width: auto !important;
}

.button-product-add-review:hover {
  background: #613df2 !important;
}

.button-text-product-add-review {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
}

.product-add-reviews {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 24px;
  background: #fafafc;
  border-radius: 24px;
  justify-content: space-between;
}

.product-add-reviews-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.product-add-reviews-one {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-all-product-reviews {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.box-progress-review {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 8px;
  max-width: 343px;
  width: 100%;
}

.progress-review {
  max-width: 195px;
  width: 100%;
  height: 8px;
  background: #f2f2f2;
  border-radius: 40px;
}

.progress-review-active {
  width: 100%;
  height: 8px;
  background: #5032c8;
  border-radius: 40px;
  transition: all 0.7s;
}

.progress-review-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
}

.progress-review-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #333333;
}

.col-rate-srarts-detail {
  gap: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 75px !important;
}

@media screen and (max-width: 1400px) {
  .filter-reviews {
    flex-direction: column;
    gap: 26px;

    .checkbox-fotos-reciews {
      margin-left: 0 !important;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 431px) {
  .col-rate-srarts-detail {
    margin-top: 164px !important;
  }

  .button-text-product-add-review {
    white-space: nowrap;
  }

  .rate-stars-box {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 430px) {
  .modal-review-detail-modal {
    .modal-header {
      width: 100vw;
    }

    .modal-body {
      padding: 0 !important;
      width: 100vw;
      height: auto;
    }
  }

  .product-add-reviews {
    flex-direction: column;
    padding: 24px !important;

    .button-product-add-review {
      max-width: unset !important;
      width: 100% !important;
    }
  }

  .col-rate-srarts-detail {
    margin-top: 0 !important;
  }

  .product-reviews {
    margin-top: 40px !important;

    .delivery-methods-history {
      max-width: 100%;
      overflow: auto;
    }
  }

  .progress-review-detail {
    align-items: flex-start;

    .box-progress-review {
      max-width: 100%;

      .progress-review {
        max-width: 234px;
      }
    }
  }

  .container-modal-content-review {
    padding: 0 !important;

    .photo-modal-preview {
      display: none;
    }
  }

  .col-slider-review-photos {
    width: 100%;

    .container-slider-review-photos {
      width: 100%;
      max-height: unset !important;
      height: 90vh !important;

      .slick-dots {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        height: min-content;
        bottom: 0 !important;

        .slick-active {
          .custon-dot {
            background: #5032c8;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            width: 10px;
            height: 10px;
          }
        }

        li {
          display: flex !important;
          align-items: center;
          justify-content: center;

          .custon-dot {
            width: 6px;
            height: 6px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 6px;
          }
        }
      }

      .slick-list {
        margin-left: -16px !important;
      }

      .slider-review-photos {
        width: 100%;
        max-height: unset !important;
        height: 90vh !important;
        margin: 0;
        max-width: unset !important;

        .slick-list {
          margin-left: 0;

          .slick-active {
            padding: 0 !important;
            margin-left: 6px;
          }

          .slick-current {
            width: 100vw !important;
          }

          .product-photo-img-review-container {
            max-width: unset;
            max-height: unset;
            width: 100vw !important;

            .product-photo-img-review {
              width: 100vw !important;
              height: 70vh;
              max-width: unset;
              max-height: unset;
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .progress-review {
    max-width: 210px !important;
  }
}

@media screen and (max-width: 360px) {
  .progress-review {
    max-width: 180px !important;
  }
}

@media screen and (max-width: 320px) {
  .progress-review {
    max-width: 140px !important;
  }
}

.modal-review-detail-modal {
  max-width: 1076px !important;
}

.container-slider-review-photos {
  width: 75%;
  max-width: 502px;
  max-height: 460px;
}

.container-slider-review-photos div .slick-prev {
  margin-left: -35px;
  top: 50% !important;
}

.container-slider-review-photos div .slick-next {
  margin-right: -35px;
  top: 50% !important;
}

.slider-review-photos {
  width: 100% !important;
  margin-left: -40px;
  margin-bottom: 19px;
  max-width: 372px !important;
  max-height: 372px !important;
}

.col-slider-review-photos {
  display: flex;
  justify-content: flex-end;
}

.product-photo-img-review-container {
  max-width: 372px;
  max-height: 372px;
}

.product-photo-img-review {
  border-radius: 25px;
  max-width: 372px;
  max-height: 372px;
  object-fit: cover;
}

.photo-modal-preview {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 372px;
}

.container-modal-content-review {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.review-name-date {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;
}

.review-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.review-date {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8b8b8b;
}

.rate-review-stars > .product-mark-stars {
  margin-bottom: 16px;
}

.rate-review-stars > .product-mark-stars > img {
  max-height: 20px;
  margin-right: 4px;
  padding-right: 0;
  max-width: 20px;
}

.rate-review-stars > .product-mark-stars > span {
  font-size: 16px;
  line-height: 20px;
}

.review-comment {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  max-width: 502px;
}

.modal-body .photo-modal-preview .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 68px;
  border-radius: 8px;
  max-height: 68px;
}

.photo-modal-preview {
  margin-left: -40px;
}

.review-photo-slider-preview-image {
  display: flex;
  width: 56px;
  height: 56px;
  align-items: center;
  // margin-bottom: 12px;
  border-radius: 8px;
  padding: 1px;
  overflow: hidden;
  cursor: pointer;
  img {
    object-fit: fill;
    height: 100%;
    z-index: 1;
    width: 100%;
    border-radius: 8px;
  }
  &.preview-active {
    border: solid 1px #5032c8;
    padding: 0;
    padding: 5px;
    width: 67px;
    height: 67px;
  }
  .slick-slider {
    .slick-list {
      border-radius: 0;
    }
  }
}

.col-rate-srarts-detail > .rate-stars-box > .product-mark-stars > img {
  max-height: 40px;
  max-width: 40px;
}

.__current-method-sort-reviews {
  border: 1px solid #5032c8 !important;
}

.sort-reviews-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 7px 16px;
  gap: 8px;
  width: auto;
  height: 38px;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: center;
  cursor: pointer;
  background: #fafafc;
  border: 1px solid #fafafc;
  p {
    font-weight: 500;
  }
}

.sort-reviews-button:hover {
  border: 1px solid #5032c8;
}

.filter-reviews {
  display: flex;
}

.checkbox-fotos-reciews {
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .input-group-text {
    cursor: pointer !important;
    .form-check-input {
      cursor: pointer !important;
    }
  }
}

.fotos-reciews-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #333333;
  margin: 0;
  white-space: nowrap;
}
