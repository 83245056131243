.btn {
  background: #ffffff;
  border: 0 !important;
  box-shadow: none;
}

.container-button-content {
  display: flex;
  margin-left: 7px;
  margin-right: 7px;
  align-items: center;
  width: 100%;
}

.content-button-img-delivery {
  width: 24px;
  height: 24px;
}

.content-button-img-authImg {
  width: 15px;
  height: 21px;
}

.content-button-img-basket {
  width: 24px;
  height: 24px;
}

.content-button-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
  margin: 0;
  margin-left: 13px;
}

._delivery {
  max-width: 200px;
  border: 1px solid #f2f2f5 !important;
}

._authImg {
  max-width: 160px;
  border: 1px solid #f2f2f5 !important;
}

._basket {
  max-width: 160px;
  border: 1px solid #f2f2f5 !important;
}

.content-button-texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
  .content-button-text-title {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
  }

  .content-button-text-info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5032c8;
    // overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;

  }
}
