.product-repeat-order-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.repeat-order-button {
  display: flex;
  margin-top: 24px;
  gap: 24px;
  .button-cancel-repeat-order {
    border-radius: 22px !important;
    border: 1px solid #f2f2f2 !important;
    background: #fff !important;
    width: 100% !important;
    max-width: 130px !important;
    min-width: 110px !important;
    height: 44px !important;
    &:hover {
      background: #f2f2f5 !important;
      border: 1px solid #f2f2f5 !important;
      box-shadow: none !important;
    }
    .button-text-cancel-repeat-order {
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      color: #5032c8 !important;
    }
  }

  .button-repeat-order {
    border-radius: 22px !important;
    background: #5032c8 !important;
    width: 100% !important;
    height: 44px !important;
    max-width: 130px !important;
    min-width: 110px !important;
    &:hover {
      background: #4425c0 !important;
      border: 1px solid #f2f2f5 !important;
      box-shadow: none !important;
    }
    .button-text-repeat-order {
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      color: #fff !important;
    }
  }
  button {
    div {
      justify-content: center;
      margin: 0 !important;
      p {
        margin-left: 0 !important;
      }
    }
  }
}
