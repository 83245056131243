.expired-link__header {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  margin: 32px 0 !important;
  padding: 0 !important;
}

.expired-link__banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #fafafc;
  border-radius: 24px;
  padding: 40px !important;

  .expired-link__banner__header {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .expired-link__banner__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 32px;
    text-align: center !important;
  }
}

.expired-link-header__cntainer {
  margin: 0 !important;
}


@media screen and (max-width: 430px) {
  .expired-link__header {
    font-weight: 500;
  }

  .expired-link__banner__container {
    padding: 0 16px;
  }

  .expired-link__banner {
    padding: 24px !important;
    height: unset;
  }

  .expired-link__banner__text {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}