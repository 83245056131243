.mySwiperCards {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

.product-card-image {
  text-align: center;
  position: relative;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  max-height: 185px;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
}

.container-cards {
  width: 314px;
  height: 360px;
  margin: 16px 0px;
  position: relative;
  padding: 0px 10px;
}

.slideSwiper3 {
  margin: 0 !important;
  justify-content: flex-start !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 148px;
}

.container-card-data {
}

// @media (max-width: 768px) {
//   .container-cards {
//     width: 300px !important;
//     margin-right: -10px;
//     .mySwiperCards {
//       width: 268px !important;
//     }
//   }
//   .data-foot {
//     padding: 0px 2px;
//   }
// }

@media (max-width: 768px) {
  .container-cards {
    width: 250px !important;
  }
}

//     .slick-list {
//       padding: 0px !important;
//       margin-right: 0px !important;
//       .slick-active {
//         overflow: visible !important;
//         padding: 0 0 0 10px !important;
//       }
//       .slick-current {
//         width: 268px !important;
//       }
//     }
//     .slick-cloned {
//       // opacity: 0 !important;
//       margin-left: -5px !important;
//     }
//     .slick-list {
//       margin-left: -36px !important;
//     }
//     .container-carousel-cards {
//       margin-right: 0 !important;
//       width: 103% !important;
//       overflow: visible !important;
//     }
//     .slick-slide {
//       opacity: 1;
//       transition: opacity 0.5s;
//     }
//     .slick-current,
//     .slick-current-clone-animate {
//       opacity: 1;
//     }
//   }
// }
