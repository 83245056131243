.box-text-feedback-info {
  width: 636px;
  margin-bottom: 96px;
}

.text-feedback-info {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.text-feedback-info:nth-child(2) {
  margin-top: 8px;
}

.text-feedback-form {
  color: #5032c8;
  cursor: pointer;
}

.__bold {
  font-weight: 700;
}

@media screen and (max-width: 430px) {
  .box-text-feedback-info {
    width: 100%;
    margin-bottom: 40px;
  }
}