.container-nav {
  display: flex;
  align-items: center;
  width: auto;
  height: 44px;
  flex-wrap: wrap;
}

.container-nav-body {
  display: flex;
  align-items: center;
  width: auto;
  // margin-top: 12px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.nav-link-header {
  margin-right: 24px;
  margin-bottom: 0;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  color: #272729;
  transition: all 0.1s ease-in-out;
}

._body-nav {
  margin-right: 0;
  margin-left: 24px;
}

._body-nav:nth-child(2) {
  color: #ed145b;
}

.nav-link-header:last-child {
  margin-right: 0;
}

.nav-link-header:hover {
  color: #5032c8;
}

.__activ-nav-link {
  color: #5032c8;
  text-decoration: underline;
}
