.exit-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 0;
  margin: 0px 0px 24px 0px;
}

.exit-form-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.exit-modal {
  max-width: 416px;
}

.exit-buttons {
  display: flex;
  justify-content: center;
}

.exit-button-box-cancel {
  max-width: 117px;
  width: 100%;
  margin-right: 8px;
}

.exit-button-box-exit {
  max-width: 117px;
  width: 100%;
  margin-left: 8px;
}

.button-cancel-exit-account {
  max-width: 224px !important;
  width: 100%;
  height: 48px !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #e6e6eb !important;
  border-radius: 22px !important;
}

.button-text-cancel-exit-account {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #272729;
}

.button-exit-account {
  max-width: 224px !important;
  width: 100%;
  height: 48px !important;
  background: #5032c8 !important;
  border-radius: 22px !important;
}

.button-text-exit-account {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.button-exit-account:hover {
  background: #4425c0 !important;
}

@media (max-width: 430px) {
  .exit-text {
    text-align: center;
  }
  .exit-buttons {
    flex-direction: column;

    div {
      max-width: 100% !important;
      width: 100% !important;
      margin-left: 0;
      .button-exit-account {
        margin-top: 16px;
      }
      button {
        max-width: 100% !important;
        width: 100% !important;
        margin-left: 0;
      }
    }
  }
  .exit-form-title {
    text-align: center;
  }
  .exit-modal {
    .modal-header {
      button {
        display: none;
      }
    }
  }
}
