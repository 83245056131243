.__new-width {
  max-width: 100%;
  margin-bottom: 24px;
}

.__rent {
  margin-top: 24px;
  padding-right: 24px;
  align-items: center;
}

.__container-form-modal {
  margin: 0;
}

.__modal-email {
  margin-bottom: 24px;
  max-width: 100%;
}

.text-agreement {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #333333;
  width: 412px;
  margin-top: 24px;
}

.success-rent-form {
  .__support {
    align-items: center;
    .button-edit-password-account {
    img {
      display: none;
    }
    p {
      margin: 0 !important;
    }
      .container-button-content {
        margin-left: 0px !important;
        justify-content: center;
      }
    }
  }
}

@media (max-width: 950px) {
  .container-formik-input {
    flex-wrap: wrap;
    width: 100%;
  }
  .__container-form-modal {
    .input-auth {
      max-width: 100%;
    }
  }
  .__rent {
    padding: 0 !important;
  }

  .box-file-input {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .container-input-file {
    max-width: 100%;
    height: auto;
  }
  .input-file-text {
    margin-bottom: 0;
  }
  .container-input-file {
    padding-bottom: 0;
  }
  .text-agreement {
    width: auto;
  }
  .container-input-file {
    margin: 24px 0px 0px 0px;
  }

}

@media screen and (max-width: 430px) {
  .container-formik-input {
    flex-direction: column;
    width: 100%;
  }

  .new-width-modal {
    margin: 0 !important;
  }

  .new-width-modal {
    border-radius: 0 !important;
  }
}
