.title-coupons {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  padding: 0;
  margin: 0px 0px 32px 0px;
}

.coupon {
  padding: 16px 16px 24px 24px;
  width: 471px;
  height: 240px;
  background: #ffffff;
  border: 1px solid #dedee0;
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.background-img-coupon {
  position: absolute;
  right: 0;
  top: 0;
}

.coupon-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #272729;
  margin: 0px 0px 16px 0px;
  padding: 0;
}

.coupon-price {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #5032c8;
  margin: 0px 0px 16px 0px;
  padding: 0;
}

.coupon-date-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a2a2a6;
  margin: 0px 0px 8px 0px;
  padding: 0;
}

.coupon-date {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a2a2a6;
  margin: 0;
  padding: 0;
}

.coupon-content {
  position: relative;
  z-index: 1;
}

.total-coupons-box {
  width: auto;
  margin: 16px 0px;
  display: flex;
  justify-content: flex-end;
}

.total-coupons-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: 0;
  padding: 0;
  background: #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
}

.img-product-coupon {
  max-width: 97px;
  max-height: 144px;
  margin-right: 15px;
}
