.container-article {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  width: 100%;
  max-width: 1280px;
}

.article {
  width: 100%;
}

.article-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.article-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.article-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.article-text-next {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  padding: 0;
  margin: 0;
}

.article-img-next {
  width: 7px;
  height: 12px;
  margin: 0px 0px 0px 9px;
}

.article-cards {
  display: flex;
  justify-content: space-between;
}
