.container-clients {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  max-width: 1320px;
}

.clients-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}

.box-carousel-clients {
  max-width: 1320px;
  width: 100%;
  border-radius: 24px;
}

.spinner-box-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
  margin-top: 20%;
}

@media screen and (max-width: 820px) and (min-width: 431px) {
  .container-clients {
    width: 95%;
  }
}

@media (max-width: 430px) {
  .clients-title {
    padding: 0px 16px;
  }
}
