.container-delivery-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 18px;
  .method-delivery-body {
    width: 100%;
  }
  .method-delivery-footer {
    width: 100%;
  }
  .__not-change-method-devivery {
    .switch-container {
      div {
        opacity: 1;
        label {
          cursor: default;
          .__disabled-delivery {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .__not-change-method-pickup {
    .switch-container {
      div {
        opacity: 1;
        label {
          cursor: default;
          .__disabled-pickup {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.delivery-form-title {
  padding: 0;
  margin: 0 !important;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.box-search-address {
  position: relative;
  width: 100%;
  max-width: 368px;
  margin: 0;
  margin-bottom: 24px;
}

.delivery-delete-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.__delete-modal {
  width: 416px !important;
  height: 172px !important;
  background: #ffffff !important;
  border-radius: 24px !important;
  margin-top: 15% !important;
  z-index: 1234;
  .modal-header {
    align-items: flex-start !important;
    .btn-close {
      margin: 6px 0px 0px 24px !important;
    }
  }
  .delete-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .close-delete-modal {
      max-width: 176px !important;
      width: 176px !important;
      height: 44px !important;
      background: #ffffff !important;
      .container-button-content {
        justify-content: center !important;
      }
      .button-text-close-delete-modal {
        font-size: 16px !important;
        line-height: 20px !important;
        color: #131313 !important;
        margin: 0 !important;
      }
    }
    .__delete-address {
      max-width: 176px !important;
      height: 44px !important;
    }
  }
}

.search-input-address {
  max-width: 648px;
  width: 100%;
  min-width: 200px;
  min-height: 48px;
  height: auto;
  background: #fafafc;
  border: 1px solid #f2f2f5;
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272729;
  padding: 12px 50px 12px 16px;
}
.input-delivery-delail {
  transition: all 0.2s;
  &:disabled {
    background-color: #f2f2f5;
    border: 1px solid #e6e6eb;
  }
}
.search-input-address,
.button-search-address {
  outline: none;
  background: transparent;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.button-search-address {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 15px 11px 0px 0px;
}

.button-search-address:before {
  content: url("../../../../image/search.svg");
  color: #f9f0da;
}

.button-delete-address {
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 15px 11px 0px 0px;
  background: white;
}

.button-delete-address:before {
  content: url("../../../../image/close.svg");
}

.box-button-delivery-from {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 24px;
}
