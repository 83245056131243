.container-delivery-card {
  width: 30%;
  margin-left: 24px;
  margin-top: 24px;
}

.img-delivery-card {
  width: 64px;
  height: 64px;
}

.title-delivery-card {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #272729;
  margin: 16px 0;
}

.subtitle-delivery-card {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #7d7d80;
  padding: 0;
  margin: 0;
}
