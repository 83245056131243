.container-modal-window-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.text-account {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
}

.text-link-auth {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.text-link-auth:hover {
  text-decoration: underline;
}

.form-select {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #333333 !important;
  background: #fafafc !important;
  border-radius: 16px !important;
  border: 1px solid #e6e6eb !important;
  cursor: pointer;
  content: url("../../../image/arrow-bottom.svg") !important;
  background-image: url("../../../image/arrow-bottom.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.form-select:focus {
  border-color: none !important;
  outline: 0;
  border: 1px solid #5032c8 !important;
  box-shadow: none !important;
}

.error-checkbox {
  border: 1px solid red !important;
}

.box-text-error-server {
  height: 37px;
}

.fullfild-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fullfild-registration-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top: 24px;
  padding: 0;
}

.button-color {
  width: 172px !important;
  height: 44px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 16px !important;
}

.button-color:hover {
  background: #613df2 !important;
}

.button-text-color {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
