.catalog-filter-modal-container {
  width: 100%;
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.clear-filter-button {
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  .content-button-text {
    color: #8b8b8b !important;
  }
}

.filter-button-container {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .clear-filter-button {
    &:hover {
      border-radius: 22px !important;
    }
  }
}
