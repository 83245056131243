body {
  margin: 0;
  font-family: "Gerbera", "Trebuchet MS" !important;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #272729;
}

#root {
  display: flex;
  justify-content: center;
  width: 100%;
}

#nprogress .bar {
  background: #5032c8 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #5032c8, 0 0 5px #5032c8 !important;
}

#nprogress .spinner-icon {
  border-top-color: #5032c8 !important;
  border-left-color: #5032c8 !important;
}
