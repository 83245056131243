.header {
  width: 100%;
  border-bottom: 1px solid #f2f2f5;
}

.header-top {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f2f2f5;
  min-height: 44px;
  max-height: 100%;
}

.box-header-top {
  justify-content: center;
  width: 100%;
  max-width: 1440px;
}

.current-city-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 48px;

  .name-city {
    display: flex;
  }
}

.city-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  cursor: pointer;
}

.button-delivery-info {
  max-width: 100% !important;
}

.content-button-img-pickup {
  filter: brightness(0) saturate(100%) invert(17%) sepia(100%) saturate(2881%) hue-rotate(249deg) brightness(84%)
    contrast(93%);
}

.city-box:hover {
  color: #5032c8;
}

.burger-header-top-container {
  width: 100%;
}

.burger-delivery-button {
  margin-top: 21px;
  height: 56px;
  margin-right: 33px;
  width: stretch;

  .container-button-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .__delivery {
      display: block;
      color: #333333;
    }
  }
}

.city-box:hover .img-geo {
  filter: invert(22%) sepia(100%) saturate(2085%) hue-rotate(244deg) brightness(79%) contrast(101%) !important;
}

.box-img-geo {
  width: 20px;
  height: 20px;
  margin-top: -10px;
}

.name-city {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-left: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .name-city {
    display: none;
  }
}

.header-bot {
  display: flex;
  // min-height: 72px;
  margin-bottom: 12px;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.search-input {
  padding: 0;
  max-width: 648px;
  width: 100%;
  min-width: 60px;
  height: 48px;
  background: #fafafc;
  border: 1px solid #f2f2f5;
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a2a2a6;
  padding-left: 16px;
}

.address-button {
  max-width: 234px;
  width: 234px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #f2f2f5;
  border-radius: 16px;
}

.botton-box-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 0px 9px;
}

.text-title {
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
  margin: 0;
}

.text-subtitle {
  margin: 0;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #a2a2a6;
  display: flex;
}

.arrow-img {
  margin-right: 11px;
  height: 15px;
  width: 9px;
}

.auth-button {
  width: 98px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #f2f2f5;
  border-radius: 16px;
}

.button-box-name {
  display: flex;
  align-items: center;
  justify-content: center;
}

.botton-img-auth {
  width: 15px;
  height: 21px;
}

.text-button-auth {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #5032c8;
  margin-left: 8.5px;
}

.col-bootstrap {
  padding: 0 !important;
}

.row-bot-header {
  justify-content: space-between;
}

.title-h1 {
  max-width: 208px;
  height: 48px;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  // font-variant: small-caps;
  color: #5032c8;
  margin: 0 0 0 24px;
  cursor: default;
}

.header-body-box {
  display: flex;
  // height: 72px;
  flex-wrap: wrap;
}

.header-body {
  min-height: 72px;
  display: flex;
  align-items: center;
  height: auto;
  justify-content: center;
}

.header-body-right {
  display: flex;
  justify-content: flex-end;
}

// .__mobile-full-open-search {
//   background: #ffffff;
//   height: 200%;
//   position: absolute;
//   z-index: 13;
//   top: 0px;
//   left: 0px;
//   border-top: 1px solid #f2f2f2;
// }

.box-search {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn:disabled {
    background: #f2f2f2 !important;
    p {
      color: #c3c3c7 !important;
    }
  }
}

.search-input,
.button-search {
  outline: none;
  background: transparent;
}

.search-input {
  height: 58px;
}

.button-search {
  border: none;
  position: absolute;
  top: 25%;
  right: calc(100% - 99%);
  cursor: pointer;
  transform: rotate(270deg);
}

.button-search-none {
  display: none;
}

.button-search:before {
  content: url("../../../image/search.svg");
  color: #f9f0da;
}

.button-search-focus {
  border: none;
  position: absolute;
  top: 20px;
  right: 123px;
  cursor: pointer;
  // margin: 14px 15px 0px 0px;
  background-color: white;
  z-index: 12;
  padding: 0;
}

.button-search-focus:before {
  color: #f9f0da;
  content: url("../../../image/close.svg");
}

// .search-input:focus {
//   border-color: #5032c8;
// }

.__search-width {
  width: 50% !important;
  max-width: 648px !important;
}

.__margin {
  margin-left: 16px;
}

._left {
  justify-content: flex-start;
}

.city-box-col {
  min-width: 152px;
}

.header-nav-col {
  max-width: 330px !important;
}

.__row-header-header {
  min-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.__catalog {
  max-width: 131px !important;
}

.__delivery {
  max-width: 200px !important;
}

.__auth {
  max-width: 126px !important;
}

.__basket {
  max-width: 120px !important;
}

.__search-width-max {
  max-width: 97%;
  width: 100%;
  margin-right: 74px;
}

.search-input-full {
  width: 100%;
  max-width: 100%;
  z-index: 12;
  position: relative;
  transition: all 0.1s;
  color: #333333;
  height: 56px;
  background: #fff;
}

.out-search-box {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding-right: 16px;
  .result-search-products {
    display: flex;
    width: 100%;
    max-height: 648px;
    .result-search-groups {
      width: 100%;
      max-width: 416px;
      overflow: auto;
      max-height: 648px;
      height: auto;

      .result-search-group {
        transition: all 0.2s;
        width: 100%;
        display: flex;
        padding: 20px 8px 20px 16px;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6eb;
        cursor: pointer;
        .search-group-name {
          font-style: normal;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          color: #333333;
        }
        .search-group-next {
          display: flex;
          .counter-product-group {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px 8px 0px 8px;
            height: 24px;
            background: #fafafc;
            border: 1px solid #e6e6eb;
            border-radius: 8px;
            margin-right: 7px;
            .counter-product-group-name {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #333333;
            }
          }
          .search-group-arrow {
            width: 21px;
            height: 24px;
          }
        }

        &:hover {
          background: rgba(80, 50, 200, 0.05);
          border-left: 4px solid #5032c8;
          padding-left: 12px;
          .search-group-name {
            font-weight: 500;
          }
          .counter-product-group {
            background: #5032c8;
            border: 1px solid #5032c8;
            .counter-product-group-name {
              color: #ffffff;
            }
          }
        }
      }
      .result-search-group__active {
        background: rgba(80, 50, 200, 0.05);
        border-left: 4px solid #5032c8;
        padding-left: 12px;
        .search-group-name {
          font-weight: 500;
        }
        .counter-product-group {
          background: #5032c8 !important;
          border: 1px solid #5032c8 !important;
          .counter-product-group-name {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.out-search-box-none {
  height: 0;
}

.nothing-found {
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px 0px 24px 26px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.img-smile {
  width: 20px;
  height: 20px;
  margin-right: 18px;
}

.text-nothing-found {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.text-nothing-found-small {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #8b8b8b;
  margin-top: 4px;
}

.search-result {
  width: 100%;
  height: auto;
  max-height: 500px;
  padding: 16px 22px 24px 16px;
  overflow: auto;
}

.box-header-delivery-button-mobile {
  width: 100%;
  max-width: 100%;
  padding: 0 16px;
  margin: 4px 0px 12px 0px;
  .header-delivery-button-mobile {
    border: 1px solid #e6e6eb !important;
    border-radius: 16px !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 58px !important;
    .container-button-content {
      justify-content: flex-start !important;
    }
  }
}

.result-head {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 12px;
}

.head-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a2a2a6;
  margin: 0px 5px 0px 0px;
}

.head-count {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 0;
}

.result-body {
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  border-top: 1px solid #e6e6eb;
}

.result-body-last {
  border-bottom: 1px solid #e6e6eb;
}

.body-product-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body-product {
  display: flex;
  align-items: center;
}

.img-product {
  width: 88px;
  height: 88px;
  margin-right: 16px;
  object-fit: contain;
}

.text-name-product {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 0;
}

.search-info-about-product {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .rate-product-search {
    display: flex;
    align-items: center;
    .text-grade-product {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      color: #333333;
      margin: 0px 0px 0px 4px;
    }
  }

  .reviews-product-search {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #acacac;
    margin: 0px 0px 0px 12px;
  }
}

.price-search-product {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-price-product {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
}

.text-old-price-product {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: line-through;
  color: #8b8b8b;
  margin: 0;
}

.text-new-price-product {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #ed145b;
  margin: 6px 0px 0px 0px;
}

.__color-text {
  color: #5032c8;
}

.search-product-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  height: auto;
}

.__class {
  align-items: center;
  justify-content: space-between;
}

.container-header-top {
  padding: 0 12px !important;
  margin: 0px 60px !important;
}

.container-header-body {
  padding: 0 12px !important;
  margin: 0px 60px !important;
}

.container-header-bot {
  width: 100%;
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  margin-right: calc(100% - 96%);
  margin-left: calc(100% - 96%);
  padding: 0px 12px;
  .cart-counter-mobile {
    position: absolute;
    top: -7px;
    right: -7px;
    border-radius: 24px;
    background-color: #5032c8;
    width: auto;
    padding: 0px 9px;
    height: 25px;
    z-index: 5;
    &:before {
      content: "";
      display: inline-block;
      height: 110%;
      vertical-align: middle;
    }
    .cart-counter-mobile-text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      display: inline-block;
      width: 100%;
      vertical-align: baseline;
      margin-top: -1px;
    }
  }
}

.box-element-header {
  margin-right: 16px;
}

.account-button {
  .header-account-button {
    position: relative;
  }
}

.delivery-button-off {
  display: none;
}

.box-element-header:last-child {
  margin-right: 0;
}

.box-element-header:nth-child(2) {
  width: 51%;
  padding: 0px 16px 0px 0px;
  margin: 0;
}

.__header-input-search {
  width: 100% !important;
  .search-input {
    width: 100%;
    max-width: 100%;
  }
}

.__width-box-input-search {
  width: 100%;
  .box-search {
    display: block;
  }
}

.row-header-bot {
  justify-content: space-between;
}

.box-search {
  .button-bootstrap {
    height: 44px !important;
  }
}

@media (max-width: 1350px) {
  .__delivery {
    line-height: 16px;
  }
}

@media (max-width: 1200px) {
  .container-bootstrap {
    padding: 0px 20px !important;
  }
  .city-box {
    padding-right: 24px;
  }
}

@media (max-width: 1024px) {
  .title-h1 {
    font-size: 20px;
  }
  .header-body {
    margin-bottom: 16px;
  }
  .__button-text-account {
    display: none;
  }
}

@media screen and (max-width: 820px) and (min-width: 430px) {
  // .button-search-focus {
  //   margin-top: 19px;
  //   margin-right: 18px;
  // }

  .header-body {
    .__class {
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .container-bootstrap {
    margin: 0px 20px !important;
  }

  .__class {
    align-items: flex-start;
    margin-top: 10px !important;
  }
  .__basket {
    display: none;
  }
  .__delivery {
    display: none;
  }
  .box-text {
    display: none;
  }
}

.burger-header {
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}

@media screen and (max-width: 430px) {
  .container-bootstrap {
    margin: 0 16px !important;
  }
  .__class {
    align-items: center;
  }
  .city-box {
    justify-content: flex-start;

    .box-img-geo {
      width: auto;
      height: auto;
      margin-top: 0;
    }

    .name-city {
      display: block;
    }
  }
}

@media (max-width: 400px) {
  .nav-link-header {
    margin-right: 20px;
  }
}

.block-search-close {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 1;
  top: 0;
  background: rgba(0, 0, 0, 0.2);

  z-index: 11;
}

.header-button-auth {
  width: auto;
  max-width: 100%;
}

.__active-button-account {
  border-color: #5032c8 !important;
}

.button-show-more {
  max-width: 140px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 16px !important;
}

.button-text-show-more {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #272729;
}

.box-nav-account-modal {
  width: 230px;
  margin-top: 4px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 12%);
  border-radius: 16px;
  padding: 16px 16px 0px 16px;
  z-index: 11;
  overflow: hidden;
}

.close-nav-account-modal {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}
.__button-text-account {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geolocation-determination {
  width: auto;
  min-width: 317px;
  background: #ffffff;
  border: 1px solid #e6e6eb;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  position: absolute;
  right: 70px;
  top: 40px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
}

.geolocation-determination-buttons {
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
}

.button-save-geolocation {
  max-width: 118px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 22px !important;
  margin-bottom: 0px !important;
  background: #5032c8 !important;
}

.button-save-geolocation:hover {
  background: #613df2 !important;
}

.button-text-save-geolocation {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.button-edit-current-city {
  max-width: 159px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 22px !important;
  margin-bottom: 0px !important;
  background: #ffffff !important;
  border: 1px solid #e6e6eb;
}

.button-text-edit-current-city {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
}

.geolocation-determination-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  display: flex;
  gap: 4px;
}

.geolocation-city {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  margin: 0;
}

.button-input-search {
  padding: 0 !important;
  gap: 12px !important;
  width: 94px !important;
  background: #5032c8 !important;
  border-radius: 12px !important;
  position: absolute !important;
  height: 44px !important;
  top: 6px;
  right: 6px;
  cursor: pointer;
  z-index: 12;
  &:hover {
    background: #613df2 !important;
  }
  .container-button-content {
    margin: 0;
    justify-content: center;
    .button-text-input-search {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 15px !important;
      line-height: 20px !important;
      color: #ffffff !important;
      margin: 0 !important;
    }
  }
}

//--------------------------Search-result-------------------------
.block-suggests {
  width: 100%;
  .box-button-search {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .button-show-more {
      width: 142px;
      height: 44px !important;
      .container-button-content {
        margin: 0;
        justify-content: flex-end !important;
        p {
          font-weight: 400 !important;
          font-size: 16px !important;
          line-height: 20px !important;
          color: #5032c8;
        }
        img {
          display: none;
        }
      }
    }
  }
  .suggests {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #e6e6eb;
    padding: 16px 16px;
    .search-suggest {
      padding: 8px 16px;
      background: #f2f2f2;
      border-radius: 16px;
      width: auto;
      margin-right: 12px;
      cursor: pointer;
      transition: all 0.1s;
      &:hover {
        background: #5032c8;
        .search-suggest-text {
          color: #ffffff;
        }
      }
      .search-suggest-text {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #333333;
      }
    }
  }
  .search-products {
    width: 100%;
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid #e6e6eb;
    overflow: auto;
    max-height: 648px;
    height: 100%;
    padding-left: 16px;
    .search-product {
      display: flex;
      padding: 16px 0px 12px 0px;
      cursor: pointer;

      &:hover {
        .search-product-info {
          .search-product-name {
            color: #5032c8;
          }
        }
      }
      .search-product-img {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        object-fit: contain;
      }
      .search-product-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        .search-product-name {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #333333;
        }
        .search-product-price {
          display: flex;
          margin-top: 12px;
          .search-product-price-new {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: -0.025em;
            color: #ed145b;
            margin-right: 12px;
          }
          .search-product-price-old {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: -0.025em;
            text-decoration-line: line-through;
            color: #c3c3c7;
          }
          .search-product-price-text {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            letter-spacing: -0.025em;
            color: #acacac;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .title-h1 {
    display: none;
  }
}

@media (max-width: 768px) {
  .__col-nav-menu-header-top {
    width: 70% !important;
  }
  .__col-current-city {
    width: 30% !important;
  }
  .__container-header-top {
    width: auto !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 431px) {
  .container-header-bot {
    padding: 0;
  }

  .box-nav-account-modal {
    right: 115px;
  }
}

@media screen and (max-width: 430px) {
  .offcanvas-start {
    width: 272px !important;
  }
  .modal-search-products {
    position: relative;
    overflow: hidden !important;
    .modal-header {
      position: absolute;
      z-index: 15;
      right: 20px;
      top: 34px;
      padding: 0 !important;
    }

    .modal-body {
      position: absolute;
      z-index: 2;
      .box-search-full {
        position: absolute;
        transition: all 0.2s;
        top: 16px;
        left: 0px;
        width: 100%;
        top: 0;
        padding: 16px;
        background: #ffffff;
        border-bottom: 1px solid #f2f2f2;
        justify-content: flex-start;
        .search-input-full {
          max-width: 342px;
          width: 100%;
          transition: all 0.2s;
        }
        .button-search-focus {
          right: 16px;
          top: 34px;
          transition: all 0.2s;
        }
        .__box-result {
          position: absolute;
          top: 90px;
          padding-right: 16px;
          height: 83vh;
          width: 100%;

          .out-search-box {
            transition: all 0.2s;
            max-width: 98%;
            margin: 0;
            top: 0px;
            box-shadow: none;
            position: relative;
            overflow: scroll;
            height: 100%;
            .nothing-found {
              padding-left: 0px;
              padding-right: 0px;
              height: 100%;
            }
            .suggests {
              padding: 16px 0px 8px 0px;
              flex-wrap: wrap;
              .search-suggest {
                margin: 0px 8px 8px 0px;
              }
            }
            .result-search-products {
              flex-wrap: wrap;
              // overflow: scroll;

              .search-products {
                overflow: scroll;
                padding-left: 0px;
                padding-bottom: 50px;
                &::-webkit-scrollbar {
                  width: 0;
                }
              }
            }
          }
        }

        .block-search-close {
          background: #ffffff;
        }
        .button-input-search {
          display: none;
        }
      }
    }
  }
  .box-nav-account-modal {
    left: 0;
    width: 100%;
    border-radius: 0 !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

    padding: 16px !important;
  }
  .text-nav-account {
    margin-bottom: 16px !important;
    .box-nav-account {
      padding: 8px 0px !important;
      margin-bottom: 0 !important;
      .cart-counter-mobile {
        top: 10px !important;
      }
    }
  }
  .text-nav-account:last-child {
    margin-bottom: 0 !important;
  }
  .header-body {
    margin-bottom: 0;

    .container-bootstrap {
      margin: 0 !important;
      padding-left: 0 !important;
    }

    .__class {
      width: 100%;
      margin: 0 !important;
    }
  }

  .search-input {
    height: 56px;
    width: 56px;

    &.search-input-full {
      width: 100%;
      box-shadow: 0px !important;
    }
  }

  ._authImg {
    height: 56px !important;
    width: 56px !important;
    width: 100%;
  }

  .box-element-header {
    ._authImg {
      z-index: 3 !important;
      position: relative !important;
    }
  }

  .container-header-bot {
    .cart-button {
      position: relative;
      button {
        height: 56px !important;
        width: 56px !important;
        border: 1px solid #f2f2f5 !important;
        &:hover {
          background: transparent !important;
        }
        &:active {
          background: #f2f2f5 !important;
        }
      }
      .btn {
        border: 1px solid #f2f2f5 !important;
      }
    }
  }

  .container-button-content {
    justify-content: center !important;
    margin: 0;
  }

  .container-header-bot {
    padding: 0;
  }

  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-img {
    margin: 0;
  }

  .catalog-button {
    padding: 16px 33px;
  }

  .box-text {
    display: flex;
    margin: 0;
    height: 21px;
    margin-left: 15px;
  }

  .delivery-button {
    display: none;
  }

  .container-catalog-button {
    max-width: none;
  }

  .button-search {
    // left: 14%;
    // top: 25%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(-1, 1) translate(51%, -36%);
    width: 50%;
    padding: 0;
  }
}

@media screen and (max-width: 390px) {
  .modal-search-products {
    .box-search-full {
      .search-input-full {
        max-width: 319px !important;
      }
    }
  }
  .button-box {
    min-width: unset;
  }

  .catalog-button {
    padding: 16px 21px;
  }
}

@media screen and (max-width: 360px) {
  .button-box {
    min-width: unset !important;
  }
  .modal-search-products {
    .box-search-full {
      .search-input-full {
        max-width: 303px !important;
      }
    }
  }
  .catalog-button {
    padding: 16px 44px;

    .box-text {
      display: none;
    }
  }

  .container-header-bot {
    .button-search {
      right: 25%;
    }
  }
}

@media screen and (max-width: 320px) {
  .catalog-button {
    padding: 16px 24px;
  }
  .modal-search-products {
    .box-search-full {
      .search-input-full {
        max-width: 248px !important;
      }
    }
  }

  .button-search {
    right: 30%;
  }
}

.burger-image-container {
  width: 54px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .burger-image {
    --bs-gutter-x: unset !important;
  }
}

.container-nav-burger {
  margin-top: 16px;

  .burger-nav {
    padding: 16px 0;

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
