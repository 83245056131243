
.__main-container {
  max-width: calc(100% - 3%) !important;
}

.content-center {
  justify-content: center;
  display: flex;
}

.__store-promotions-col {
  margin-top: 108px !important;
}

.__page-main-col {
  margin-top: 96px !important;
}

.__block-new-col {
  margin-top: 96px !important;
}

.__block-map-col {
  margin-top: 84px !important;
}

@media screen and (max-width: 820px) {
  .__block-new-col {
    margin-top: 40px !important;
  }

  .row-page-main {
    justify-content: center;

    .main-banner {
      width: 95%;
    }
  }
}

@media screen and (max-width: 430px) {
  .row-page-main {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .content-center {
    &.map__block {
      margin-left: -28px;
      margin-top: 0px !important;
    }
  }

  .__page-main-col {
    margin-top: 40px !important;
    padding: 0px 16px 0px 0px !important;
  }

  .main-banner {
    width: 100vw !important;
    padding: 0 !important;
    max-width: unset !important;

    .mySwiper2,
    .swiper-slide img {
      border-radius: unset !important;
    }
  }

  .__store-promotions-col {
    padding: 0 !important;
    margin-top: 48px !important;
  }

  .mobile__banner {
    padding-left: 16px !important;
    .row-mobile-banner {
      margin-right: 0 !important;
    }
  }
  .col-article-card-slider {
    padding: 0 !important;
    .container-carousel-cards {
      padding: 0 !important;
    }
    .__carousel-article-cards {
      margin-right: 0px;
    }
    @media screen and (max-width: 360px) {
      .slick-list {
        max-height: 320px !important;
      }
    }
  }
}
