.edit-date-info {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #8B8B8B;
  margin: 8px 0px 0px 0px;
  padding: 0 16px;
}

.lable-number-phone {
  color: #5032c8;
  margin-left: 4px;
}
