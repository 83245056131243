.container-map {
  max-width: 1296px;
  width: 100%;
  max-height: 526px;
  height: 100%;
  border-radius: 24px;
  margin-bottom: 100px;
  width: 100%;
}

.map-block-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  margin: 0;
  margin-bottom: 32px;
}

@media screen and (max-width: 430px) {
  .container-map {
    margin-top: 16px !important;

    .map {
      width: 100vw !important;
    }
  }
}

.ymaps-2-1-79-map {
  height: 100% !important;
  max-width: 1296px !important;
  width: 100% !important;
  max-height: 526px !important;
  border-radius: 24px;
}

.container-map > div {
  height: 100%;
  height: 526px !important;
  max-width: 1296px;
  width: 100% !important;
  max-height: 526px !important;
  position: relative;
}

.ymaps-2-1-79-inner-panes {
  border-radius: 24px;
}

.ymaps-2-1-79-search {
  width: 100% !important;
  max-width: 1280px !important;
}

.ymaps-2-1-79-controls__toolbar_left {
  width: 100% !important;
  max-width: 1280px !important;
}

.ymaps-2-1-79-controls__control_toolbar {
  width: 100% !important;
  max-width: 1280px !important;
}

.ymaps-2-1-79-searchbox-button-text {
  background: #ffffff;
  border: 0;
  background-image: url("../../../image/search.svg") !important;
  background-repeat: no-repeat;
  border-radius: 16px !important;
  margin-top: 10px;
  margin-right: 10px;
}

.ymaps-2-1-79-searchbox-button {
  background: #ffffff !important;
  border: 0 !important;
  height: 48px !important;
  border-radius: 16px !important;
  display: flex !important;
  align-items: center !important;
}

.ymaps-2-1-79-searchbox-input {
  border-radius: 16px 0px 0px 16px !important;
  height: 48px;
  text-align: center;
  border: 0 !important;
}

.ymaps-2-1-79-searchbox-input__input {
  height: 48px !important;
}

.ymaps-2-1-79-searchbox__normal-layout {
  position: static !important;
}

.map-placemark-container {
  width: 40px;
  height: 62px;
  background-image: url("../../../image/map-placemark.svg") !important;
}

.shop-info-card {
  position: absolute;
  max-width: 370px;
  width: 100%;
  height: 116px;
  left: 24px;
  bottom: 28px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  justify-content: space-between;
  z-index: 1;
}

.shop-info {
  padding: 12px 0px 0px 16px;
  width: 320px;
  height: 100px;
  gap: 12px;
}

.name-shop {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
  height: 28px;
  overflow: hidden;
}

.address-shop {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.time-work-shop {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #acacac;
}

.button-close-info-card {
  background: transparent;
  border: 0;
  margin: 5px 5px 0px 0px;
}

.button-close-info-card:hover {
  background: #acacac20;
  border-radius: 5px;
}

.shop-search-bar {
  position: absolute;
  max-width: 535px;
  width: 100%;
  height: 48px;
  left: 24px;
  top: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  justify-content: space-between;
  z-index: 1;

  input {
    border-radius: 16px;
    height: 48px;
    width: 100%;
    padding: 12px 16px;
    border: 0 !important;
  }
}
@media (max-width: 780px) {
  .ymaps-2-1-79-map {
    height: 300px !important;
  }
  .shop-search-bar {
    max-width: 300px;
  }
}

@media (max-width: 400px) {
  .shop-search-bar {
    max-width: 170px;
  }
}
.shop-search-results {
  position: absolute;
  width: 535px;
  left: 24px;
  top: 80px;
  background: #ffffffaa;
  backdrop-filter: blur(1px);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  z-index: 1;
  max-height: 272px;
  overflow: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 430px) {
  .shop-search-results {
    width: 90%;
    align-items: flex-start;
  }
}

.search-highlight {
  // background-color: #5032C8;
  // color: #FFF;
  color: #5032c8;
  background: none;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.shop-search-result-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 4px;
  cursor: pointer;

  width: 535px;
  height: 68px;
  border-radius: 12px;

  span.__title {
    font-weight: 700;
  }

  span.__address {
    font-weight: 400;
  }

  &:hover {
    background: #5032c8;
    color: #fff;
    .search-highlight {
      color: #fff;
    }
  }
}

@media screen and (max-width: 430px) {
  .shop-search-results {
    backdrop-filter: none;
  }

  .shop-search-result-item {
    height: unset;
    width: 100%;
  }
}

.shop-search-noresult-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 4px;
  color: #8b8b8b;
  width: 535px;
  height: 36px;
  border-radius: 12px;

  span.__title {
    font-weight: 700;
  }

  span.__address {
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .container-map {
    margin-top: 40px;
  }
  .ymaps-2-1-79-map {
    height: 430px !important;
  }
  .ymaps-2-1-79-inner-panes {
    border-radius: 0 !important;
  }
  .shop-search-bar {
    width: 100%;
    max-width: 90%;
    top: 16px;
  }
  .shop-info-card {
    bottom: 16px;
    left: 16px;
    margin-right: 16px;
    max-width: 91%;
  }
  .container-map {
    margin-bottom: 0;
  }
  .container-map > div {
    max-height: 430px !important;
  }
  .map-block-title {
    padding-left: 16px;
  }
}

@media screen and (max-width: 430px) {
  .shop-info-card {
    height: auto;

    .shop-info {
      height: auto;
      padding-bottom: 12px;
    }

    .name-shop {
      height: auto;
    }
  }

  .shop-info {
    gap: unset !important;
  }
}
