.__container-mobile-banner {
  width: 100%;
}

.row-mobile-banner {
  padding: 24px 0px !important;
  background: #f7f7fa;
  border-radius: 24px;
}

.mobile-banner-left {
  padding: 16px 0px 0px 43px;
}

.mobile-banner-left-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
}

.left-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #272729;
  margin: 0;
  padding: 0;
}

.left-subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #272729;
  width: 100%;
  max-width: 556px;
  margin: 0;
  padding: 0;
}

.box-button-app {
  display: flex;
  margin-bottom: 16px;
}

.left-code {
  padding: 16px 16px 16px 24px;
  max-width: 508px;
  height: auto;
  width: 100%;
  background: #f2f2f5;
  border-radius: 16px;
  display: flex;
  margin-bottom: 43px;
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #e6e6eb;
}

@media (max-width: 1000px) {
  .left-code {
    display: none;
  }

  .box-button-app {
    width: 100%;
    flex-wrap: wrap;
  }

  .mobile-banner-right {
    height: 100%;
    align-items: center;
  }
}

.code-text {
  margin: 0px 16px 0px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.code-qr {
  width: 100%;
  height: 100%;
  max-width: 128px;
  max-height: 128px;
}

.mobile-banner-right {
  display: flex;
  width: 100%;
  max-width: 660px;
  justify-content: center;
  min-width: 234px;
  height: 100%;
  align-items: center;
}

.img-mobile {
  max-width: 236px;
  max-height: 478px;
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  // .__container-mobile-banner {
  //   margin: 0px 10px !important;
  //   display: flex !important;
  //   align-items: center !important;
  //   justify-content: center !important;
  // }
}
@media (max-width: 610px) {
  // .mobile-banner-left {
  //   padding: 0px 0px 0px 0px;
  // }
  // .container-mobile-banner {
  //   padding: 20px;
  // }
}

@media (max-width: 1200px) {
  // .mobile-banner-left {
  //   padding-left: 0px;
  // }
}
