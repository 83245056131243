.modal-header {
  border-bottom: 0;
  padding: 24px 24px 0px 24px !important;
  border-bottom: 0 !important;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 !important;
}

.modal-content {
  max-width: 416px;
  border-radius: 24px !important;
  border: 0;
}

.btn-close:focus {
  box-shadow: none;
}

.new-width-modal {
  max-width: 641px !important;
}

@media screen and (max-width: 1280px) and (min-width: 431px) {
  .modal-content {
    max-width: fit-content;
    min-width: 416px;
  }
}

@media screen and (max-width: 430px) {
  .modal-content {
    max-width: 100vw;
  }

  .new-width-modal {
    max-width: 100vw !important;
  }
}
