.clients-slide {
  width: 100%;
  max-width: 1320px;
  height: 446px;
  display: flex !important;
  overflow: hidden;
}

.box-carousel-clients {
  .slick-slider {
    .slick-list {
      border-radius: 24px;
      overflow: hidden;
    }
  }
}

.img-clients-slide {
  width: 59%;
  height: 446px;
  object-fit: cover;
}

@media screen and (max-width: 430px) {
  .box-carousel-clients {
    .slick-dots {
      top: 296px !important;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      height: min-content;

      .slick-active {
        .custon-dot {
          background: #5032C8;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          width: 10px;
          height: 10px;
        }
      }

      li {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .custon-dot {
          width: 6px;
          height: 6px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 6px;
        }
      }
    }
  }

  .clients-slide {
    flex-direction: column;
    height: 100%;

    .img-clients-slide {
        width: 100%;
        height: 286px;
    }

    .box-slide-data {
      padding: 34px 32px 16px 16px;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .slide-data-title {
        width: 100%;
        margin: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }

      .slide-data-subtitle {
        margin: 0 0 24px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .slide-data-button {
        margin: 0;
        width: 100%;
      }
    }
  }

  .box-carousel-clients {
    padding: 0px 0px 0px 16px;
     .slick-list .slick-current {

      width: 100vw !important;
      padding-right: 16px !important;
    }
  }
}

.box-slide-data {
  background: #f2f2f5;
  width: 41%;
}

.slick-cloned {
  opacity: 0;
}

.slide-data-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #272729;
  margin: 40px 40px 24px 40px;
  padding: 0;
}

.slide-data-subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #8B8B8B;
  margin: 0px 40px 24px 40px;
  padding: 0;
}

.slide-data-button {
  width: 139px;
  height: 44px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
  border: 0;
  margin: 0px 0px 0px 40px;
}

.slide-data-button:hover {
  background: #f2f2f5;
}

.slide-data-button:disabled {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  cursor: not-allowed;
}

.slick-slide {opacity: 1; transition: opacity 0.5s; }
.slick-current,
.slick-current-clone-animate {opacity: 1;}