.button-like {
  border: 0;
  box-shadow: 0;
  background: #ffffff;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  padding: 0;
  transition: all 0.1s ease-in-out;
}

.button-like:hover {
  background: #f5f2f2b0;
}

.button-like {
  height: 44px;
  width: 44px;
  border-radius: 32px;
}
