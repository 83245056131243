.container-info-vacancy {
  width: 100%;
  margin-top: 16px;
}

.info-vacancy-head {
  display: flex;
  align-items: center;
}

.button-back-carrer {
  border: 0;
  width: 48px;
  height: 48px;
  background: #f7f7fa;
  border-radius: 200px;
}

.info-vacancy-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  margin: 0px 0px 0px 24px;
}

.box-vacancy-content-top {
  display: flex;
}

.info-vacancy {
  max-width: 636px;
  width: 100%;
  height: 120px;
  background: #f7f7fa;
  border-radius: 24px;
  display: flex;
  padding: 32px;
  justify-content: space-between;
  margin-top: 40px;
}

@media screen and (max-width: 430px) {
  .container-info-vacancy {
    margin-top: 16px;
  }

  .info-vacancy-head {
    button {
      display: none;
    }

    .info-vacancy-title {
      margin-left: unset;
    }
  }

  .info-vacancy {
    flex-direction: column;
    height: unset;
    padding: 20px;
    margin-top: 32px;

    .salary-text-vacancy {
      font-weight: 500;
      font-size: 18px;
    }

    .box-address-vacancy {
      margin-top: 24px;

      .address-title-vacancy {
        margin-bottom: 4px;
      }

      .address-text-vacancy {
        margin-bottom: unset;
        color: #333333;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  .button-request {
    width: 100% !important;

    .container-button-content {
      justify-content: center;
      margin-left: unset;

      .button-text-resume {
        margin-left: unset;
      }
    }
  }
}

.box-salary-vacancy {
}

.salary-title-vacancy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272729;
  padding: 0;
  margin: 0px 0px 8px 0px;
}

.salary-text-vacancy {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.box-address-vacancy {
  max-width: 360px;
  width: 100%;
}

.address-title-vacancy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #272729;
  padding: 0;
  margin: 0px 0px 8px 0px;
}

.__info-vacancy {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 430px) {
  .__info-vacancy {
    margin-top: 40px;
  }
}

.box-left {
  width: 100%;
}

.button-request {
  width: 209px;
  background: #5032c8 !important;
}

.button-request:hover {
  background: #3f24ac !important;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 16%) !important;
}

.button-text-resume {
  color: #ffffff;
  margin-left: 0;
  display: flex;
  justify-content: center;
  margin-left: 15%;
}

.button-vacancy {
  width: 100%;
}

.button-back-carrer:hover {
  background: #e9e9ec;
}

@media screen and (max-width: 1024px) and (min-width: 431px) {
  .box-left {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info-vacancy {
      flex-direction: column;
      height: auto;
    }
  }
}
