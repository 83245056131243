.container-subcatalog {
  width: 43%;
  border-radius: 16px;
  height: 200px;
  max-width: 400px;
  max-height: 80%;
  background: #ffffff;
  height: auto;
  padding: 14px 16px 0px 16px;
  overflow: auto;
  box-shadow: 16px 11px 29px rgb(0 0 0 / 8%);
}

.subcatalog-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 0px 0px 8px 0px;
  padding: 10px 0px;
  cursor: pointer;
  text-decoration: none;
}

.subcatalog-text:hover {
  color: #5032c8;
}

.mobile-back-to-category {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 52px;
  color: #8B8B8B;
  border-bottom: 1px solid #F2F2F2;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
    margin-left: 8px;
  }

  img {
    filter: invert(54%) sepia(0%) saturate(1%) hue-rotate(356deg) brightness(102%) contrast(100%);
  }
}

@media screen and (max-width: 430px) {
  .container-subcatalog {
    width: 100%;
    height: 80vh;
    max-width: unset;
    border-radius: unset;
  }
}