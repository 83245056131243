.input__wrapper {
  width: 90%;
  position: relative;
  margin: 15px 0;
  text-align: center;
  border-radius: 24px;
  box-shadow: 0px 5px 10px 0px rgb(80 50 200 / 10%);
}

.input__file {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  z-index: 99;
  left: 0;
  bottom: 0;
  height: 100%;
  width: auto;
  max-width: 252px;
}

.input__file::file-selector-button {
  width: 252px;
  height: 50px;
  cursor: pointer;
  border-radius: 24px;
}

.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #fff;
}

.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}

.input__file-button {
  width: 100%;
  max-width: 209px;
  height: 50px;
  background: #ffffff;
  color: #5032c8;
  font-size: 1.125rem;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-input-file {
  max-width: 282px;
  width: 100%;
  height: 248px;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  margin-top: 11px;
}

@media screen and (max-width: 430px) {
  .container-input-file {
    margin-bottom: 32px;
  }

    .__vacancy-file {
      height: unset !important;
    }

    .box-file-input,  .__vacansy {
      margin-left: unset !important;
    }
}

.input-file-loaded {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px 12px 0px;
}

.input-file-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
}

.input-file-info {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: #272729;
  margin: 0px 0px 12px 0px;
}

.img-file {
  width: 48px;
  height: 48px;
}

.box-text-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px 12px 0px;
}

.name-file {
  width: 180px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a2a2a6;
  margin: 0;
  overflow: hidden;
  text-align: center;
}

.text-loaded-file {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a2a2a6;
  margin-top: 12px;
}

.box-button-file {
  width: 100%;
  gap: 5px;
  height: 44px;
  display: flex;
  justify-content: center;

  .input__file {
    box-shadow: none;
  }
}

.__box-button-file-multi {
  justify-content: space-between;
}

.button-delete-file {
  max-width: 143px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 30px !important;
}

.__error {
  color: #ed1414;
}

.input-multy {
  width: 50%;
  margin: 0;
  background: #ffffff;
}

.__text-multy-file {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
}

.input-file-multy {
  width: 100%;
}

.__button-multy-file {
  width: 50% !important;
}

.__lable-multy-input {
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%);
  border-radius: 22px;
  height: 44px;
  cursor: pointer;
}

.input-multy:hover .__lable-multy-input {
  background: #f2f2f5;
  box-shadow: none;
}
