.accordion-history {
  background: #99c4d5;
  border-radius: 24px;
  overflow: hidden;
}

.accordion-history .accordion-button {
  height: 100px !important;
  background: #99c4d5 !important;
  border-radius: 23px !important;
  box-shadow: none !important;
}

.accordion-history-item {
  border: 1px solid #e6e6eb !important;
  border-radius: 24px !important;
}

.box-header-accordion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-accordion-date {
  display: flex;
}

.accordion-date-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0px 4px 0px 0px;
  padding: 0;
}

.accordion-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.header-accordion-sum {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 40%;
}

.accordion-button::after {
  background-image: url("../../../../image/arrow-bottom.svg") !important;
  background-position: 50% 50% !important;
  width: 48px !important;
  height: 48px !important;
  text-align: center !important;
  background-color: #ffffff !important;
  border: 1px solid #e6e6eb !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 48px !important;
  position: relative !important;
  z-index: 10 !important;
}

.box-image-products {
  margin-right: -63px;
  display: flex;
  gap: 13px;
}

.container-img-accordion {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 90px;
  height: 100px;
  transform: skewX(-30deg);
  transition: all 0.15s;
}

.header-accordion-img {
  position: absolute;
  left: 50%;
  width: 170%;
  height: 100%;
  object-fit: cover;
  transform: skewX(30deg) translateX(-50%);
}

.accordion-history-body {
  padding: 0 !important;
  border-radius: 0px 0px 24px 24px !important;
}

.button-repeat-order {
  max-width: 191px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 0 !important;
  padding: 12px 12px 12px 22px !important;
}

.button-text-repeat-order {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #5032c8;
  margin: 0px 8px 0px 0px;
  padding: 0;
}

.address-order {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.accordion-body-row {
  padding: 0rem 1.25rem;
}

.__order-product {
  border-top: 1px solid #e6e6eb;
}

.box-repeat-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  width: 100%;
  .container-button-content {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 0 !important;
  }
}

.container-product-history {
  padding: 24px 0px;
  display: flex;
}

.product-history-img-box {
  width: 100px;
  height: 100px;
  margin-right: 24px;
  position: relative;
  .product-history-out-stock {
    position: absolute;
    right: 0;
    top: 0;
    // height: auto;
    background: #ffffff;
    border: 1px solid #5032c8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0px 2px;
    // padding: 4px 8px 2px 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #5032c8;
  }
}

.produce-history-info {
  width: 87%;
  display: flex;
  .container-button-content {
    margin-left: 0 !important;
  }
}

.history-info-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 522px;
  text-decoration: none;
  padding-right: 24px;
}

.history-info-text:hover .info-text-name {
  color: #5032c8 !important;
}

.info-text-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.info-text-code {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  color: rgba(172, 172, 172, 1);
}

.button-text-add-cart {
  display: none;
}

.button-add-cart {
  width: 104px !important;
  height: 36px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container-basken-counter-history {
  display: flex;
  align-items: center;
  width: 104px;
  height: 36px;
  border: 1px solid #e6e6eb;
  border-radius: 30px;
  .box-counter-minus {
    .counter-minus {
      border-right: 0px;
      padding-right: 6px;
      margin-left: 10px;
      img {
        filter: brightness(0) saturate(100%) invert(17%) sepia(30%) saturate(7423%) hue-rotate(250deg) brightness(105%)
          contrast(96%);
        width: 16px;
        height: 16px;
      }
    }
  }
  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: rgba(80, 50, 200, 1);
    }
  }
  .box-counter-plus {
    .counter-plus {
      border-left: 0px;
      padding-left: 6px;
      margin-right: 10px;
      img {
        filter: brightness(0) saturate(100%) invert(17%) sepia(30%) saturate(7423%) hue-rotate(250deg) brightness(105%)
          contrast(96%);
        width: 16px;
        height: 16px;
      }
    }
  }
  .disabled-button-plus {
    img {
      filter: brightness(0) saturate(100%) invert(88%) sepia(6%) saturate(120%) hue-rotate(201deg) brightness(109%)
        contrast(86%);
      cursor: default;
    }
  }
}

.history-info-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}

.history-product-quantity {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(172, 172, 172, 1);
  padding: 0;
  margin: 0;
}

.history-product-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  padding: 0;
  margin: 0;
}

.button-add-cart:hover {
  background: #613df2 !important;
  box-shadow: none !important;
}

.product-history-img {
  object-fit: contain;
  width: 100px;
  height: 100px;
}

.box-calendar {
  width: 552px;
  max-width: 552px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-daterange-picker__wrapper {
  width: auto;
  height: 32px;
  max-width: 271px;
  background: #ffffff;
  border: 1px solid #5032c8;
  border-radius: 22px;
  padding: 3px 0px 0px 16px;
  max-width: 100%;
  display: none;
}

.react-daterange-picker__inputGroup > input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
}

.react-daterange-picker__inputGroup > span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
}

.react-daterange-picker__inputGroup {
  padding-left: 5px;
}

.react-daterange-picker__inputGroup__month {
  width: 58%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
}

.react-calendar__tile {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #333333 !important;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
}

// .react-calendar__tile--active {
//   background: #f6f5fc;
// }

// .react-calendar__tile--active:enabled:hover,
// .react-calendar__tile--active:enabled:focus {
//   background: #5032c8;
//   border-radius: 8px;
//   color: #e6e6eb;
// }

// .react-calendar__tile--rangeEnd {
//   background: #5032c8;
//   border-radius: 8px;
//   color: #e6e6eb;
// }

// .react-calendar__tile--rangeStart {
//   background: #5032c8;
//   border-radius: 8px;
//   color: #e6e6eb;
// }

// .react-calendar button:enabled:hover {
//   background: #5032c8;
//   border-radius: 8px;
//   color: #e6e6eb !important;
// }

// .react-calendar__tile--now {
//   background: transparent;
// }

.react-calendar__navigation__prev-button:hover {
  background: transparent !important;
  color: #5032c8 !important;
  border-radius: 8px !important;
}

.react-calendar__navigation__next-button:hover {
  background: transparent !important;
  color: #5032c8 !important;
  border-radius: 8px !important;
}

.react-calendar__navigation__prev-button {
  background: transparent !important;
  color: #333333 !important;
  border: 0 !important;
  border-radius: 8px !important;
  font-size: 35px;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation__next-button {
  background: transparent !important;
  color: #333333 !important;
  border: 0 !important;
  border-radius: 8px !important;
  font-size: 35px;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation {
  width: 100%;
  margin: 14px 0px 8px 0px;
  height: 32px;
}

.react-calendar__navigation__label:hover {
  background: transparent !important;
  color: #333333 !important;
}

.react-calendar__navigation__label {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.box-data-range-picker {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.react-daterange-picker__calendar {
  z-index: 15;
  right: 200px !important;
  top: 36px !important;
}

.history-date-range {
  width: auto;
  height: 32px;
  max-width: 271px;
  background: #ffffff;
  border: 1px solid #e6e6eb;
  border-radius: 22px;
  padding: 3px 0px 0px 16px;
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(0%) saturate(3588%) hue-rotate(334deg) brightness(115%)
      contrast(90%);
  }
}

.history-date-range:hover {
  border: 1px solid #5032c8;
  color: #5032c8;
  p {
    color: #5032c8;
  }
  img {
    filter: none;
  }
}

.__history-date-range-open {
  border: 1px solid #5032c8;
  color: #5032c8;
  p {
    color: #5032c8;
  }
  img {
    filter: none;
  }
}

.history-date-range-text {
  font-family: "Gerbera";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #333333;
  margin: 0;
}

.__start-date {
  margin-right: 5px;
}

.__end-date {
  margin-left: 5px;
}

.history-date-range-arrow {
  margin: -3px 12px 0px 8px;
  transition: transform 0.2s ease-in-out;
}

.__arrow-rotate {
  transform: rotate(180deg);
}

.box-date-range-calendar {
  position: absolute;
  width: auto;
  height: auto;
}

.button-cancel-date-range {
  max-width: 224px !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  width: 115px !important;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 22px !important;
}

.button-text-cancel-date-range {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #e92c2c;
}

.button-save-date-range {
  max-width: 224px !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 22px !important;
  background: #5032c8 !important;
  width: 107px !important;
}

.button-text-save-date-range {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.control-menu-date-range {
  position: absolute;
  top: 316px;
  right: 15px;
  z-index: 15;
  display: flex;
  justify-content: space-between;
  width: 520px;
  align-items: center;
}

.display-value-date-range {
  display: flex;
  min-width: 200px;
  flex-direction: column;
  align-items: flex-start;
}

.value-date-range {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #acacac;
}

.error-date-range {
  color: #ed1414;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}

.react-calendar button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.double-view-calendar {
  width: 552px;
  position: relative;
  z-index: 15;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
}

.react-calendar--doubleView {
  width: 520px !important;
  border: 0 !important;
}

.react-calendar__navigation__prev-button {
  justify-content: normal !important;
  color: #333333 !important;
}

.react-calendar__navigation__next-button {
  justify-content: flex-end;
  color: #333333 !important;
}

.react-calendar__navigation {
  margin: 0;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays__weekday {
  display: none;
}

.react-calendar__viewContainer {
  margin-top: -5px !important;
}

.close-date-range-calendar {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 11;
}

.settings-get-sales-history {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .__current-method .delivery-method-history-text {
    color: #333333 !important;
  }
}

.delivery-method-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: auto;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: center;
  cursor: pointer;
}

.col-account-history {
  .__current-method {
    background: #5032c8;
    border: 1px solid #5032c8 !important;
    .delivery-method-history-text {
      color: #ffffff !important;
    }
  }
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  background: #ffffff !important;
  border: 1px solid #5032c8 !important;
  border-radius: 8px !important;
  color: #333333 !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart {
  background: #5032c8 !important;
  color: white !important;
  border-radius: 8px !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds {
  background: #5032c8 !important;
  color: white !important;
  border-radius: 8px !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #5032c8 !important;
  border-radius: 8px !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
  background: #5032c8 !important;
  color: white !important;
  border-radius: 8px !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range {
  background: #f6f5fc !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background: #f6f5fc !important;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background: transparent;
}

.react-calendar__month-view__days {
  row-gap: 4px;
}

.react-calendar__tile abbr {
  font-family: "Gerbera", "Trebuchet MS" !important;
}

.react-calendar__navigation button:enabled:hover {
  border-radius: 0 !important;
  border: 0 !important;
}

.react-calendar__navigation button:enabled {
  border-radius: 0 !important;
  border: 0 !important;
  color: #5032c8 !important;
}

.react-calendar__navigation__label span {
  color: #333333 !important;
}

.react-calendar__navigation {
  margin-top: 10px;
}

.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart {
  background: #5032c8 !important;
  color: white !important;
  border-radius: 8px !important;
}
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd {
  background: #5032c8 !important;
  color: white !important;
  border-radius: 8px !important;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--to {
  width: 50%;
  padding: 0px 0px 0px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  margin-top: 3px;
}

.react-calendar__navigation__label__divider {
  display: none;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  margin-top: 3px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  padding: 0;
  width: auto;
  min-width: 0px !important;
}

.react-calendar__navigation__arrow {
  min-width: 0px !important;
  width: 15px !important;
}

.react-calendar__navigation {
  padding: 0px 10px;
}

.react-calendar__navigation button {
  min-width: 0px !important;
  width: 15px !important;
}

.react-calendar__navigation__label {
  padding-top: 3px;
  cursor: default !important;
  pointer-events: none !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent !important;
}

.button-save-date-range:hover {
  background-color: #613df2 !important;
}

.button-history-show-more {
  width: 100%;
  display: flex;
  justify-content: center;
}

.__color-text-show-more {
  color: #5032c8 !important;
}

.accordions-order {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.history-show-more {
  max-width: 140px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 16%) !important;
  border-radius: 22px !important;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 1000px) {
  .settings-get-sales-history {
    align-items: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .box-data-range-picker {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .history-date-range {
    width: 100%;
    justify-content: center;
  }

  .produce-history-info {
    flex-direction: column;
  }

  .history-info-text {
    width: 100%;
  }

  .history-info-price {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .col-account-history {
    padding: 0 !important;
    .button-cart-mobile {
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .history-product-price {
        margin-top: 0 !important;
      }
    }
    .__order-product {
      padding: 0 !important;
      margin: 0px 16px !important;
    }
    .__order {
      padding: 0px 16px;
      button {
        max-width: 100% !important;
      }
    }
    .box-repeat-order {
      flex-direction: column;
      padding: 16px 0px;
      height: auto !important;
      align-items: normal;
      .address-order {
        margin-bottom: 16px;
      }
    }
  }

  .delivery-methods-history {
    overflow-y: scroll;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }
  .delivery-methods-history::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .banner-found-nothing {
    padding: 24px;
  }
  .button-banner-found-nothing {
    max-width: 100% !important;
    border-radius: 22px !important;
  }
  .banner-found-nothing-text {
    margin-bottom: 24px;
  }
  .banner-found-nothing-title {
    text-align: center;
  }
  .close-date-range-calendar {
    background-color: #00000040;
  }
  .box-date-range-calendar {
    width: 96%;
    padding-right: 16px;
  }
  .double-view-calendar {
    width: 100%;
  }
  .react-calendar button {
    width: 100% !important;
    height: 100% !important;
    max-width: 46px !important;
    max-height: 46px !important;
  }
  .react-calendar__viewContainer {
    height: 100% !important;
    max-height: 246px !important;
    width: 92%;
  }
  .react-calendar__month-view__days {
    height: 100%;
  }
  .react-calendar__month-view {
    height: 100%;
  }
  .react-calendar__month-view > div {
    height: 100%;
  }
  .react-calendar__month-view > div > div {
    height: 100%;
  }
  .react-calendar__navigation {
    height: 32px !important;
    justify-content: space-between;
    margin-bottom: 8px !important;
    justify-content: center;
    .react-calendar__navigation__label {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 !important;
      span {
        width: 100% !important;
      }
    }
  }
  .double-view-calendar {
    height: 465px;
  }
  .control-menu-date-range {
    width: auto;
    flex-direction: column;
    top: inherit;
    bottom: 16px;
    padding: 0px 28px 0px 32px;
    left: 0px;
  }
  .display-value-date-range {
    margin-bottom: 16px;
  }
  .button-cancel-date-range {
    margin-right: 16px;
    max-width: 100% !important;
    width: 100% !important;
  }
  .button-save-date-range {
    max-width: 100% !important;
    width: 100% !important;
  }
  .control-menu-date-range > div:last-child {
    width: 100%;
    display: flex;
  }
  .react-calendar {
    border: 0 !important;
    width: 100% !important;
    padding: 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .accordion-history-header {
    .accordion-button {
      height: 224px !important;
      padding: 0 !important;
      &::after {
        top: 16px !important;
      }
    }
  }
  .box-header-accordion {
    flex-direction: column-reverse;
    height: 100%;
  }
  .box-image-products {
    width: 131%;
    justify-content: flex-end;
    margin-right: 125px;
  }
  .header-accordion-date {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    margin-top: 16px;
  }
  .header-accordion-sum {
    left: 16px;
    bottom: 80px;
  }

  .history-info-text {
    width: auto;
  }

  .info-text-code {
    margin-top: 4px;
  }
  .history-product-price {
    margin-top: 28px;
  }
  .button-history-show-more > .history-show-more {
    width: 100% !important;
    max-width: 100% !important;
  }
  .delivery-method-history {
    margin-left: 12px;
  }
  .delivery-method-history:first-child {
    margin-left: 0px;
  }
  .accordion-button::after {
    position: absolute !important;
    right: 16px;
    top: 16px;
  }
  .accordion-history {
    margin-top: 24px !important;
  }
  .accordion-history:first-child {
    margin-top: 0 !important;
  }
  .repeat-order-button {
    width: 100%;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
    .button-bootstrap {
      max-width: 50% !important;
    }
  }
}
