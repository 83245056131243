.container-auth-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

}

.button-submit {
  width: 200px;
  height: 48px;
  background: #5032c8;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 10px 0px;
}

.auth-form-title {
  padding: 0;
  margin: 0;
  font-style: normal;
  letter-spacing: -0.5px;
  color: #272729;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.container-phone-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.form {
  width: 100%;
}

.forgot-your-password {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #5032c8;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.forgot-your-password:hover {
  text-decoration: underline;
}

.checkbox {
  background: #fafafc !important;
  border: 1px solid #d9d9db !important;
  border-radius: 6px !important;
}

.input-group-text {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  margin-right: 10px !important;
}

.form-check-input {
  width: 20px !important;
  height: 20px !important;
  background-color: #fafafc !important;
  border: 1px solid #d9d9db !important;
  border-radius: 6px !important;
  margin: 0 !important;
  color: #d9d9db !important;
  cursor: pointer;

  &:checked {
    background-color: #5032c8 !important;
    border-color: #5032c8 !important;

    &:hover {
      background-color: #613DF2 !important;
    }
  }

  &:focus {
    border-color: #5032c8 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(80 50 200 / 50%) !important;
  }

  &:hover {
    background-color: #fff !important;
  }
}

.save-me-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #272729;
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

.save-me {
  display: flex;
  align-items: flex-start;
}

.container-additional-functions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 26px;
  margin-top: 10px;
}

.input-group {
  width: 20px;
  margin: 0 !important;
  margin-top: -4px !important;
  height: 20px !important;
}

.button-box-auth {
  display: flex;
  justify-content: center;
  width: 100%;
}

.text-link-data {
  color: #5032c8;
  text-decoration: underline;
}

.button-end {
  max-width: 96px;
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #d9d9db;
  border-radius: 22px;
  padding: 0;
  margin: 0px 16px 0px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
}

.text-forgot {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 24px 0px 0px 0px;
}

.margin-modal {
  margin-top: 24px;
}

.new-password-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 12px 0px 40px 0px;
}

.box-error-auth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 24px;
  margin-top: -10px;
  margin-bottom: 5px;
}

.error-auth-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ed1414;
  padding: 0;
  margin: -10px 0px 0px 0px;
}

.box-error-forgot {
  width: 100%;
  padding-right: 24px;
  height: auto;
  display: flex;
  justify-content: center;
}

@media (max-width: 430px) {
  .button-box-auth {
    align-items: center;
    flex-direction: column-reverse;

    .vacancy__button {
      &:disabled {
        width: 50% !important;
      }
    }
  }
  .button-end {
    margin-top: 16px;
    max-width: 100% !important;
  }
}
