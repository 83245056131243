.text-label-account {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #272729;
  padding: 0;
  margin: 16px 0px 16px 0px;
}

.button-box-account {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.box-button-save-edit {
  width: 100%;
  max-width: 133px;
}

.box-button-cancel-edit {
  width: 100%;
  margin-right: 24px;
  max-width: 133px;
}

.title-account-info {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #000000;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
}

.container-account-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-account {
  max-width: 100%;
  margin-top: 0 !important;
}

.button-edit-password-account {
  max-width: 224px !important;
  width: 100%;
  height: 48px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  margin-bottom: 16px !important;
}

.button-text-edit-password-account {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #5032c8;
}

.box-notification {
  margin-bottom: 28px;
  display: flex;
}

.notification-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #272729;
  padding: 0;
  margin: 0px;
}

.save-data-success {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #299f28;
  padding: 0;
  margin: 0px 0px 16px 0px;
}

.save-data-success-error {
  color: #ed1414;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding: 0;
  margin: 0px 0px 16px 0px;
}

.input-date:disabled {
  cursor: not-allowed;
  color: #27272978;
}

.electronic-check {
  isolation: isolate;
  width: 100%;
  max-width: 636px;
  border: 2px solid #b7a7f7;
  border-radius: 24px;
  position: relative;
  margin: 8px 0px 16px 0px;
}

.electronic-check-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 397px;
}

.check-info-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.check-info-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.check-info-checkbox-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.electronic-check-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.electronic-check-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.check-info-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: 16px;
}

@media screen and (max-width: 820px) and (min-width: 431px) {
  .electronic-check {
    max-width: unset;
  }
}

@media (max-width: 768px) {
  .button-box-account {
    justify-content: center;
  }
}

@media (max-width: 430px) {
  .electronic-check {
    margin: 24px 0px 40px 0px;
  }
  .text-label-account {
    margin-top: 24px;
  }
  .title-account-info {
    margin-bottom: 24px;
  }
  .container-phone-input {
    margin-top: 0 !important;
  }
  .button-box-account {
    flex-direction: column-reverse;
    align-items: stretch;
    div {
      max-width: 100% !important;
    }
    .button-edit-password-account {
      margin-bottom: 0 !important;
      max-width: 100% !important;
    }
    .box-button-save-edit {
      button {
        margin-bottom: 16px;
      }
    }
  }
}
