.product-photo-img-container {
  display: flex;
}

.modal-body {
  .product-photo-img-container {
    img {
      margin: 0 !important;
    }
  }
}

.product-review {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #333333;
  text-decoration: none;
  &:hover {
    color: #333333;
    text-decoration: underline;
  }
}

.product-photo-slider-main {
  width: 542px;
  height: 542px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-photo-slider-main img {
  max-width: 90%;
  max-height: 90%;
}

.product-favorites {
  position: absolute;
  width: 44px;
  height: 44px;
  right: 16px;
  top: 16px;
  z-index: 3;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-favorite-img {
  display: block;
  position: absolute;
  left: 25%;
  top: 25%;
}

.product-main-info-attributes {
  display: flex;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
}
.product-main-info-name {
  line-height: 32px;
  font-size: 28px;
  font-weight: 500;
}
.product-article {
  padding-right: 48px;
}

.product-star {
  padding-right: 24px;
}

.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 3.75rem;
  align-items: flex-end;
  justify-content: space-between;
}

.price-button {
  display: flex;
  background: #5032c8;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    background: #613df2;
  }
}

.button-cart-product {
  &:hover {
    background: #5032c8;
  }
  .container-basken-counter {
    width: 205px;
    height: 48px;
    color: #ffffff;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%)
        hue-rotate(40deg) brightness(105%) contrast(101%);
    }
    p {
      color: #ffffff;
    }
    .box-counter-minus {
      border-radius: 30px 0px 0px 30px;
      &:hover {
        background: #613df2;
      }
    }
    .box-counter-plus {
      border-radius: 0px 30px 30px 0px;
      &:hover {
        background: #613df2;
      }
    }
    .disabled-button-plus {
      cursor: default;
      &:hover {
        background: #5032c8;
      }
      .img {
        filter: brightness(0) saturate(100%) invert(94%) sepia(8%) saturate(82%)
          hue-rotate(201deg) brightness(96%) contrast(96%);
      }
    }
  }
}

.price-button-img {
  padding-left: 3rem;
  padding-right: 1rem;
}

.price-button-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 3rem;
  color: #ffffff;
}

.full-price-container {
  display: flex;
  flex-direction: row;
  color: #8b8b8b;
  gap: 16px;
}

.full-price {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-decoration: line-through;
}

.full-price-discount {
  color: #5032c8;
  border: 1px solid #5032c8;
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.price-with-discount {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #5032c8;
  margin-top: 1.5rem;
  &.full {
    color: #333333;
  }
}

.energy-value-header {
  margin-top: 4rem;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.block-energy-value {
  display: flex;
  gap: 10px;
}

.energy-value {
  flex: 0 1 25%;
}

.energy-value-container {
  margin-top: 24px !important;
}

.energy-value-element {
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // width: 100% !important;
}

.energy-value-element-text {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
}

.energy-value-element-value {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
}

.product-description-header,
.about-product-header,
.composition-product-header,
.product-mark-header {
  font-weight: 300;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 1.75rem;
}

.product-description-body,
.composition-product-body {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.about-product-element {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  text-overflow: clip;
  overflow: auto;
}

.about-product-element-name {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  margin-right: 8px;
}

.about-product-element-underline {
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  border-bottom: 2px solid #e6e6eb;
}

.about-product-element-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-left: 8px;
  white-space: nowrap;
}

.composition-product {
  margin-top: 3rem;
}

.product-mark {
  margin-top: 4.5rem;
}
.product-mark-stars {
  display: flex;
  align-items: stretch;
}
.product-mark-stars img {
  width: 32px;
  height: 32px;
  padding-right: 4px;
}

@media screen and (max-width: 820px) and (min-width: 431px) {
  .price-button-text {
    white-space: nowrap;
  }

  .price-container {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start !important;
    margin-top: 16px;
  }

  .product-mark-review-header {
    flex-direction: column;
  }
}

@media screen and (max-width: 430px) {
  .product-mark-stars {
    img {
      width: 40px;
      height: 40px;
    }

    span {
      font-weight: 500 !important;
      font-size: 18px !important;
      line-height: 28px !important;
      height: auto !important;
      display: flex !important;
      align-items: center;
    }
  }

  .product-mark-review-header {
    flex-direction: column;
  }

  .product-mark-review-header-mark img {
    width: 20px;
    height: 20px;
  }

  .review-header-my-rate {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 16px;
  }

  .detele-rate-buttons {
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .button-delete-rate,
    .button-close-delete-rate {
      max-width: unset !important;
      width: 100%;
    }
  }
}

.product-mark-stars span {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  margin-left: 12px;
  display: block;
  padding-top: 2px;
}

.product-mark-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.product-mark-footer-count {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}

.product-mark-footer-review-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
  text-decoration: none;
  cursor: pointer;
}

.product-mark-footer-review-link:hover {
  text-decoration: underline;
  color: #613df2;
}

.product-mark-reviews {
  margin-top: 1.75rem;
}

.product-photo-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    width: 100%;
  }
}

.product-photo-slider-preview {
  width: 72px;
  height: 480px;
}

.product-photo-slider-preview-image {
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 1px;
  overflow: hidden;

  cursor: pointer;
  img {
    object-fit: contain;
    height: 100%;
    z-index: 1;
  }
  &.preview-active {
    border: solid 1px #5032c8;
    padding: 0;
  }
  .slick-slider {
    .slick-list {
      border-radius: 0;
    }
  }
}

.product-photo-img-container {
  height: 542px;
  img {
    object-fit: contain;
  }
}

.product-photo-img {
  margin: 1em;
}

.product-review-photo {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 8px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: pointer;
  }
}

.container-sales-product {
  display: flex;
  gap: 16px;
}

.product-out {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product-out-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
}

.product-out-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8b8b8b;
}

.product-main-new-review {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #5032c8;
  cursor: pointer;
  margin-left: 16px;
}

.product-main-new-review:hover {
  color: #613df2;
}

.__no-reviews {
  margin-top: 16px;
}

.review-header-my-rate {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafc;
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 16px;
}

.my-rate-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}

.my-rate-edit-box {
  display: flex;
}

.my-rate-edit {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5032c8;
  cursor: pointer;
  margin-right: 24px;
}

.my-rate-edit:hover {
  color: #613df2;
}

.my-rate-delete {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #e92c2c;
  cursor: pointer;
}

.my-rate-delete:hover {
  color: #f52828eb;
}

.modal-delete-rate-modal {
  max-width: 418px !important;
  width: 100%;
}

.button-delete-rate {
  max-width: 176px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #e92c2c !important;
  border-radius: 22px !important;
}
.button-text-delete-rate {
  margin-left: -7px !important;
  width: 100% !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #e92c2c;
}

.button-delete-rate:hover {
  background: #e5e4e4 !important;
}

.button-close-delete-rate {
  max-width: 176px !important;
  width: 100%;
  height: 44px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
}

.button-text-close-delete-rate {
  margin-left: -7px !important;
  width: 100% !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.button-close-delete-rate:hover {
  background: #613df2 !important;
}

.detele-rate-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.detele-rate-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.body-modal-delete-rate {
  margin-top: 18px;
}

.__my-review {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.__my-review > div {
  padding: 0px 16px;
}

.item-not-purchased {
  justify-content: center !important;
  span {
    max-width: 380px !important;
  }
}

.container-no-photo-product {
  svg {
    width: 100%;
  }
}

@media screen and (max-width: 1280px) and (min-width: 431px) {
  .modal-body {
    .product-photo-img {
      margin: 0;
    }
  }
}

@media (max-width: 430px) {
  .product-photo > .product-photo-img-container {
    height: 466px;
  }
  .product-photo-img-container {
    justify-content: center;
    height: 430px;
    .slick-dots {
      bottom: -35px;
    }
    .slick-dots li button:before {
      font-size: 9px !important;
    }
    .product-favorites {
      right: 0;
    }
  }
  .product-photo-slider-main {
    height: 466px;
    width: 100%;
  }
  .product-photo-img {
    height: 100%;
    width: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .product-article {
    padding-right: 32px;
  }
  .price-container {
    margin-top: 32px;
    flex-direction: column;
    align-items: flex-start;
  }
  .price-with-discount {
    margin-top: 6px;
  }
  .price-button {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }
  .product-star {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #333333;
  }
  .full-price-container {
    gap: 0;
  }
  .full-price-discount {
    padding: 0px 5px;
    height: 24px;
    margin-left: 16px;
  }
  .container-sales-product {
    gap: 0;
  }
  .energy-value-header {
    margin-top: 40px;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }
  .energy-value-container {
    margin-top: 24px !important;
    .energy-value-element {
      margin: 0 0 8px 0 !important;
    }
    .energy-value-element:nth-child(odd) {
      margin-right: 8px !important;
    }
  }
  .block-energy-value {
    flex-wrap: wrap;
    gap: 0;
  }
  .energy-value {
    flex: 0 1 50%;
  }
  .energy-value-element {
    // width: 50% !important;
    // flex-basis: 50% !important;
    // flex: 0 1 50% !important;

    flex: 0 1 auto !important;
    max-width: 188px !important;
  }
  .col-product-description {
    margin-top: 40px !important;
  }
  .product-description-header {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .about-product-header {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 24px;
  }
  .col-product-additional-info {
    margin-top: 40px !important;
  }
  .composition-product-header {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  .product-mark-header {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }
  .product-photo-container {
    .slick-list {
      width: 100% !important;
      max-width: 430px !important;
    }
    .slick-slider {
      width: 100% !important;
      max-width: 430px !important;
    }
  }
  .col-product-photo {
    margin-bottom: 30px !important;
  }
  .product-mark {
    margin-top: 40px !important;
  }
  .product-mark-header {
    margin-bottom: 24px;
  }
  .fullscreen-modal-photo {
    .modal-body {
      margin-top: 60px !important;
      display: flex;
      align-items: center;
    }
    .slick-dots {
      bottom: -70px;
      li button:before {
        font-size: 12px;
        color: #5032c8;
      }
    }
  }
  .product-mark-footer {
    flex-wrap: wrap;
  }
  .button-navigate-to-reviews {
    width: 100%;
    height: 48px !important;
    background: #ffffff !important;
    border-radius: 22px !important;
    margin-bottom: 16px !important;
    background: #5032c8 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
    margin: 24px 0px 0px 0px !important;
  }
  .button-text-navigate-to-reviews {
    margin-left: -7px !important;
    width: 100% !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }
}
