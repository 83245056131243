.mobile-button-app-black {
  width: 100%;
  max-width: 119px;
  height: 36px;
  background: #000000;
  box-shadow: 0px 1px 4px #a6a6a6;
  border-radius: 7px;
  padding: 5px;
  margin-left: 8px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: none;
}

.img-app-store {
  padding: 5px 0px;
  height: 36px;
}

.donload-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
}

.store-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
}

.button-black-app-content {
  display: flex;
  align-items: center;
}

.content-button-black-text {
  margin-left: 6px;
}

.mobile-button-app-black:hover {
  background: #636060;
}
