.box-logo-error {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
}

.box-error-content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title-error {
  color: #5032c8;
  padding: 0;
  margin: 0;
  font-size: 100px;
}

.box-error-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-text {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 8px 0px 0px 0px;
  padding: 0;
}

.box-error-link {
  margin-top: 40px;
}

.link-error {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  text-decoration: none;
  margin-right: 24px;
}

.link-error-last {
  margin: 0;
}

.link-error:hover {
  color: #5032c8;
}

.footer-error-page {
  display: flex;
  align-items: center;
  padding: 12px 72px;
  gap: 24px;
}

.box-button-mobile-app {
  width: 491px;
  display: flex;
}

.box-social-error {
  display: flex;
  width: 144px;
  justify-content: space-between;
  margin-left: 228px;

  .box-img-social {
    width: 44px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 88px;
  }
}

.row-error-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.text-footer-error {
  width: 378px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error-container {
  height: 100vh;
}

@media screen and (max-width: 430px) {
  .footer-error-page {
    flex-direction: column;
    justify-content: center;
  }

  .row-error-page {
    height: auto;
    padding-bottom: 48px;
  }

  .header-error-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px !important;
    border-bottom: 1px solid #F2F2F2;

    .burger-image {
      padding-right: 16px;
    }

    .box-logo-error {
      justify-content: flex-start;
    }
  }

  .row-error-page {
    flex-wrap: nowrap !important;
  }

  .text-footer-error {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box-social-error {
    margin-left: unset;
  }

  .box-button-mobile-app {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .box-error-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 48px;

    a {
      text-align: center;
      margin-right: unset;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 360px) {
  .error-text {
    width: 80%;
  }
}