.data-head {
  width: 306px;
  height: 200px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
}

.container-head-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
  padding-left: 10px;
}

.data-sale {
  height: auto;
  background: #ffffff;
  border: 1px solid #5032c8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 4px 9px;
  padding: 4px 8px 2px 8px;
}

.sale-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5032c8;
  margin: 0;
  padding: 0;
}

.product-card-reviews-info {
  border-radius: 0px 16px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #ffffff;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .data-grade {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .data-rewiews {
    display: flex;
    justify-content: center;
    align-items: center;
    .rewiews-count-text {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #c3c3c7;
      margin-right: 2px;
    }
    .img-rewiews {
      width: 16px;
      height: 16px;
    }
  }
}

.grade-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffc112;
  padding: 0;
  margin: 0;
  margin-left: -2px;
}

.img-star {
  height: 16px !important;
  width: 16px !important;
  margin-left: 3px;
  margin-top: -3px;
}

.data-foot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-top: -142px;
  position: relative;
  z-index: 12;
  // position: absolute;
  padding: 0px 8px;
  width: 100%;
  margin-top: 10px;
}

.product-card-info {
  text-decoration: none;
}

.title-data-card {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #272729;
  padding: 0;
  margin: 0;
  height: 40px;
  overflow: hidden;
  text-decoration: none;
}

.foot-info-price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.price-old {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #a2a2a6;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.price-new {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #5032c8;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

.price-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #a2a2a6;
  padding: 0;
  margin: 0;
}

.MuiCheckbox-colorPrimary {
  color: #dedee0 !important;
}

.Mui-checked {
  color: #5032c8 !important;
}

.swiper-pagination {
  background: #ffffff !important;
  width: auto !important;
  margin: 40% 0px 0px 42% !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  border-radius: 16px !important;
  height: 23px !important;
  bottom: 150px !important;
}

.container-cart-button {
  max-width: 302px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e6e6eb;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  margin-top: 12px;
  margin-left: 2px;
  transition: all 200ms;
  &:active {
    border: none !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12) inset !important;
  }
  .container-basken-counter {
    .disabled-card-button-card {
      cursor: default;
      &:hover {
        background-color: #ffffff;
      }
      img {
        filter: brightness(0) saturate(100%) invert(94%) sepia(8%) saturate(82%)
          hue-rotate(201deg) brightness(96%) contrast(96%);
      }
    }
  }
}

.container-cart-button-count {
  &:hover {
    box-shadow: none !important;
    border: 1px solid #e6e6eb !important;
  }
}

.box-cart-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.text-cart-button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  // display: none;
  // transition: 100ms;
  max-width: 0px;
  white-space: nowrap;
  text-overflow: hidden;
  transition: all 200ms;
  transition-timing-function: ease-in-out;
  transform: scaleX(0.00001);
}

.container-cart-button:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border: 0;
  border-color: #e6e6eb;
  .text-cart-button {
    max-width: 200px;
    transform: scaleY(1);
  }
}

.container-cart-button:hover .text-cart-button {
  display: block;
}

.container-basken-counter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.text-counter {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #272729;
  margin: 0;
  padding: 0;
}

.counter-minus {
  margin-left: 18px;
  height: 24px;
  border-right: 1px solid #eeeef0;
  padding-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter-plus {
  margin-right: 18px;
  height: 24px;
  border-left: 1px solid #eeeef0;
  padding-left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-counter-minus {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px 0px 0px 20px;
}

.box-counter-minus:hover {
  background-color: #eeeef0;
}

.box-counter-plus {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0px 20px 20px 0px;
}

.box-counter-plus:hover {
  background-color: #eeeef0;
}

.__price-old-none {
  opacity: 0;
}

.__color-price {
  color: #272729;
}

.link-product {
  text-decoration: none !important;
}

@media screen and (max-width: 430px) {
  .title-data-card {
    text-align: left;
  }

  .container-cart-button {
    width: 100% !important;
    max-width: unset;
  }
}
