.container-text-vacancy {
  width: 100%;
}

.box-text-vacancy {
  margin-bottom: 32px;
}

.title-content-vacancy {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #272729;
  margin: 0px 0px 16px 0px;
}

.text-content-vacancy {
  font-family: "Gerbera", "Trebuchet MS";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin: 0px 0px 4px 0px;
}

@media screen and (max-width: 1024px) {
  .container-text-vacancy {
    margin-top: 32px;
  }
}