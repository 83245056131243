.container-nav-account {
  flex-direction: column;
  width: 306px;
  max-width: 100%;
  .cart-counter-mobile {
    position: absolute;
    top: -7px;
    right: -7px;
    border-radius: 24px;
    background-color: #5032c8;
    width: auto;
    padding: 0px 9px;
    height: 25px;
    z-index: 5;
    top: 1px !important;
    right: 0 !important;
    &:before {
      content: "";
      display: inline-block;
      height: 110%;
      vertical-align: middle;
    }
    .cart-counter-mobile-text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      display: inline-block;
      width: 100%;
      vertical-align: baseline;
      margin-top: -1px;
      margin-left: 0 !important;
    }
  }
  .cart-counter-mobile {

    .cart-counter-mobile-text {
    }
  }
}

.box-nav-account {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;

  span {
    margin-left: 18px;
    margin-top: 3px;
  }

}

.text-nav-account {
  font-family: "Gerbera", "Trebuchet MS" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #272729 !important;
  // margin: 0px 0px 0px 23px !important;
  padding: 0 !important;
  text-decoration: none !important;
}

.text-nav-account:hover {
  color: #5032c8 !important;
}

.__nav-item-active {
  color: #5032c8 !important;
}

.text-nav-account:hover .img-nav-account {
  filter: invert(22%) sepia(100%) saturate(2085%) hue-rotate(244deg) brightness(79%) contrast(101%) !important;
}

.__nav-img-active {
  filter: invert(22%) sepia(100%) saturate(2085%) hue-rotate(244deg) brightness(79%) contrast(101%);
}

@media screen and (max-width: 1024px) {
  .container-nav-account {
    width: 100%;
  }
}
