.input-auth {
  padding: 0px 16px;
  width: 100%;
  background: #fafafc;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
  max-width: 368px;
  height: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}

.input-auth:focus-visible {
  outline-color: #5032c8;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 1px;
}

.input-auth:focus-visible + .img-security-password {
  opacity: 1;
}

.text-error {
  margin-top: 4px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ed1414;
  margin-bottom: -15px;
}

.container-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
}

@media screen and (max-width: 1280px) and (min-width: 431px) {
  .container-input {
    width: 100%;
    .container-input-password {
      width: 100%;
      max-width: 100%;
    }
    .select {
      width: 100% !important;
    }
    .select > div {
      width: 100% !important;
    }
  }
}

.input-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #a2a2a6;
  padding: 0px 0px 0px 16px;
  margin: 0px 0px 4px 0px;
}

.container-input-password {
  position: relative;
  width: 100%;
  max-width: 368px;
}

._password {
  position: relative;
}

.img-security-password {
  position: absolute;
  right: 16px;
  top: 25%;
  cursor: pointer;
  opacity: 0.5;
}

.box-text-error {
  display: flex;
  justify-content: center;
  padding-right: 24px;
  width: 100%;
}
