.container-article {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  width: 100%;
  max-width: 1280px;
}

.article {
  width: 100%;
}

.article-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.article-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  padding: 0;
  margin: 0;
}

.article-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.article-text-next {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5032c8;
  padding: 0;
  margin: 0;
}

.article-img-next {
  width: 7px;
  height: 12px;
  margin: 0px 0px 0px 9px;
}

.article-cards {
  display: flex;
  justify-content: normal !important;
}

.__sort {
  justify-content: flex-start !important;
}

.container-button-show-content {
  flex-direction: row-reverse !important;
  justify-content: flex-end;
  margin: 0;
}

.button-show-content {
  // max-width: 168px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  margin-bottom: 0 !important;
  padding: 12px 12px 12px 22px !important;
}

.button-text-show-content {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #5032c8;
  margin: 0px 4px 0px 0px;
  padding: 0;
}

.button-show-content:active {
  background-color: #ffffff !important;
}

.button-show-all {
  width: 100%;
  height: 48px !important;
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  margin-bottom: 0px !important;
}

.button-text-show-all {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.box-button-show-all {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.button-show-all:hover {
  background: #613df2 !important;
}

@media (max-width: 430px) {
  .article-title {
    padding: 0px 16px !important;
  }
  .box-button-show-all {
    padding: 0 16px;
  }
}
