.container-city-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.list-city {
  columns: 3;
  padding: 0;
  column-width: 190px;
  column-gap: 8px;
}

@media screen and (max-width: 430px) {
  .list-city {
    columns: 1;
    column-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    // height: 100%;
    // max-height: 400px;
    height: 73vh;
  }
}

.name-city-list {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  list-style-type: none;
  cursor: pointer;
  padding: 0;
  margin-bottom: 8px;
}

.name-city-list:hover {
  color: #5032c8;
}

.city-modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  margin: 0;
  padding: 0;
}

.modal-city-size {
  width: 636px;
  min-width: 636px;
  overflow: hidden;
}

@media screen and (max-width: 430px) {
  .modal-city-size {
    width: 100%;
    min-width: unset;
    height: 98vh !important;
  }

  .col-modal-city-content {
    overflow: hidden;
    min-height: 430px;
  }
}

.search-result-city {
  position: absolute;
  max-height: 136px;
  height: auto;
  width: 588px;
  background: antiquewhite;
  overflow: scroll;
  margin-top: 8px;
  background: #ffffff;
  border: 1px solid #e6e6eb;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 8px;
  z-index: 10;
}

@media screen and (max-width: 430px) {
  .search-result-city {
    width: 90%;

    .elem-result-search {
      width: 100%;
    }
  }
}

@media screen and (max-width: 375px) {
  .search-result-city {
    width: 80%;
  }
}

.search-result-city::-webkit-scrollbar {
  display: none;
}

.row-modal-city {
  flex-direction: column;
}

.elem-result-search {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  padding: 8px 16px;
  gap: 4px;
  width: 572px;
  height: 40px;
  margin: 0;
  cursor: pointer;
}

.__active-search-result {
  background: #5032c8;
  border-radius: 12px;
  color: #ffffff;
}

.col-modal-city {
  padding: 0 !important;
}

.col-modal-city-content {
  padding: 0 !important;
}

.box-search-city {
  position: relative;
  width: 100%;
  margin: 24px 0px 0px 0px;
  z-index: 10;
}

.search-input-city {
  max-width: 648px;
  width: 100%;
  min-width: 200px;
  min-height: 48px;
  height: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272729;
  padding: 12px 50px 12px 16px;
  background: #fafafc;
  border: 1px solid #e6e6eb;
  border-radius: 16px;
}

.box-box-box {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.search-box {
  margin-bottom: 24px;
}

.close-input-search {
  position: absolute;
  left: 0;
  top: -53px;
  height: 119%;
  width: 100%;
  z-index: 1;
}
