.container-info-delivery {
  margin: 120px 0px 112px 0px;
}

.info-delivery-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #272729;
  margin: 0;
}

.info-delivery-box {
  width: 100%;
  max-width: 425px;
  padding: 18px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.box-number {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #272729;
  margin: 0px 16px 0px 0px;
}

.box-text-info {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.45;
  color: #272729;
  margin: 0;
}

.__color-info {
  color: #ffffff;
  background-color: #5032c8;
}
