.container-delivery-terms {
  width: 100%;
  max-width: 660px;
  margin-top: 96px;
}

.dilivery-terms-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #272729;
  margin: 0;
  padding: 0;
}

.box-content-delivery-terms {
  margin-top: 32px;
}

.content-delivery-terms-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #272729;
  margin: 0px 0px 16px 0px;
}

.content-delivery-terms-text {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #272729;
  margin: 0;
}

@media screen and (max-width: 430px) {
  .container-delivery-terms {
    margin-top: 40px;
  }
}
