.__form-vacancy {
  max-width: 636px;
}

.__vacancy-phone {
  max-width: 636px;
  width: 636px;
  margin-top: 0 !important;
}

@media screen and (max-width: 820px) {
  .__vacancy-phone {
    width: 100%;
    max-width: unset;
  }

  .__vacansy {
    height: unset !important;
  }

  .__form-vacancy {
    max-width: unset;
  }
}

.__vacansy {
  margin-top: 10px;
  width: 100%;
  margin-left: 24px;
  height: 317px;
}

.__vacancy-file {
  max-width: 100%;
  margin-top: 0;
  height: 100%;
  justify-content: space-between;
}

.title-vacancy-form {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #272729;
  margin-bottom: 16px;
}

.text-vacancy-form {
  font-family: "Gerbera", "Trebuchet MS";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272729;
  margin-bottom: 32px;
}

.text-agreement {
  max-width: 416px;
  font-family: "Gerbera", "Trebuchet MS";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #272729;
}

.container-text-agreement {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.modal-resume {
  max-width: 712px !important;
  padding-right: 24px;
}

@media screen and (max-width: 820px) {
  .modal-resume {
    padding-right: unset;
  }
}

.box-vacansy-personal-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 306px;
}

.container-formik-input-vacancy {
  display: flex;
  width: 636px;
  align-items: center;

}

.text-agreement-vacancy {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #333333;
  width: 412px;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .text-agreement-vacancy {
    margin-top: 24px;
    text-align: center;
  }

  .container-formik-input-vacancy {
    margin-bottom: 32px;
  }
}

.successfully-sent {
  width: 100%;
  display: flex;
  justify-content: center;
  color: green;
}
