.container-carus {
  padding-top: 16px;
  .photo-carousel-full {
    .slick-list {
      max-height: 526px !important;
      height: 100% !important;
      img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 526px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 25px;
        min-height: 320px;
      }
    }
  }
  .photo-carousel-preview {
    padding-top: 24px;
    .slick-slide {
    }
    .box-slider-photo-prev {
      cursor: pointer;
      max-width: 210px;
      max-height: 80px;
      position: relative;
      height: 100%;
      padding: 0px;
      img {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        max-width: 197px;
      }
      .slider-text {
        font-family: "Gerbera" , "Trebuchet MS";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #272729;
        margin: 0;
        max-height: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
    .slick-prev {
      top: 48% !important;
    }
    .slick-next {
      top: 48% !important;
    }
  }
  .banner-hide {
    display: none !important;
  }
  .__prev-slide {
    left: -24px;
  }

  .__prev-slide-img {
    transform: scale(-1, 1);
  }
  .button-navigation-slider-banner {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    right: -24px;
    bottom: 5.4%;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;
    cursor: pointer;
  }

  .navigation-slider-banner-arrow-img {
    width: 24px;
    height: 24px;
  }

  .button-navigation-slider-banner:hover {
    background: #e7e6e6;
  }
  .__inactive-slide {
    opacity: 0.5;
  }
}

.container-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-carus {
  max-width: 1314px;
  max-height: 666px;
  height: 100%;
  width: 100%;
  position: relative;

  .swiper-pagination {
    display: none;
  }
}

.container-skeleton {
  width: 100%;
  max-width: 1314px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.box-timer-circl-progress {
  position: absolute;
  top: 31%;
  left: 40%;
}

.__photo-carousel-size {
  height: 320px;
}

.slick-slide {
  opacity: 1;
  transition: opacity 0.5s;
}
.slick-current,
.slick-current-clone-animate {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .container-carus {
    .button-navigation-slider-banner {
      bottom: 9%;
    }
  }
}

@media screen and (max-width: 430px) {
  .container-carus {
    .photo-carousel-full {
      .slick-list {
        img {
          border-radius: 0;
        }
      }
    }
  }
}
