.container-list-vacancy {
  width: 100%;
}

.list-vacancy-header {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
}

.box-address-vacancy {
  color: #8b8b8b;
  font-weight: 400;
  font-size: 16px;
}

@media screen and (max-width: 430px) {
  .list-vacancy-header {
    flex-direction: column;
    align-items: flex-start;

    .city-name-box {
      margin-left: unset;
      margin-top: 12px;
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .box-vacancy {
    flex-direction: column;
    padding-bottom: 16px !important;

    .box-name-vacancy {
      max-width: unset;

      .salary-vacancy {
        font-weight: 500;
        margin-bottom: 8px;
      }
    }

    .box-address-vacancy {
      max-width: unset;
      display: flex;
      flex-direction: row;
    }

    .box-link-vacancy {
      max-width: unset;
      justify-content: flex-start;
    }
  }
}

.list-vacancy-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  padding: 0;
  margin: 0;
}

.list-vacancy-city {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #5032c8;
  margin: 0;
}

.box-vacancy {
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #f2f2f5;
  margin-bottom: 16px;
  justify-content: space-between;
}

.box-name-vacancy {
  max-width: 33%;
  width: 100%;
}

.name-vacancy {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.salary-vacancy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0px 0px 0px;
}

.box-address-vacancy {
  max-width: 33%;
  width: 100%;
}

.text-title-address {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.text-address-vacansy {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.box-link-vacancy {
  width: 100%;
  max-width: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.link-vacancy-info {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none !important;
  color: #5032c8;
}

.city-name-box {
  display: flex;
  padding: 8px 16px 8px 20px;
  height: 36px;
  border: 1px solid #e6e6eb;
  border-radius: 22px;
  align-items: center;
}

.city-name {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
  line-height: 32px;
  text-overflow: ellipsis;
  overflow-wrap: normal;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.city-name-box:hover {
  border: 1px solid #5032c8;
  .city-name {
    color: #5032c8;
  }
}

.__active-vacancy-city {
  color: #5032c8;
  margin-right: 8px;
}

.city-name-box:hover {
  border-bottom: 1px solid #5032c8;
  margin-bottom: -1px;
}

.__active-city-name-box {
  border: 1px solid #5032c8;
  width: auto !important;
}

.box-clear-city-vacansy {
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.clear-vacansy-city {
  height: 10px;
  width: 10px;
  filter: brightness(0) saturate(100%) invert(22%) sepia(56%) saturate(4319%)
    hue-rotate(247deg) brightness(79%) contrast(98%);
}

.__active-select-city {
  color: #5032c8 !important;
  img {
    margin-top: -4px;
  }
}

.button-clear-vacancy-filter {
  max-width: 177px !important;
  width: 100%;
  height: 44px !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
}

.button-text-clear-vacancy-filter {
  margin-left: -7px !important;
  width: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #5032c8;
}

.row-button-clear-vacancy-filter {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px !important;
}

.header-modal-vacancy-city {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-close-modal-city {
  margin-right: 12px;
  margin-top: -3px;
  filter: brightness(0) saturate(100%) invert(6%) sepia(5%) saturate(2%)
    hue-rotate(314deg) brightness(103%) contrast(98%);
}

.clear-filter-vacancy-city {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #acacac;
  cursor: pointer;
}

.clear-filter-vacancy-city:hover {
  text-decoration: underline;
}

@media (max-width: 430px) {
  .row-button-clear-vacancy-filter {
    display: none !important;
  }
  .modal-city-vacancy > .modal-header > .btn-close {
    display: none;
  }
  .modal-city-vacancy {
    .button-search-address {
      transform: scale(-1, 1);
    }
    // .list-city {
    //   height: 77vh !important;
    // }
  }
  .modal-title {
    width: 100%;
  }
  .row-page-career {
    margin-top: 40px !important;
  }
  .container-feedback {
    margin-top: 0 !important;
  }
  .container-map {
    padding: 0 !important;
  }
}
