.edit-address-modal {
  .box-button-new-delivery-address {
    .button-edit-address {
      width: 135px !important;
      height: 44px !important;
      border: 1px solid #e92c2c !important;
      border-radius: 22px !important;
      margin-right: 16px !important;
      .container-button-content {
        margin: 0;
        justify-content: center !important;
        img {
          filter: brightness(0) saturate(100%) invert(37%) sepia(71%)
            saturate(4028%) hue-rotate(342deg) brightness(91%) contrast(101%);
        }
        .content-button-text {
          margin: 3px 0px 0px 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          color: #e92c2c;
        }
      }
    }
    .button-save-delivery-info {
      width: 135px !important;
      height: 44px !important;
      max-width: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .edit-address-modal {
    .box-button-new-delivery-address {
      flex-direction: column;
      .button-edit-address {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 16px;
      }
      .button-save-delivery-info {
        width: 100% !important;
        max-width: 100% !important;
      }
      margin-bottom: 0;
    }
  }
}
